import React, { useState, useEffect } from "react";

export default function FacultyOfHumanities() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const facultyData = [
    {
      name: "Dr. Nishi Sharma",
      designation: "Asst. Prof",
      department: "English",
      profile: "https://jssuninoida.edu.in/assets/profiles/Dr_Nishi_Sharma_JSS_University.docx"
    },
  ];

  return (
    <div
      style={{
        padding: "20px",
        marginTop: windowWidth < 768 ? "50px" : "300px",
        marginBottom: "50px",
      }}
    >
      <h1>Faculty Of Humanities</h1>
      <p>
        Our faculty members are highly experienced and industry-oriented,
        ensuring that students receive the best education and practical
        knowledge.
      </p>
      <div style={{ overflowX: "auto" }}>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead style={{ background: "#0054a4", color: "#fff" }}>
            <tr>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Name
              </th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Designation
              </th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Department
              </th>
              <th style={{ padding: "10px", border: "1px solid #ddd" }}>
                Profile
              </th>
            </tr>
          </thead>
          <tbody>
            {facultyData.map((faculty, index) => (
              <tr key={index} style={{ textAlign: "left" }}>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {faculty.name}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {faculty.designation}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  {faculty.department}
                </td>
                <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                  <a
                    className="btn btn-primary"
                    target="_blank"
                    href={faculty.profile}
                  >
                    View Profile
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
