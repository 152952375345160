import React from "react";
export default function IETEDetail() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "20vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <center>
          <h1>IETE</h1>
        </center>
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          The Institution of Electronics and Telecommunication Engineers (IETE)
          is India's leading recognized professional society devoted to
          advancing Science and Technology in Electronics, Telecommunication &
          IT. Founded in 1953. The IETE is the National Apex Professional body
          of Electronics and Telecommunication, Computer Science, and IT
          Professionals. It serves over 1,25,000 members (including Corporate,
          Student and, ISF members) through 63 Centres, spread all over India
          and abroad. The Institution provides leadership in Scientific and
          Technical areas of direct importance to the national development and
          economy. The government of India has recognized IETE as a Scientific
          and Industrial Research Organization (SIRO) and also notified it as an
          educational Institution of national eminence. The IETE focuses on the
          advancement of the Science and Technology of Electronics,
          Telecommunication, Computers, Information Technology, and related
          areas. Towards this end, the Institution promotes and conducts basic
          engineering and continuing technical education programs for human
          resource development.
          <br />
          <br />
          <h4>Objective: -</h4>
          The objectives of IETE focus on advancing electro-technology. The IETE
          conducts and sponsors technical meetings, conferences, symposia, and
          exhibitions all over India, publishes technical journals and provides
          continuing education as well as career advancement opportunities to
          its members.
          <br />
          <br />
          <h4>Benefits: -</h4>
          The IETE Newsletter and one of the technical journals are supplied to
          all Corporate Members free of cost. In addition, they are entitled to
          subsidized subscription rates for other publications of IETE. Other
          Benefits include concessional rates for attending seminars,
          conferences, and other technical programs organized by IETE and the
          opportunity to participate in meetings and forums on professional
          issues.
        </p>
      </section>
    </>
  );
}
