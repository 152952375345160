import React from "react";
export default function CSIDetail() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "20vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <center>
          <h1>CSI</h1>
        </center>
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          JCSI (Computer Society of India) Student Branch is the official branch
          under CSI Ghaziabad Chapter. The mission of CSI is to facilitate
          research, knowledge sharing, learning and career enhancement for all
          categories of IT Professionals, while simultaneously inspiring and
          nurturing new entrants into the industry and helping them to integrate
          into the IT community.
          <br />
          <br />
          <h4>Objective: -</h4>
          <ul style={{ listStyle: "disc" }}>
            <li>
              To enrich and empower the minds of students by conducting
              technical Quizzes Competitions regularly.
            </li>
            <li>To organize the Technical, Non-Technical events</li>
            <li>o improve the leadership quality among the students</li>
          </ul>
          <br />
          <br />
          <h4>Benefits: -</h4>
          <ul style={{ listStyle: "disc" }}>
            <li>
              To be a part of the distinguished fraternity of famous IT industry
              leaders, brilliant scientists and dedicated academicians through
              Networking.{" "}
            </li>
            <li>
              Appreciation letter / certificate for performance excellence.
            </li>
            <li>Industry recognized CSI certification opportunities.</li>
            <li>
              Technology updates through Conferences, Seminars, Tutorials &amp;
              workshop at discounted rates.
            </li>
            <li>
              A forum for activities like Paper Presentations, Quiz,
              Competitions and Exhibitions.
            </li>
            <li>CSI Minor Research project funding up to Rs. 50000/-</li>
          </ul>
        </p>
      </section>
    </>
  );
}
