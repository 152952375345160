import React from "react";
import { useEffect } from "react";

export default function JSSLoader(){
    useEffect(() => {
        document.title = "JSS University NOIDA";
        setTimeout(() => {
            window.location.href = "/home";
        }, 4000);
    }, []);
    return (
        <div className="jss-loader-container">
            <img id="jss-loader" src="images/logo.png"/><br/>
            <h2 style={{display:"block", marginTop:"50px"}}>Loading...</h2>
        </div>
    )
}