import React from "react";
import GuestHouseCarousel from "./components/GuestHouseCarousel";

export default function GuestHouse() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Guest House</h2>
        </center>
        <GuestHouseCarousel></GuestHouseCarousel>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          JSS University Noida offers a well-equipped guest house on campus, 
          providing comfortable accommodation for visiting faculty, scholars, 
          parents, and other guests. The guest house is designed to ensure that 
          visitors have a pleasant and convenient stay, reflecting the university's 
          hospitality and commitment to excellence.
<br/><br/>
          The guest house features a range of amenities to cater to the needs of 
          its guests. Each room is furnished with modern conveniences such as 
          air conditioning, Wi-Fi, and television, ensuring a comfortable and 
          connected stay. The rooms are well-maintained and serviced regularly 
          to uphold high standards of cleanliness and hygiene.
<br/><br/>
          In addition to comfortable lodging, the guest house offers dining 
          facilities where guests can enjoy a variety of meals prepared by 
          experienced chefs. The dining area is designed to provide a relaxing 
          ambiance, making it an ideal place for guests to unwind and socialize.
<br/><br/>
          The guest house also includes conference and meeting rooms equipped 
          with the latest audiovisual technology. These facilities are available 
          for guests to conduct meetings, seminars, or collaborative projects, 
          making the guest house not only a place for rest but also a venue for 
          academic and professional activities.
<br/><br/>
          JSS University Noida's guest house is strategically located within the 
          campus, providing easy access to various university facilities, including 
          lecture halls, libraries, and recreational areas. The staff at the guest 
          house are dedicated to ensuring that guests have a memorable and hassle-free 
          experience, offering assistance and support as needed.
<br/><br/>
          Through its well-appointed guest house, JSS University Noida demonstrates 
          its commitment to hospitality and convenience, ensuring that all visitors 
          experience the warmth and vibrancy of the university community.
        </p>
      </section>
    </>
  );
}
