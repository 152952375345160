import React, { useState, useEffect } from "react";

export default function Notification(params) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {windowWidth > 768 ? (
        <div
          className="notification-container no-gutters row"
          style={{
            height: "50px",
            backgroundColor: "#E67817",
            padding: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <div
            className="sideways-animation"
            style={{
              fontSize: "1em",
              color: "#FFF",
              textDecoration: "underline",
            }}
          >
            <a
              className="btn btn-primary"
              style={{
                padding: "5px 10px",
                marginLeft: "10px",
                backgroundColor: "crimson",
              }}
              href="/admission-main"
            >
              Apply For Admission (2024-25)
            </a>
            <a
              className="btn btn-primary"
              style={{
                padding: "5px 10px",
                marginLeft: "10px",
                backgroundColor: "crimson",
              }}
              // href="#"
              href="https://jssuninoida.edu.in/assets/docs/Check_List-MCA_merged.pdf"
              target="_blank"
            >
              Shortlisted Candidates for MCA
            </a>
            <a
              className="btn btn-primary"
              style={{
                padding: "5px 10px",
                marginLeft: "10px",
                backgroundColor: "crimson",
              }}
              href="/fee-structure"
              // target="_blank"
            >
              Fees Structure
            </a>
            <a
              className="btn btn-primary"
              style={{
                padding: "5px 10px",
                marginLeft: "10px",
                backgroundColor: "crimson",
              }}
              href="/admission-checklist"
              // target="_blank"
            >
              Admission Checklist
            </a>
          </div>
          <br />
        </div>
      ) : (
        <div
          className="notification-container no-gutters row"
          style={{
            width: "100%",
            height: "100px",
            backgroundColor: "#E67817",
            padding: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "fit-content",
            gap: "10px",

          }}
        >
          <div
            className="sideways-animation"
            style={{
              fontSize: "1em",
              color: "#FFF",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <a
              className="btn btn-primary"
              style={{
                padding: "5px 10px",
                marginLeft: "10px",
                marginBottom: "10px",
                backgroundColor: "crimson",
              }}
              href="/admission-main"
            >
              Apply For Admission (2024-25)
            </a>
            <a
              className="btn btn-primary"
              style={{
                padding: "5px 10px",
                marginLeft: "10px",
                backgroundColor: "crimson",
                marginTop: "10px",
                marginBottom: "10px",

              }}
              // href="#"
              href="https://jssuninoida.edu.in/assets/docs/Check_List-MCA_merged.pdf"
              target="_blank"
            >
              Shortlisted Candidates for MCA
            </a>
            <a
              className="btn btn-primary"
              style={{
                padding: "5px 10px",
                marginLeft: "10px",
                backgroundColor: "crimson",
                marginTop: "10px",

              }}
              href="/fee-structure"
              // target="_blank"
            >
              Fees Structure
            </a>
            <a
              className="btn btn-primary"
              style={{
                padding: "5px 10px",
                marginLeft: "10px",
                backgroundColor: "crimson",
                marginTop: "10px",
                marginBottom: "10px",

              }}
              href="/admission-checklist"
              // target="_blank"
            >
              Admission Checklist
            </a>
          </div>
        </div>
      )}
    </>
  );
}
