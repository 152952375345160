import React from "react";

export default function AboutEmployeeBenefits() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Employee Benefits</h2>
        </center>

        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          <h4>GRATUITY</h4>
          It is a prescribed benefit plan and is one of the many retirement
          privileges by the employer to the employee upon leaving the
          organization. A person who has served in this organization for at
          least 5 years is eligible for this benefit. This computation is based
          on his/her average salary, dearness allowance, and the number of years
          he/she worked in the organization. The employee is eligible for 15
          days of salary for every one calendar year of service in the
          organization. All the employees of the institution are provided with
          the Gratuity (linked to LIC scheme) as per the Govt. of India
          notification.
          <br />
          <br />
          {/* <h4>EMPLOYEE PROVIDENT FUND (EPF) & EMPLOYEE PENSION SCHEME</h4>
          Employee Provident Fund (EPF) & Employee Pension Schemes are provided
          to all the eligible employees as per Central Government norms. Under
          the EPF scheme, 12% of an employee's monthly basic salary + dearness
          allowance subjected to calculations for maximum of 15000/- ceiling
          limit towards EPF. The employer contribution is 12% (split into 8.33%
          towards Employees Family Pension Scheme or EFPS) and 3.67% EPF.
          Employee Provident Fund (EPF) & Employee Pension Scheme to all the
          employees of the institution as per the Govt. of India notification.
          <br />
          <br /> */}
          <h4>GROUP MEDICLAIM FACILITY</h4>
          Group mediclaim facility is provided to all the employees of JSS
          Noida, University (optional) with cashless hospital admission facility.
          <br />
          <br />
          <h4>EMPLOYEE STATE INSURANCE CORPORATION</h4>
          ESI Medical Benefits. Employees' State Insurance or ESI is a comprehensive social security program for workers in the event of medical contingencies, such as sickness, maternity, death, or disablement due to employment injury and occupational disease. ESI facility is extended to all the employees with the ceiling limit of total salary Rs. 21,000/- per month.
          <br />
          <br />
          <h4>MATERNITY BENEFIT</h4>
          Women Employees can avail 135 days of maternity leave with full salary and shall not exceed two occasions during the service period of the employee. Maternity leave facility under ESI Scheme is also available up to 180 days extended for the ESI scheme employees.

Maternity Leave benefits are extended to all the eligible employees as per Government notification.
          <br />
          <br />
          <h4>GROUP PERSONAL ACCIDENT POLICY</h4>
          All employees of JSS University, Noida are covered under Group Personal Accident Insurance with Sum Assured of Rs.2,50,000/-, and accidental hospitalization expenses up to Rs 50,000/-. If the staff is met with an accident, not able to attend the duties due to temporary total disabilities, weekly compensation Rs. 5000/- will be payable for a period of 52 weeks.
          <br />
          <br />
          <h4>EARLY DEATH LIFE INSURANCE (EDLI) SCHEME</h4>
          EDLI facility related to the death of the employee during his service period. This is extended to all the employees of JSS Univeristy Noida up to a limit of 600000/-.
          <br />
          <br />
        </p>
      </section>
    </>
  );
}
