import React, { useState, useEffect } from "react";
import "./style.css";

export default function CSEAIML() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        padding: windowWidth > 768 ? "100px" : "10px",
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
        margin:
          windowWidth > 768 ? "200px 20px 100px 20px" : "50px 20px 50px 20px",
        textAlign: windowWidth > 768 ? "justify" : "left",
      }}
    >
      <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>
        B. Tech - CSE-AIML
      </h2>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
        1. Cutting-Edge Curriculum
      </h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "22px" }}>
        The B.Tech in CSE-AIML at JSS University, Noida, is specifically
        designed to cater to the growing demand for AI and ML experts in the
        tech industry. The curriculum is a blend of foundational computer
        science principles and specialized courses focused on AI and ML,
        ensuring that students are well-versed in both the theoretical and
        practical aspects of these cutting-edge technologies.
      </p>
      <ul className="course-section">
        <li>
          <strong>Core Computer Science Foundation:</strong> The program starts
          with a strong foundation in core computer science subjects, such as
          Data Structures, Algorithms, Operating Systems, and Databases. These
          subjects are crucial for understanding the underlying mechanics of AI
          and ML systems.
        </li>
        <li>
          <strong>Specialized AIML Courses:</strong> As students progress, the
          curriculum delves into specialized topics like Machine Learning, Deep
          Learning, Natural Language Processing (NLP), Computer Vision, and AI
          Ethics. These courses are designed to equip students with the skills
          needed to develop intelligent systems that can learn and adapt.
        </li>
        <li>
          <strong>Practical Applications:</strong> The curriculum emphasizes
          hands-on learning through projects, labs, and assignments. Students
          work on real-world problems, developing AI models, training neural
          networks, and building intelligent applications. This practical
          experience is vital for understanding the complexities of AI and ML
          technologies.
        </li>
        <li>
          <strong>Research and Innovation:</strong> The program also encourages
          research in emerging areas of AI and ML, such as reinforcement
          learning, generative adversarial networks (GANs), and explainable AI.
          Students are provided with opportunities to publish their research and
          present it at conferences, preparing them for careers in academia or
          R&D.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
        2. Future Scope and Job Opportunities
      </h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "22px" }}>
        The future scope for graduates of the CSE-AIML program is vast, as AI
        and ML are revolutionizing industries across the globe. The demand for
        professionals skilled in AI and ML is at an all-time high, with
        opportunities spanning various sectors.
      </p>
      <ul className="course-section">
        <li>
          <strong>Diverse Career Opportunities:</strong> Graduates can pursue
          careers as Machine Learning Engineers, Data Scientists, AI
          Researchers, NLP Engineers, Computer Vision Specialists, and AI
          Consultants. These roles are highly sought after in industries such as
          tech, finance, healthcare, automotive, and entertainment.
        </li>
        <li>
          <strong>Emerging Fields:</strong> AI and ML are constantly evolving,
          giving rise to new fields such as Autonomous Systems, Edge AI, AI in
          Healthcare, and AI in Finance. JSS University’s CSE-AIML program
          prepares students to enter these cutting-edge fields, where they can
          work on the development of autonomous vehicles, smart healthcare
          systems, and predictive financial models.
        </li>
        <li>
          <strong>Global Demand:</strong> AI and ML skills are in demand
          worldwide, and graduates from JSS University are well-equipped to take
          on roles in leading tech hubs like Silicon Valley, London, Singapore,
          and Bengaluru. The university’s strong reputation and industry
          connections further enhance graduates’ employability in the global job
          market.
        </li>
        <li>
          <strong>Entrepreneurship:</strong> The entrepreneurial ecosystem
          around AI and ML is thriving, with startups focusing on AI-driven
          solutions for various problems. Graduates of the CSE-AIML program are
          well-prepared to launch their own startups, leveraging their expertise
          to create innovative products and services.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
        3. Higher Studies and Research Opportunities
      </h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "22px" }}>
        For students interested in furthering their education, the B.Tech in
        CSE-AIML provides a strong platform for pursuing advanced studies and
        research.
      </p>
      <ul className="course-section">
        <li>
          <strong>Advanced Degrees in AIML:</strong> Graduates can pursue
          M.Tech, MS, or Ph.D. programs in specialized areas such as Artificial
          Intelligence, Machine Learning, Data Science, and Robotics. The
          program’s rigorous curriculum and research focus ensure that students
          are well-prepared for these advanced degrees, whether they pursue them
          in India or abroad.
        </li>
        <li>
          <strong>Research-Oriented Learning:</strong> JSS University promotes a
          research-driven approach to learning, with opportunities for students
          to engage in cutting-edge research projects. These projects often
          explore advanced topics such as deep reinforcement learning, AI
          ethics, and human-computer interaction, contributing to the
          advancement of AI and ML knowledge.
        </li>
        <li>
          <strong>Collaborative Research:</strong> The university collaborates
          with leading research institutions and industries on AI and ML
          projects, providing students with opportunities to work on
          interdisciplinary research. These collaborations often lead to
          publications in prestigious journals and presentations at
          international conferences, enhancing students’ academic and
          professional profiles.
        </li>
        <li>
          <strong>Innovation in AI:</strong> The focus on innovation at JSS
          University encourages students to think creatively and push the
          boundaries of what AI and ML can achieve. This innovative mindset is
          essential for those pursuing research careers or advanced studies,
          where they can contribute to the development of new AI technologies
          and applications.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
        4. State-of-the-Art Infrastructure
      </h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "22px" }}>
        JSS University, Noida, offers top-notch infrastructure that supports the
        specialized needs of the CSE-AIML program, ensuring that students have
        access to the latest tools and technologies.
      </p>
      <ul className="course-section">
        <li>
          <strong>Advanced AI and ML Labs:</strong> The university is equipped
          with dedicated AI and ML laboratories, featuring high-performance
          computing resources, including GPUs and TPUs, which are essential for
          training complex neural networks and processing large datasets. These
          labs provide an environment where students can experiment with the
          latest AI and ML algorithms.
        </li>
        <li>
          <strong>Cutting-Edge Software Tools:</strong> Students have access to
          industry-standard software tools such as TensorFlow, PyTorch, Keras,
          Scikit-learn, and OpenCV. These tools are crucial for developing and
          deploying AI models in real-world applications. The university ensures
          that students are trained in these tools, making them industry-ready
          upon graduation.
        </li>
        <li>
          <strong>Data Resources:</strong> AI and ML are data-intensive fields,
          and JSS University provides access to extensive datasets and
          cloud-based resources. These resources are critical for conducting
          experiments, training models, and developing AI applications that
          require large-scale data processing.
        </li>
        <li>
          <strong>Smart Classrooms:</strong> The university’s smart classrooms
          are equipped with the latest teaching aids, enabling interactive and
          immersive learning experiences. These classrooms support hybrid
          learning models, where students can participate in virtual labs,
          attend online guest lectures from industry experts, and collaborate on
          projects remotely.
        </li>
        <li>
          <strong>Innovation and Incubation Centers:</strong> JSS University’s
          innovation centers are designed to foster creativity and
          entrepreneurship. These centers provide students with the resources
          and mentorship needed to develop AI-driven solutions, from initial
          concept to prototype development and commercialization.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
        5. Industry Connections and Placement Support
      </h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "22px" }}>
        The B.Tech in CSE-AIML program at JSS University benefits from the
        university’s strong industry connections and comprehensive placement
        support, which are crucial for students transitioning into the
        workforce.
      </p>
      <ul className="course-section">
        <li>
          <strong>Industry-Academia Collaboration:</strong> The program is
          developed in collaboration with industry experts, ensuring that the
          curriculum is aligned with the latest industry requirements. Regular
          workshops, seminars, and guest lectures by professionals from leading
          AI and ML companies provide students with insights into industry
          trends and expectations.
        </li>
        <li>
          <strong>Internships and Live Projects:</strong> JSS University’s
          strong ties with the tech industry facilitate internships and live
          projects, giving students practical experience in real-world AI and ML
          applications. These internships often lead to job offers, as companies
          prefer to hire candidates who have demonstrated their skills in a
          professional setting.
        </li>
        <li>
          <strong>Dedicated Placement Cell:</strong> The university’s placement
          cell is proactive in preparing students for the job market. It offers
          training in resume building, interview techniques, and soft skills,
          ensuring that students are well-prepared for recruitment processes.
          The placement cell also organizes campus recruitment drives,
          attracting top AI and ML companies to hire from the university.
        </li>
        <li>
          <strong>Global Career Opportunities:</strong> The placement cell’s
          strong connections with multinational companies provide students with
          global career opportunities. Graduates have the chance to work with
          leading organizations in AI and ML, both in India and internationally,
          in roles that offer challenging and rewarding career paths.
        </li>
        <li>
          <strong>Strong Alumni Network:</strong> The university’s alumni
          network is a valuable resource for current students. Alumni working in
          prominent positions in AI and ML companies often return to mentor
          students, offer internships, and share their experiences. This network
          provides students with guidance and networking opportunities that are
          critical for career success.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
        6. Holistic Development
      </h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "22px" }}>
        JSS University is committed to the holistic development of its students,
        ensuring they graduate as well-rounded individuals ready to excel in the
        rapidly evolving tech landscape.
      </p>
      <ul className="course-section">
        <li>
          <strong>Technical and Non-Technical Clubs:</strong> The university
          hosts a variety of clubs and societies that cater to both technical
          and non-technical interests. AI and ML enthusiasts can join coding
          clubs, participate in hackathons, and engage in collaborative
          projects. Meanwhile, cultural, sports, and arts clubs offer avenues
          for students to explore their passions beyond academics.
        </li>
        <li>
          <strong>Soft Skills Enhancement:</strong> The program emphasizes the
          development of soft skills, such as communication, leadership,
          teamwork, and critical thinking. These skills are integral to the
          success of AI and ML professionals, who often work in
          interdisciplinary teams and need to articulate complex ideas to
          diverse audiences.
        </li>
        <li>
          <strong>Social Initiatives and Community Service:</strong> JSS
          University encourages students to participate in social initiatives,
          where they can apply their AI and ML skills to solve real-world
          problems. Projects like AI for social good, environmental monitoring,
          and healthcare solutions not only provide practical experience but
          also instill a sense of social responsibility.
        </li>
        <li>
          <strong>Global Exposure:</strong> Through international internships,
          and collaborations with global research institutions, students gain
          exposure to the international AI and ML community. This global
          perspective is invaluable in a field as dynamic and widespread as AI
          and ML, preparing students to work in diverse environments and
          contribute to global innovations.
        </li>
        <li>
          <strong>Mental and Physical Well-being:</strong> The university offers
          resources to support the mental and physical well-being of its
          students. Counseling services, fitness centers, and wellness programs
          are available to help students manage stress and maintain a healthy
          lifestyle, ensuring they can perform at their best academically and
          personally.
        </li>
      </ul>

      <p style={{ marginTop: "20px", marginBottom: "20px", fontSize: "22px" }}>
        The B.Tech in Computer Science and Engineering with a specialization in
        Artificial Intelligence and Machine Learning (CSE-AIML) at JSS
        University, Noida, is a comprehensive program that prepares students for
        the exciting and rapidly expanding field of AI and ML. With a curriculum
        that combines strong foundational knowledge with specialized skills,
        state-of-the-art infrastructure, and a focus on practical experience,
        students are well-equipped to excel.
      </p>
    </div>
  );
}
