import React, { useState , useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function PopupButtons() {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneno: "",
    program: "",
    state: "",
    city: "",
    query: "",
  });
  const navigate = useNavigate();
  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    console.log(formData);
  };
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // change the value of isSubmitting to true
    setIsSubmitting(true);

    console.log("Form Data:", formData);
    // Reset form data
    // create a parameterized URL
    const base_url = "https://script.google.com/macros/s/AKfycbw2lgIbAXFydtrL-U_BHjrq-uT3Zm-pOEgBGjI3Aly9JnW-nGKNvtaTaM8cTWCEJt2NDw/exec";
    const url = `${base_url}?name=${formData.name}&email=${formData.email}&phone=${formData.phoneno}&program=${formData.program}&state=${formData.state}&city=${formData.city}&query=${formData.query}`;
    // send a POST request to the Google Apps Script URL
    try {
      const response = await fetch(url, {
        method: 'POST',
      });
      
      if (response.status === 200) {
        console.log('Form submitted successfully');
      } else {
        console.log('Error:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
    
    // // Close the modal
    setShowModal(false);    
    setIsSubmitting(false);
    setFormData({
      name: "",
      email: "",
      phoneno: "",
      program: "",
      state: "",
      city: "",
      query: "",
    });
    // scroll to top
    window.scrollTo(0, 0);
    navigate("/thank-you-admission");
  };
  
  const toggleModal = () => {
    setShowModal(!showModal);
  };
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="vertical-buttons">
        {/* <button
          onClick={toggleModal}
          style={{
            marginRight: "1rem",
            backgroundColor: "crimson",
            boxShadow: "0 0 40px rgba(0,0,0,0.8)",
            color: "white",
          }}
          className="btn"
          onMouseOver={(e) => {
            e.target.style.backgroundColor = "darkred";
            e.target.style.color = "white";
            e.target.style.boxShadow = "0 30px 40px rgba(0,0,0,0.8)";
            e.target.style.transition = "0.5s";
          }}
          onMouseOut={(e) => {
            e.target.style.backgroundColor = "crimson";
            e.target.style.color = "white";
            e.target.style.boxShadow = "0 0 40px rgba(0,0,0,0.8)";
            e.target.style.transition = "0.5s";
          }}
        >
          Admission Query <span className="fa fa-message"></span>
        </button> */}
        <a href="tel:01202401484" target="_blank" rel="noreferrer">
          <button
            className="btn"
            style={{
              marginRight: "1rem",
              backgroundColor: "green",
              boxShadow: "0 0 40px rgba(0,0,0,0.8)",
              color: "white",
            }}
            onMouseOver={(e) => {
              e.target.style.backgroundColor = "darkgreen";
              e.target.style.color = "white";
              e.target.style.boxShadow = "0 30px 40px rgba(0,0,0,0.8)";
              e.target.style.transition = "0.5s";
            }}
            onMouseOut={(e) => {
              e.target.style.backgroundColor = "green";
              e.target.style.color = "white";
              e.target.style.boxShadow = "0 0 40px rgba(0,0,0,0.8)";
              e.target.style.transition = "0.5s";
            }}
          >
            Enquire Now <span className="fa fa-phone"></span>
          </button>
        </a>
      </div>
      {showModal && (
        <div
          className="modal"
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="modal-content"
            style={{
              maxWidth: "700px",
              margin: "10px",
              maxHeight: "90vh",
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title">Admission Enquiry</h5>
              <button onClick={toggleModal} className="close">
                &times;
              </button>
            </div>
            <div className="modal-body" style={{ overflowY: "auto" }}>
              <form
                // action="https://formsubmit.co/agrawalmanan2@gmail.com"
                // method="post"
                onSubmit={handleSubmit}
              >
                <div className="form-group">
                  <label htmlFor="name">Candidate Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Full Name"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Email Address"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="phone">Phone Number</label>
                  <input
                    type="phone"
                    id="phone"
                    name="phoneno"
                    value={formData.phoneno}
                    onChange={handleInputChange}
                    className="form-control"
                    placeholder="Phone Number"
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="program" className="form-label-select">
                    Select Program
                  </label>
                  <select
                    name="program"
                    value={formData.program}
                    onChange={handleInputChange}
                    placeholder="Select Program"
                    class="form-control"
                    required
                  >
                    <option selected>Select Program</option>
                    <option value="Engineering & Technologies (UG Courses)">
                      Engineering & Technologies (UG Courses)
                    </option>
                    <option value="Management (PG Courses)">
                      Management (PG Courses)
                    </option>
                    <option value="Computer Applications (PG Courses)">
                      Computer Applications (PG Courses)
                    </option>
                    <option value="Basic Sciences (PG Courses)">
                      Basic Sciences (PG Courses)
                    </option>
                    <option value="Humanities (PG Courses)">
                      Humanities
                    </option>
                    <option value="Pharmacy (PG & UG Courses)">Pharmacy (PG & UG Courses)</option>
                  </select>
                </div>
                <div className="form-group">
                  <label htmlFor="state">State</label>
                  <input
                    type="text"
                    name="state"
                    value={formData.state}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                    placeholder="State"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="city">City</label>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                    placeholder="City"
                  ></input>
                </div>
                <div className="form-group">
                  <label htmlFor="query">Your Admission Query</label>
                  <input
                    type="text"
                    name="query"
                    value={formData.query}
                    onChange={handleInputChange}
                    className="form-control"
                    required
                    placeholder="Admission Query"
                  ></input>
                </div>
                <input
                  type="hidden"
                  name="_next"
                  value="https://main.d2bxnag4r95dg7.amplifyapp.com/thank-you-admission-query"
                ></input>

                <button disabled={isSubmitting} type="submit" className="btn btn-primary">
                  {isSubmitting && <span className="fa fa-spinner fa-spin"></span>} &nbsp;&nbsp;
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
