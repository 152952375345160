import React from 'react';
import esImg from "../../assets/images/esJSSMVP.jpg";

export default function Es() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "10vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <div className="centered-image">
          <div>
            <h2 style={{ textAlign: "center" }}>Dr. C.G. Betsurmath</h2>
          </div>
          <div>
            <img
              src={esImg}
              alt="Dr. C.G. Betsurmath"
              style={{
                maxWidth: "40vh",
                height: "auto",
                display: "block",
              }}
            />
          </div>
        </div>
        <br /><br />
        <p className="" style={{ textAlign: "justify", fontSize: "1.2em" }}>
          <h4>BIOGRAPHY</h4>
        </p>
        <p className="" style={{ textAlign: "justify", fontSize: "1.2em", marginBottom: "100px" }}>
          Dr. C.G. Betsurmath, a Senior Super Time Scale (Retired) Karnataka Administrative Service Officer of the rank of Special Secretary to the Government of Karnataka, is an eminent administrator and academician. He currently serves as the Chief Executive Officer and Administrative Head of JSS MVP and its Institutions, overseeing their functioning and development since October 2016.
          <br /><br />
          <strong>Experience:</strong>
          <ul>
            <li>Served as a Lecturer in Economics at the then P.G. Centre of Gulbarga and Belgaum.</li>
            <li>Joined Karnataka Administrative Services and held various positions including Tahasildar, Assistant Commissioner, Sub-Divisional Magistrate, Additional Deputy Commissioner, and Additional District Magistrate in multiple regions.</li>
            <li>Held roles such as Commissioner of Mysuru Urban Development Authority, Commissioner of Archaeology, Museums & Heritage, and Commissioner of Mysuru City Corporation.</li>
            <li>Worked as a resource person at the Administrative Training Institute, Mysuru, and Karnataka Police Academy, Mysuru, educating officials on topics like Land Revenue Administration, Land Reforms Act, Urban Planning, and Human Rights.</li>
            <li>Served as a State-level Resource Person during General Elections, empanelled by the Election Commission of India.</li>
            <li>Organized and participated in numerous seminars, workshops, and conferences at state, national, and international levels.</li>
          </ul>
          <strong>Achievements:</strong>
          <ul>
            <li>Co-authored "Rural Urban Migration: Theory and Applications" published in The Indian Journal of Labour Economics.</li>
            <li>Authored several papers and articles on public administration, literacy campaigns, and land record computerization.</li>
            <li>Edited volumes celebrating India's Golden Jubilee of Independence.</li>
          </ul>
          <strong>Other Information:</strong>
          <ul>
            <li>He specializes in General Administration, Elections, Economic Development, Urban and Regional Development & Planning, Training and Research, Guest Lecturing, Project and Team Management, Heritage Conservation, Human Resources, and Policy Designing.</li>
          </ul>
        </p>
      </section>
    </>
  );
}
