import React from "react";
export default function SAEDetail() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "20vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <center>
          <h1>SAE</h1>
        </center>
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          SAE International is a global association with technical expertise in
          the aerospace, automotive and commercial-vehicle industries. The SUPRA
          competition challenges teams to conceive, design, fabricate, develop
          and compete with small, formula style, vehicles. At the college level
          the team VEGA taking parts in the different events organized by SAE
          India. The team VEGA racing is a team of budding engineers since 2011,
          associated with designing, building and racing vehicles in different
          national level competitions, such as BAJA SAE, BAJA student India,
          Enduro Student India, Formula Student India etc. Since it was founded,
          the team VEGA racing is continuously evolved, flourished and
          optimizing its design, simulation and fabrication to compete at
          national level. The team's hard work and dedication depicted on the
          rank table which are made by SAE at the end of event on the basis of
          performance of the college team of pan India. In the session 2018-19,
          the team was able to secure 129 ranks in the virtual round but this
          rank has drastically improved in session 2019-20, and got only 39
          ranks out of 400 participating teams which shows our student devotion,
          dedication and determination to compete the pan India teams.
          <br />
          <br />
          <h4>Objective: -</h4>
          <ul style={{ listStyle: "disc" }}>
            <li>Fabricate the designed vehicle in the laboratory</li>
            <li>Design the ATV (All-Terrain Vehicle)</li>
          </ul>
          <br />
          <br />
          <h4>Benefits: -</h4>
          <ul style={{ listStyle: "disc" }}>
            <li>Various CAD Software’s and how they can use them</li>
            <li>
              Different part of an automobile and their importance, how do they
              work harmoniously to propel us from one place to another.
            </li>
            <li>
              Develop and prepare technical skills which are necessary for the
              students
            </li>
          </ul>
        </p>
      </section>
    </>
  );
}
