import React from "react";

export default function ThankYouAdmissionQuery() {
    return (
        <section className="container"
        style={{
            width: "100vh",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
        >
            <div className="row">
                <div className="col-md-12">
                    <h1>Thank you for your submission</h1>
                    <p style={{fontSize:"1.5em"}}>
                        We will get back to you soon. 
                    </p>
                    <a href="/" className="btn btn-primary">
                        Go to Homepage
                    </a>
                </div>
            </div>
        </section>
    );
}
