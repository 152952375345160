import React from "react";
import AmenitiesCarousel from "./components/AmenitiesCarousel";

export default function AmenitiesCenter() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Amenities Center</h2>
        </center>
        <AmenitiesCarousel></AmenitiesCarousel>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          JSS University Noida is equipped with a comprehensive amenities center 
          designed to cater to the diverse needs of students, faculty, and staff. 
          The amenities center provides a variety of services and facilities, ensuring 
          convenience and enhancing the overall campus experience.
<br/><br/>
          One of the key features of the amenities center is the photocopying and 
          printing facility. This service allows students and staff to easily make 
          copies of study materials, print documents, and prepare academic assignments. 
          The center is equipped with high-quality printers and copiers to ensure 
          efficient and reliable service.
<br/><br/>
          The amenities center also includes a stationary shop that stocks a wide 
          range of stationary items, including pens, pencils, notebooks, lab files, 
          and other essential supplies. This makes it easy for students and faculty 
          to quickly obtain the materials they need for their academic and research 
          activities.
<br/><br/>
          In addition to stationary items, the amenities center offers a selection 
          of food items and snacks. This includes packaged snacks, beverages, and 
          other quick bites, providing a convenient option for those who need a 
          quick refreshment between classes or during study sessions.
<br/><br/>
          The center's convenient location within the campus ensures that students 
          and staff have easy access to these essential services. Whether it's 
          preparing for an exam, completing a project, or simply grabbing a quick 
          snack, the amenities center serves as a one-stop solution for many needs.
<br/><br/>
          JSS University Noida's commitment to providing a supportive and 
          well-equipped environment is evident through the comprehensive offerings 
          of its amenities center. By ensuring that students, faculty, and staff 
          have access to essential services and supplies, the university enhances 
          the overall campus experience and supports the academic and personal 
          success of its community members.
        </p>
      </section>
    </>
  );
}
