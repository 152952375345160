import React from "react";
import SportsCarousel from "./components/SportsCarousel";

export default function Sports() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Sports Facilities</h2>
        </center>
        <SportsCarousel></SportsCarousel>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          While JSS Academy of Higher Education & Research, Noida, prioritizes
          academic excellence across various disciplines, it is equally
          committed to fostering a vibrant sports culture. The university boasts
          a range of sports facilities designed to cater to a variety of
          interests and skill levels. Students can engage in popular sports such
          as cricket, where they can enjoy well-maintained pitches and
          equipment. For those interested in indoor games, the university
          provides excellent facilities for chess, offering a quiet and
          competitive environment. Additionally, traditional Indian sports like
          kho-kho are encouraged, with dedicated spaces for practice and play.
          Basketball enthusiasts have access to well-equipped courts that meet
          international standards, promoting both recreational and competitive
          play. Badminton players can take advantage of the indoor courts,
          ensuring that weather conditions do not hinder their practice
          sessions. Volleyball players, too, benefit from the university’s
          commitment to sports, with outdoor courts available for regular
          matches and training. Beyond these, JSS Academy recognizes the
          importance of sports in overall development and well-being, organizing
          various tournaments and sports events throughout the academic year.
          The university’s focus on sports is not merely about providing
          facilities but also about encouraging a balanced lifestyle, teamwork,
          and physical fitness among its students and faculty. In the future,
          the academy aims to expand its sports infrastructure further, ensuring
          that all students have ample opportunities to pursue their athletic
          interests.
        </p>
      </section>
    </>
  );
}
