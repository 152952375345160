import React, { useState, useEffect } from "react";
// import "./style.css";

export default function MediaCoverage() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        padding: windowWidth > 768 ? "100px" : "10px",
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
        margin:
          windowWidth > 768 ? "200px 20px 100px 20px" : "50px 20px 50px 20px",
        textAlign: windowWidth > 768 ? "justify" : "left",
      }}
    >
      <center>
        <h1 style={{ marginTop: "20px", marginBottom: "20px" }}>
          MEDIA COVERAGE
        </h1>
        <img
          src="images/jss_in_media2.jpeg"
          alt=""
          style={{
            objectFit: "cover",
            maxWidth: "100%",
            marginBottom: "20px",
            marginRight: "20px",
          }}
        />
        <img
          src="images/jss_in_media3.jpeg"
          alt=""
          style={{ objectFit: "cover", maxWidth: "100%" }}
        />
        <img
          src="images/jss_in_media1.jpeg"
          alt=""
          style={{ objectFit: "cover", maxWidth: "100%" }}
        />
      </center>
    </div>
  );
}
