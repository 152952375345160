import React , {useState , useEffect} from 'react';
import "./style.css";

export default function CSEDS() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{
        padding:windowWidth > 768 ? "100px" : "10px",
        backgroundColor:"#f0f0f0",
        borderRadius:"10px",
        boxShadow:"0 0 10px 0 rgba(0, 0, 0, 0.1)",
        margin:windowWidth > 768 ? "200px 20px 100px 20px" : "50px 20px 50px 20px",
        textAlign:windowWidth > 768 ? "justify" : "left"
    }}>
      <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>B. Tech - CSE - Data Science</h2>
      
      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>1. Innovative Curriculum</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        The B.Tech in CSE with a specialization in Data Science (CSE-DS) at JSS University, Noida, is tailored to meet the growing demand for data science professionals. The curriculum is meticulously designed to provide students with a comprehensive understanding of both the core principles of computer science and the specialized techniques and tools needed in data science.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Core Computer Science Knowledge:</strong> The program starts with foundational courses in computer science, such as Programming, Data Structures, Algorithms, Operating Systems, and Databases. These subjects are essential for understanding the technical aspects of data management and software development.
        </li>
        <li>
          <strong>Specialized Data Science Courses:</strong> As students progress, they delve into specialized subjects like Statistics for Data Science, Machine Learning, Big Data Analytics, Data Visualization, and Data Mining. These courses are designed to equip students with the skills required to analyze, interpret, and derive insights from large datasets.
        </li>
        <li>
          <strong>Practical Learning:</strong> The curriculum places a strong emphasis on practical, hands-on experience. Students engage in numerous lab sessions, assignments, and projects that simulate real-world data challenges. They learn to use industry-standard tools and software like Python, R, SQL, Hadoop, and Tableau, ensuring they are well-prepared for the job market.
        </li>
        <li>
          <strong>Capstone Projects:</strong> Towards the end of the program, students undertake capstone projects where they apply their learning to solve complex data problems. These projects often involve collaboration with industry partners, allowing students to work on real-world data sets and gain practical experience.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>2. Future Scope and Job Opportunities</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        Data Science is one of the fastest-growing fields, and graduates of the CSE-DS program have access to a wealth of opportunities across various industries. The ability to analyze and interpret data is increasingly valuable in today’s data-driven world.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Diverse Career Paths:</strong> Graduates can pursue roles such as Data Scientist, Data Analyst, Machine Learning Engineer, Business Intelligence Analyst, Data Engineer, and Quantitative Analyst. These roles are highly sought after in sectors like finance, healthcare, retail, technology, and more.
        </li>
        <li>
          <strong>High Demand for Skills:</strong> The demand for data science professionals is expected to continue growing as companies increasingly rely on data-driven decision-making. This demand translates into competitive salaries and strong job security for graduates.
        </li>
        <li>
          <strong>Emerging Fields:</strong> The field of Data Science is constantly evolving, with new areas such as AI-driven analytics, predictive analytics, and cloud-based big data solutions emerging. The CSE-DS program prepares students to enter these innovative fields, where they can work on cutting-edge projects.
        </li>
        <li>
          <strong>Global Opportunities:</strong> Data Science is a universal discipline, and skills acquired through the CSE-DS program are in demand worldwide. Graduates have the opportunity to work in international markets, with roles in global companies and startups that are leading the charge in data innovation.
        </li>
        <li>
          <strong>Entrepreneurial Ventures:</strong> The ability to analyze and leverage data opens up opportunities for graduates to start their own ventures. Whether it’s a data-driven startup or a consultancy, the CSE-DS program equips students with the knowledge and skills to succeed as entrepreneurs.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>3. Higher Studies and Research Opportunities</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        For those interested in furthering their education, the B.Tech in CSE-DS provides an excellent foundation for advanced studies and research in Data Science and related fields.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Advanced Degrees in Data Science:</strong> Graduates can pursue advanced degrees such as M.Tech, MS, or Ph.D. in Data Science, Artificial Intelligence, Machine Learning, or Business Analytics. The strong foundational knowledge provided by the CSE-DS program ensures that students are well-prepared for these advanced studies, whether in India or at top universities abroad.
        </li>
        <li>
          <strong>Research-Focused Learning:</strong> JSS University promotes a research-oriented approach, offering students opportunities to engage in cutting-edge research projects. These projects often explore advanced topics such as deep learning, data ethics, and big data technologies, contributing to the advancement of knowledge in Data Science.
        </li>
        <li>
          <strong>Collaborative Research Opportunities:</strong> The university collaborates with industry and academic institutions on various research initiatives, providing students with the chance to work on interdisciplinary research projects. These collaborations often lead to publications in reputed journals and presentations at international conferences.
        </li>
        <li>
          <strong>Innovation in Data Science:</strong> The emphasis on innovation at JSS University encourages students to explore new ways of using data to solve complex problems. This innovative mindset is essential for those pursuing research careers or advanced studies, where they can contribute to the development of new data science methodologies and technologies.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>4. State-of-the-Art Infrastructure</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        JSS University, Noida, offers an exceptional infrastructure that supports the specialized needs of the CSE-DS program, providing students with access to the latest tools and technologies.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Advanced Data Science Labs:</strong> The university is equipped with specialized Data Science labs featuring powerful computing resources essential for processing large datasets, running complex algorithms, and developing data-driven applications. These labs provide an environment where students can experiment with the latest data science techniques.
        </li>
        <li>
          <strong>Cutting-Edge Software and Tools:</strong> Students have access to industry-standard software tools such as Python, R, SAS, Apache Hadoop, Spark, and Tableau. These tools are crucial for data analysis, machine learning, and big data processing. The university ensures that students are proficient in these tools, making them industry-ready upon graduation.
        </li>
        <li>
          <strong>Extensive Data Resources:</strong> Data is the cornerstone of Data Science, and JSS University provides students with access to extensive datasets and cloud-based resources. These resources are critical for conducting experiments, training models, and developing data-driven applications.
        </li>
        <li>
          <strong>Smart Classrooms:</strong> The university’s smart classrooms are equipped with modern teaching aids that enhance interactive and immersive learning experiences. These classrooms support hybrid learning models, where students can participate in virtual labs, attend guest lectures from industry experts, and collaborate on projects remotely.
        </li>
        <li>
          <strong>Innovation and Incubation Centers:</strong> JSS University’s innovation and incubation centers are designed to foster creativity and entrepreneurship. These centers provide students with the resources and mentorship needed to develop data-driven solutions, from initial concept to prototype development and commercialization.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>5. Industry Connections and Placement Support</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        The B.Tech in CSE-DS program at JSS University benefits from the university’s strong industry connections and comprehensive placement support, which are crucial for students entering the workforce.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Industry-Academia Collaboration:</strong> The CSE-DS program is developed in collaboration with industry experts, ensuring that the curriculum is aligned with the latest industry needs. Regular workshops, seminars, and guest lectures by professionals from leading data science companies provide students with insights into industry trends and expectations.
        </li>
        <li>
          <strong>Internships and Live Projects:</strong> JSS University’s strong ties with the tech industry facilitate internships and live projects, giving students practical experience in real-world data science applications. These internships often lead to job offers, as companies prefer to hire candidates who have demonstrated their skills in a professional setting.
        </li>
        <li>
          <strong>Dedicated Placement Cell:</strong> The university’s placement cell is proactive in preparing students for the job market. It offers training in resume building, interview techniques, and soft skills, ensuring that students are well-prepared for recruitment processes. The placement cell also organizes campus recruitment drives, attracting top data science companies to hire from the university.
        </li>
        <li>
          <strong>Global Career Opportunities:</strong> The placement cell’s strong connections with multinational companies provide students with global career opportunities. Graduates have the chance to work with leading organizations in data science, both in India and internationally, in roles that offer challenging and rewarding career paths.
        </li>
        <li>
          <strong>Strong Alumni Network:</strong> The university’s alumni network is a valuable resource for current students. Alumni working in prominent positions in data science companies often return to mentor students, offer internships, and share their experiences. This network provides students with guidance and networking opportunities that are critical for career success.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>6. Holistic Development</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        JSS University is committed to the holistic development of its students, ensuring they graduate as well-rounded individuals ready to excel in the rapidly evolving field of data science.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Technical and Non-Technical Clubs:</strong> The university hosts a variety of clubs and societies that cater to both technical and non-technical interests. Data Science enthusiasts can join coding clubs, participate in data science competitions, and engage in collaborative projects. Meanwhile, cultural, sports, and arts clubs offer avenues for students to explore their passions beyond academics.
        </li>
        <li>
          <strong>Soft Skills Enhancement:</strong> The program emphasizes the development of soft skills, such as communication, leadership, teamwork, and critical thinking. These skills are integral to the success of data science professionals, who often work in interdisciplinary teams and need to articulate complex ideas to diverse audiences.
        </li>
        <li>
          <strong>Social Initiatives and Community Service:</strong> JSS University encourages students to participate in social initiatives, where they can apply their data science skills to solve real-world problems. Projects like data-driven social good initiatives, environmental monitoring, and healthcare analytics not only provide practical experience but also instill a sense of social responsibility.
        </li>
        <li>
          <strong>Global Exposure:</strong> Through international collaborations with global research institutions, students gain exposure to the international data science community. This global perspective is invaluable in a field as dynamic and widespread as data science, preparing students to work in diverse environments and contribute to global innovations.
        </li>
        <li>
          <strong>Mental and Physical Well-being:</strong> The university offers resources to support the mental and physical well-being of its students. Counseling services, fitness centers, and wellness programs are available to help students manage stress and maintain a healthy lifestyle, ensuring they can perform at their best academically and personally.
        </li>
      </ul>

      <p style={{ marginTop: "20px", marginBottom: "20px", fontSize:"22px" }}>
        The B.Tech in Computer Science and Engineering with a specialization in Data Science (CSE-DS) at JSS University, Noida, is a comprehensive program that equips students with the skills and knowledge required to excel in the fast-growing field of Data Science. With a curriculum that combines strong foundational knowledge with specialized data science techniques, state-of-the-art infrastructure, and a focus on practical experience, students are well-prepared to tackle the challenges of the data-driven world. Whether your goal is to work in top tech
      </p>
    </div>
  );
}