import React from "react";

export default function Campusvideo() {
  return (
    <>
      <div
        className="Stunning-component"
        style={{
          width: "100%",
          height: "50vh",
          padding: "130px 0 127px",
          background:
            "url(./images/jssCampus.jpg) center / cover no-repeat #111",
          marginTop: "100px",
          marginBottom : "50px"
        }}
      >
        <div className="container">
          <div className="partners-list text-center">
            <button>
                <img
                  src="https://www.bennett.edu.in/wp-content/themes/twentysixteen/cdn/images/play-btn.png"
                  title="Virtual Campus Tour"
                  alt="Virtual Campus Tour"
                />
            </button>
            <h3>Virtual Campus Tour</h3>
          </div>
        </div>
      </div>
    </>
  );
}
