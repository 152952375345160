import React from "react";
import LibraryCarousel from "./components/LibraryCarousel";

export default function LibraryInformationCentre() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Library & Information Centre</h2>
        </center>
        <LibraryCarousel></LibraryCarousel>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          JSS University Noida's Library & Information Centre is a cornerstone of 
          academic life on campus, providing an extensive collection of resources 
          and state-of-the-art facilities to support the educational and research 
          needs of students, faculty, and staff. The library is dedicated to fostering 
          a rich learning environment through its comprehensive services and 
          diverse resources.
<br/><br/>
          The library houses a vast collection of books, journals, periodicals, 
          and digital resources across various disciplines. It offers both physical 
          and electronic access to a wide range of academic materials, ensuring 
          that users have the resources they need for their studies and research. 
          The digital library provides access to numerous online databases, 
          e-journals, and e-books, allowing for seamless and convenient access to 
          information from anywhere on campus.
<br/><br/>
          Equipped with modern technology, the Library & Information Centre 
          features dedicated computer workstations, high-speed internet access, 
          and advanced search tools to facilitate efficient information retrieval. 
          The library also provides quiet study areas, group study rooms, and 
          comfortable reading spaces, catering to different study preferences and 
          needs.
<br/><br/>
          The library staff are highly skilled and offer personalized assistance to 
          help users navigate the extensive collection and make the most of the 
          available resources. Regular workshops, training sessions, and orientation 
          programs are conducted to familiarize users with the library's services, 
          electronic resources, and effective research techniques.
<br/><br/>
          In addition to its rich collection and user-friendly facilities, the 
          Library & Information Centre at JSS University Noida is committed to 
          promoting a culture of reading and lifelong learning. The library 
          regularly organizes book clubs, author talks, and literary events, 
          providing a platform for intellectual exchange and community engagement.
<br/><br/>
          The library's commitment to excellence extends beyond traditional 
          resources, as it continuously explores innovative ways to enhance its 
          services. By integrating new technologies and adopting best practices 
          in library science, the Library & Information Centre strives to remain 
          at the forefront of academic support and resource provision.
<br/><br/>
          JSS University Noida's Library & Information Centre is more than just 
          a repository of books; it is a dynamic learning hub that empowers users 
          to achieve academic success and fosters a vibrant intellectual community.
        </p>
      </section>
    </>
  );
}
