import React from "react";
import { useState, useEffect } from "react";

const governingBodiesData = [
  {
    slNo: 1,
    constitution: "The Vice-Chancellor",
    data: [
      "Dr. H.R. Mahadevaswamy",
      "Joint Director, TED JSSMVP, Mysuru (Officiating VC)",
    ],
    status: "Chairperson",
  },
  {
    slNo: 2,
    constitution: "Member Nominated by the Governing Body",
    data: [
      "Dr. DP Singh, Former Chairman, UGC",
      "Dr. H.R. Mahadevaswamy Joint Director, TED JSSMVP, Mysuru",
    ],
    status: "Member",
  },
  {
    slNo: 3,
    constitution: "Eminent educationalist nominated by the President",
    data: [
      "Dr. Anurag S. Rathore, Ph.D. Coordinator, DBT COE for Biopharmaceutical Technology. Professor, Department of Chemical Engineering (Jointly with) Yardi School of Artificial Intelligence Indian Institute of Technology Hauz Kauz, New Delhi, 110016",
    ],
    status: "Member",
  },
  {
    slNo: 4,
    constitution: "Eminent educationalist nominated by the president",
    data: ["Dr. B.Suresh, Pro Chancellor"],
    status: "Member",
  },
  {
    slNo: 5,
    constitution:
      "Officer of the state Government not below the rank OF Joint Secretary to the Government of Uttar Pradesh",
    data: ["To be Nominated by Government"],
    status: "Member",
  },
  {
    slNo: 6,
    constitution:
      "One Professor of the University in order of seniority on rotation basis for a period of one year",
    data: [
      "Dr. R.S. Jagadeesh",
      " Professor & Head Dept. of Chemistry",
      " JSS University, Noida",
    ],
    status: "Member",
  },
  {
    slNo: 7,
    constitution:
      "One associate Professor of the University in order of seniority on rotation basis for a period of one year",
    data: [
      "Dr. Dheeraj Pandey",
      "Assoc. Professor & HoD",
      "Dept. Of CSE, JSS University, Noida",
    ],
    status: "Member",
  },
  {
    slNo: 8,
    constitution:
      "One Educationist not below the rank of Associate Professor from a panel of three names tp be approved by the state Government, for  which the University shall submit a list of three names of eminent educationists",
    data: [
      "Panel to be sent:",
      "",
      "1. Dr.Shailendra Saraf",
      "Director NIPER, Ahmedabad",
      "",
      "2. Dr. Brahmeshwar Mishra",
      "Professor, Department of Pharmaceuticals Engineering and Technology,IIT BHU",
      "Phone No(s): 8052641306, 0542-7165724",
      "Email: bmishra.phe@iitbhu.ac.in",
      "",
      "3. Preeti Kothiyal",
      "Vice-Chancellor",
      "Dev Bhoomi Uttarakhand University, Dehradun",
    ],
    status: "Member",
  },
  {
    slNo: 9,
    constitution: "Registrar",
    data: ["Dr. T.G. Mamatha"],
    status: "Member Secretary",
  },
];

export default function ExecutiveCouncil() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section
      className="section"
      style={{
        marginTop: windowWidth > 768 ? "30vh" : "100px",
        marginBottom: "100px",
        padding: "10px",
        fontSize: windowWidth > 768 ? "1.2em" : "0.75em",
      }}
    >
      <div className="container mt-5">
        <h1 className="mb-4">Executive Council</h1>
        <table className="table table-striped">
          <thead style={{ background: "#0054a4", color: "#fff" }}>
            <tr>
              <th scope="col">SL NO</th>
              <th scope="col">CONSTITUTION</th>
              <th scope="col">NAME AND DESIGNATION</th>
              <th scope="col">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {governingBodiesData.map((body, index) => (
              <tr key={index}>
                <th scope="row">{body.slNo}</th>
                <td>{body.constitution}</td>
                <td>
                  {body.data.map((data, index) => (
                    <>
                      {data}
                      <br />
                    </>
                  ))}
                </td>
                <td>{body.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
}
