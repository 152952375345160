import React from "react";
import ATMFacilityCarousel from "./components/ATMFacilityCarousel";

export default function BankATMFacility() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Bank ATM Facility</h2>
        </center>
        <ATMFacilityCarousel></ATMFacilityCarousel>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          JSS University Noida provides convenient banking services on campus with 
          the availability of Bank ATM facilities. These ATMs are strategically 
          located to ensure easy access for students, faculty, and staff, allowing 
          them to perform a variety of banking transactions without the need to 
          leave the campus.
<br/><br/>
          The ATMs on campus support multiple banking services, including cash 
          withdrawals, balance inquiries, fund transfers, and mini statements. 
          This facility significantly enhances the convenience for the university 
          community, ensuring that banking needs are met efficiently and securely.
<br/><br/>
          In addition to basic banking transactions, these ATMs often provide 
          services such as mobile recharge, bill payments, and account-related 
          queries. This broad range of services ensures that users can manage 
          their financial needs seamlessly while focusing on their academic 
          and professional responsibilities.
<br/><br/>
          JSS University Noida ensures that the ATMs are regularly maintained and 
          replenished with cash to avoid any inconvenience. Security measures are 
          also in place to safeguard users and their transactions, providing a 
          secure banking environment on campus.
<br/><br/>
          The presence of ATMs on campus reflects JSS University Noida's commitment 
          to providing a supportive and convenient environment for its community. 
          By facilitating easy access to banking services, the university helps 
          students and staff manage their finances effectively, contributing to 
          their overall well-being and productivity.
        </p>
      </section>
    </>
  );
}
