
import React, { useEffect, useState } from "react";

export default function AdmissionChecklist() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section
      className="section"
      style={{
        position: "relative",
        top: windowWidth > 768 ? "185px" : "0px",
        marginBottom: "200px",
        marginTop: "10vh",
        padding: windowWidth > 768 ? "2rem 5rem" : "2rem 1rem",
      }}
    >
      <center>
        <h2>Admission Checklist</h2>
      </center>
      <br />
      <br />
      <h4>Checklist : </h4>
      <p
        className=""
        style={{
          textAlign: "justify",
          fontSize: "1.2em",
        }}
      >
        All candidates are requested to bring the following original documents
        along with <b>TWO</b> set of photo copies at the time of counseling:
        <ul>
          <li>1. Proof of date of birth (High School Mark sheet)</li>
          <li>2. Intermediate Mark sheet</li>
          <li>3. Transfer Certificate</li>
          <li>4. Migration Certificate (for CBSE & ICICI Board)</li>
          <li>5. Character Certificate</li>
          <li>6. Aadhar Cards – both candidates and parents </li>
          <li> 7. Affidavit for year gap students </li>
          <li>8. Photographs</li>
        </ul>
      </p>
      <h4>Payment Details/Mode of Payment :</h4>
      <p
        className=""
        style={{
          textAlign: "justify",
          fontSize: "1.2em",
        }}
      >
        Initial payment of Rs.50,000/- in the form of Demand Draft in favour of
        “JSS University, Noida” / online payment as a confirmation fee.
      </p>
    </section>
  );
}
