import React from "react";

export default function PlacementStatistics() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "10vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <center>
          <h2>Training & Placement</h2>
        </center>
        {/* <img src="./images/4.png" alt="Banner" className="centered-image w-100"  /> */}
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          <b>
            ­JSS University, Noida can serve as a launchpad to a successful
            career!
          </b>
          <br />
          <br />
          JSS University, Noida is dedicated to providing quality education
          under the leadership of Honourable Chancellor, His Holiness Jagadguru
          Sri Shivarathri Deshikendra Mahaswamiji and this is evident in its
          commitment to grooming students who are not just academically
          proficient but also industry ready. Here are some of the practices
          followed by JSS Universities & Institutions to ensure the holistic
          development of its students:
          <br />
          <br />
          <ul style={{ listStyle: "disc" }}>
            <li>
              <b>Industry-Aligned Curriculum:</b> The university incorporates
              hands-on projects, case studies, and real-world scenarios into its
              curriculum, providing students with practical experience and
              preparing them for the demands of the industry.
            </li>
            <li>
              <b>Internships:</b>Through facilitated internships, students gain
              practical experience and exposure to professional work
              environments. Encouraging internships during semester breaks
              allows students to apply theoretical knowledge in real-world
              settings, enhancing their understanding and skills.
            </li>
            <li>
              <b>Industry Collaborations (MOUs/COEs):</b>By forging partnerships
              with industry organizations and professionals, the university
              provides opportunities for guest lectures, networking, and
              collaboration on research projects. This collaboration bridges the
              gap between academia and industry, ensuring that students are
              well-prepared for the realities of the workplace.
            </li>
            <li>
              <b>Skill Development Workshops:</b>The university offers
              workshops, seminars, and training sessions focused on developing
              essential skills such as communication, teamwork, problem-solving,
              and leadership. These initiatives enhance students' employability
              and readiness for the professional world.
            </li>
            <li>
              <b>Extracurricular Activities: </b>
              Encouraging participation in extracurricular activities related to their field of study helps students develop teamwork, time management, and leadership skills. Competitions, hackathons, and volunteer initiatives provide platforms for students to showcase their passion and commitment.
            </li>
            <li>
              <b>Professional Development Support:</b>
              Through career counselling sessions, resume reviews, interview preparation, and job search resources, the university supports students in navigating their career paths and enhancing their employability.
            </li>
            <li>
              <b>Continuous Feedback and Evaluation:</b>Soliciting feedback from industry partners, alumni, and employers helps the university assess the effectiveness of its programs and identify areas for improvement. Regular evaluation of student performance ensures that they receive constructive feedback and guidance for further development.
            </li>
          </ul>
          <br/><br/>
          At JSS University, above and beyond placements, our dedicated placement team focuses on bridging the gap between theory and application, collaborating closely with industry partners and through these practices ensure that students graduating are not just academically competent but also well-prepared to excel in their chosen professions, contributing positively to the industry and society at large. 
          <br/><br/>
          <h4>Placement Highlights of JSS Universities & Institutions</h4>
          <table className="table table-bordered" style={{width:'100vw'}}>
            <tr>
              <th>JSSATE, NOIDA</th>
              <th>JSSATE, Bengaluru</th>
              <th>JSSSTU, Mysuru</th>
            </tr>
            <tr>
              <td>Offers 900+</td>
              <td>Offers 750+ </td>
              <td>Offers 800+</td>
            </tr>
            <tr>
              <td>Highest CTC : 47LPA</td>
              <td>Highest CTC : 43.17LPA</td>
              <td>Highest CTC : 60LPA</td>
            </tr>
            <tr>
              <td>Recruiters on Campus:170+</td>
              <td>Recruiters on Campus:80+</td>
              <td>Recruiters on Campus:400+</td>
            </tr>
          </table>
          <center><img style={{maxWidth:'80vw'}} src="images/placements/placement_process.png"></img></center>
          <br/><br/>
          <h3>Industry Institute Collaborations (MOUs & COEs)</h3>
          <center><img style={{maxWidth:'80vw'}} src="images/placements/mous_coes.png"></img></center>
          <br/><br/>
          <h3>Few Esteemed Recruiters</h3>
          <center><img style={{maxWidth:'80vw'}} src="images/placements/company_1.png"></img></center>
          <br/><br/>
          <center><img style={{maxWidth:'80vw'}} src="images/placements/company_2.png"></img></center>
          <br/><br/>
          <center><img style={{maxWidth:'80vw'}} src="images/placements/company_3.png"></img></center>
          <br/><br/>
          <center><img style={{maxWidth:'80vw'}} src="images/placements/company_4.png"></img></center>
          <br/><br/>
          
          Request Industry Patrons to Contact Placement Officer and Team at the details mentioned below:<br/>
          <b>
          Dr.(Mrs.) Parveen P Terang,<br/>
          Training & Placement Officer<br/>
          </b>
          <b>Email:</b> tpo@jssaten.ac.in<br/>
          <b>Phone:</b> +91 9911382632<br/>
          
          <br/><br/>
          <br/><br/>
        </p>
      </section>
    </>
  );
}
