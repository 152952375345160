import React , {useState , useEffect} from 'react';
import "./style.css";


export default function CSE() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{
        padding:windowWidth > 768 ? "100px" : "10px",
        backgroundColor:"#f0f0f0",
        borderRadius:"10px",
        boxShadow:"0 0 10px 0 rgba(0, 0, 0, 0.1)",
        margin:windowWidth > 768 ? "200px 20px 100px 20px" : "50px 20px 50px 20px",
        textAlign:windowWidth > 768 ? "justify" : "left"
    }}>
      <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>B. Tech - CSE</h2>
      
      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>1. Comprehensive Curriculum</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px",fontSize:"22px" }}>
        The B.Tech in Computer Science and Engineering (CSE) at JSS University, Noida, is designed to provide a broad and deep understanding of computing principles while keeping up with the latest industry trends. The curriculum is meticulously structured to cover both fundamental and advanced topics, ensuring that students acquire a well-rounded education.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Core Subjects:</strong> The core subjects include essential areas like Data Structures and Algorithms, Operating Systems, Database Management Systems, and Computer Networks. These subjects form the cornerstone of computer science, teaching students the building blocks of software development, data management, and network communication.
        </li>
        <li>
          <strong>Specializations and Electives:</strong> As students progress, they have the option to specialize in cutting-edge fields such as Machine Learning, Artificial Intelligence, Cybersecurity, Internet of Things (IoT), Blockchain, and Cloud Computing. Elective courses allow students to delve into areas of interest, giving them the flexibility to shape their educational journey according to their career goals.
        </li>
        <li>
          <strong>Practical Learning:</strong> Practical application is a significant focus at JSS University. The program includes numerous lab sessions, projects, and hands-on assignments that simulate real-world scenarios. Whether it's building software from scratch, analyzing large datasets, or developing AI models, students gain invaluable experience that prepares them for industry challenges.
        </li>
        <li>
          <strong>Industry-Relevant Projects:</strong> The curriculum integrates industry-relevant projects that align with the latest technological advancements. These projects are often conducted in collaboration with industry partners, ensuring that students work on real problems faced by companies, thus bridging the gap between academia and industry.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>2. Future Scope and Job Opportunities</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px",fontSize:"22px" }}>
        The future for CSE graduates from JSS University is filled with diverse and lucrative opportunities across various sectors. The demand for skilled computer science professionals is consistently high, and the versatility of the degree opens doors to numerous career paths.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Diverse Career Paths:</strong> Graduates can pursue careers in software development, where they can work as software engineers, application developers, or systems architects. In the rapidly growing field of data science, they can become data analysts, data engineers, or machine learning engineers. Cybersecurity is another critical area, with roles such as security analyst, ethical hacker, and information security manager.
        </li>
        <li>
          <strong>Emerging Technologies:</strong> As technology continues to evolve, new fields such as Quantum Computing, Blockchain, and Augmented Reality/Virtual Reality (AR/VR) are creating additional opportunities. JSS University’s curriculum prepares students to be at the forefront of these innovations, making them highly sought-after in the job market.
        </li>
        <li>
          <strong>Global Opportunities:</strong> With a B.Tech in CSE from JSS University, graduates are not limited to local job markets. They can explore global opportunities in leading tech hubs like Silicon Valley, Berlin, Tokyo, and Bengaluru. The skills acquired are universally recognized, and many graduates find employment with multinational corporations.
        </li>
        <li>
          <strong>Entrepreneurial Ventures:</strong> The strong technical foundation and problem-solving skills gained during the program also empower graduates to start their own tech ventures. With the rise of startups and the support of incubation centers, including those available at JSS University, students can transform their innovative ideas into successful businesses.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>3. Higher Studies and Research Opportunities</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px",fontSize:"22px" }}>
        For students with a passion for research and advanced studies, JSS University provides a solid platform to pursue their academic ambitions.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Advanced Degrees:</strong> Graduates often opt for advanced degrees such as M.Tech, MS, or MBA to specialize further or to gain managerial skills. The strong academic foundation provided by JSS University ensures that students are well-prepared to excel in these programs, whether they pursue them in India or at prestigious universities abroad.
        </li>
        <li>
          <strong>Research Initiatives:</strong> The university fosters a research-oriented environment, encouraging students to participate in research projects right from their undergraduate studies. These projects cover a wide range of topics, including AI, machine learning, data mining, natural language processing, robotics, and more.
        </li>
        <li>
          <strong>Publications and Conferences:</strong> Students are encouraged to publish their research findings in reputed journals and present their work at national and international conferences. This exposure not only enhances their academic profile but also connects them with leading researchers and professionals in their field.
        </li>
        <li>
          <strong>Collaborative Research:</strong> JSS University also collaborates with industry and academic institutions on various research initiatives. These collaborations provide students with the opportunity to work on interdisciplinary projects, gaining exposure to different perspectives and methodologies.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>4. State-of-the-Art Infrastructure</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px",fontSize:"22px" }}>
        The learning environment at JSS University is bolstered by its state-of-the-art infrastructure, which supports both academic and extracurricular activities.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Advanced Laboratories:</strong> The university boasts specialized labs for different fields of computer science. For example, the AI and Machine Learning lab is equipped with high-performance computing resources that allow students to work on complex algorithms and large datasets. The Cybersecurity lab provides a controlled environment to study and counter cyber threats.
        </li>
        <li>
          <strong>High-End Hardware and Software:</strong> The university provides access to the latest hardware, including powerful servers, GPUs for deep learning, and IoT devices. Software tools, such as MATLAB, TensorFlow, Hadoop, and others, are available to students, ensuring they can work with the same technologies used in industry.
        </li>
        <li>
          <strong>Library and Digital Resources:</strong> The university’s library is well-stocked with an extensive collection of books, journals, and digital resources. Online databases like IEEE Xplore, Springer, and ACM Digital Library provide students with access to the latest research and developments in computer science.
        </li>
        <li>
          <strong>Innovation and Incubation Centers:</strong> JSS University also houses innovation labs and incubation centers where students can work on their startup ideas, collaborate with peers from different disciplines, and get guidance from mentors. These centers are crucial for fostering creativity and innovation.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>5. Industry Connections and Placement Support</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px",fontSize:"22px" }}>
        JSS University’s strong industry connections and dedicated placement support provide students with a significant advantage when entering the job market.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Regular Industry Interaction:</strong> The university regularly organizes guest lectures, workshops, and seminars featuring industry experts. These interactions give students insights into the latest trends, technologies, and challenges in the industry, helping them stay updated and relevant.
        </li>
        <li>
          <strong>Internships and Live Projects:</strong> Through partnerships with leading tech companies, the university facilitates internships and live projects that give students practical experience. These opportunities often lead to pre-placement offers, as companies prefer hiring interns who are already familiar with their work environment and processes.
        </li>
        <li>
          <strong>Placement Cell:</strong> The placement cell at JSS University is proactive in preparing students for the job market. It offers career counseling, conducts mock interviews, and organizes skill development workshops. The placement cell also coordinates campus recruitment drives, where top companies from various sectors visit the campus to hire talented graduates.
        </li>
        <li>
          <strong>Alumni Network:</strong> The strong alumni network is another asset for students. Alumni working in prestigious positions across the globe often return to mentor current students, offer internships, and even recruit from the university. This network provides valuable connections and insights into different career paths.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>6. Holistic Development</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px",fontSize:"22px" }}>
        At JSS University, the focus is on nurturing well-rounded individuals who excel not only academically but also personally and socially.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Extracurricular Activities:</strong> The university offers a wide array of extracurricular activities, including technical clubs like coding and robotics, cultural clubs for dance, music, and drama, and sports clubs for football, basketball, and more. Participation in these activities helps students develop teamwork, leadership, and communication skills.
        </li>
        <li>
          <strong>Soft Skills Training:</strong> Recognizing the importance of soft skills in professional success, JSS University provides training in areas such as public speaking, time management, and conflict resolution. These skills are crucial for career advancement and personal growth.
        </li>
        <li>
          <strong>Social Responsibility:</strong> The university encourages students to engage in community service and social initiatives. Activities like blood donation camps, environmental conservation drives, and educational outreach programs help students develop a sense of social responsibility and empathy.
        </li>
        <li>
          <strong>Global Exposure:</strong> Through international collaborations, students at JSS University have opportunities to study abroad, attend international conferences, and collaborate on global projects. This exposure broadens their horizons and prepares them for careers in a globalized world.
        </li>
        <li>
          <strong>Mental and Physical Well-being:</strong> The university also places a strong emphasis on the mental and physical well-being of its students. Facilities like counseling centers, fitness centers, and wellness programs ensure that students can manage stress and maintain a healthy lifestyle.
        </li>
      </ul>

      <p style={{ marginTop: "20px", marginBottom: "20px", fontSize:"22px" }}>
        Choosing B.Tech in Computer Science and Engineering at JSS University, Noida, is an excellent decision for those looking to build a successful career in the tech industry. The university offers a comprehensive education that combines theoretical knowledge with practical experience, supported by state-of-the-art infrastructure and strong industry connections. With a focus on holistic development, research opportunities, and global exposure, JSS University prepares its students to become leaders and innovators in the ever-evolving field of computer science. Whether your goal is to work in top tech companies, pursue advanced studies, or start your own venture, JSS University equips you with the skills and experiences needed to achieve your ambitions.
      </p>
    </div>
  );
}