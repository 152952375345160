import React from "react";

export default function ChancellorMessage() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "10vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <div className="centered-image">
          <div>
            <h2 style={{ textAlign: "center" }}>Chancellor's Message</h2>
          </div>
          <div>
            <img
              src="images/chancellor_with_modi.jpg"
              alt="Chancellor Jagadguru Sri Shivarathri Deshikendra Mahaswamiji"
              style={{
                maxWidth: "80vh",
                height: "auto",
                display: "block",
              }}
            />
          </div>
        </div>
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          We are happy to state that all the members of the JSS University,
          Noida are committed to work to create a vibrant future for this
          institution. To achieve this end, the University has an excellent
          strategic plan, one that builds on the legacy of success provided by
          the JSS Mahavidyapeetha in providing quality education to the country
          and combining academic excellence with an unprecedented commitment to
          innovation, quality and service.
          <br />
          <br />
          JSS University, Noida is the outcome of concerted efforts to bring
          about structural re-orientation to tertiary education, particularly
          technical education under the ageis of JSS Mahavidyapeetha. The
          purpose of establishment of JSS Mahavidyapeetha, the hallowed arm of
          Sri Suttur Math, was to translate for the committed values of Srimath
          cherished by generations of Pontiffs of the Srimath into reality.
          ‘Education for all’ being the fundamental and endeared value of the
          organization for decades, the much needed push to raise the bar and
          nurture the talent to confront the global standards was availed
          through the establishment of JSS University, Noida. JSS
          Mahavidyapeetha continues to experiment with the contemporary tools of
          education to blend the cutting edge technology and skill development
          with the ethos of this great nation while aligning with the global
          benchmarks.
          <br />
          <br />
          The genesis of JSS University, Noida has come at a time when JSS
          Mahavidyapeetha is embracing a socio-economic responsibility, as its
          mission, in a large scale to mark the event of centenary celebrations
          HH Jagadguru Sri Dr. Shivarathri Rajendra Mahaswamiji, the founder of
          JSS Mahavidyapeetha. One can be confident that the university would
          redefine the purpose and objective of technical education with its
          newfound authority by making a mark in the tapestry of Indian
          technical education. This dream could be achieved with proactive
          participation of all the stake holders. The collective action injects
          sustainability and true success comes only from collective efforts.
          The desirability could be made possibilities with the consistent
          cooperation and assistance of all, as it should align with the
          nation’s vision for innovation, skill and research.
          <br />
          <br />
          <i>
            {" "}
            This is your Academy and the legacy of greatness that we must carry
            forward belongs to our future generations.
          </i>
          <br />
          <br />
          <b>
            His Holiness
            <br />
            Jagadguru Sri Shivarathri Deshikendra Mahaswamiji
            <br />
            Chancellor
            <br />
          </b>
          <br/><br/>
          <br/><br/>
        </p>
      </section>
    </>
  );
}
