import React, { useState, useEffect } from "react";

export default function ResearchFacilities() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top:  windowWidth > 768 ? "145px" : "0px",
          marginBottom: "10vh",
          marginTop: "10vh",
          padding:  windowWidth  > 768 ? "80px" : "20px",
          height: "fit-content",
        }}
      >
        <center>
          <h2>Research Center</h2>
        </center>
        <p
          style={{
            fontSize: "1.2em",
            marginTop: "20px",
          }}
        >
          JSS University Noida is actively involved in diverse areas of research
          and offers consultancy services to industry. Currently there is more
          thrust on inter disciplinary research activities. Sponsored research
          projects investigated by the faculty and supported by Government and
          research organizations have helped the Institute in setting up state
          of the art Laboratory facilities and equipment necessary for carrying
          out high quality research. Research projects of academic nature as
          well as those aimed at solving industrial problems are being pursued.
          JSS University Noida strongly believe that Research is integral part
          of the Teaching Learning process and a powerful form of learning by
          doing. Our students enrich their education through engaging in
          frontline, faculty-led research. Research flourishes in all the
          Departments and across the disciplines. This definitely be helpful to
          transform the leading-edge technology into a new horizon. The research
          centers at JSS University Noida focus on finding solutions to
          disruptive interdisciplinary / cross-disciplinary research problems
          leading to a significant societal and/or economic impact. Through its
          extensive network of skilled research faculty, graduate students and
          collaborators, the Institute is working in advance research areas,
          creating unique research training opportunities and increasing its
          competitiveness. Research at JSS University Noida is an integral part
          of the curriculum and a major component of the teaching-learning
          process. The institute has attracted significant research grants from
          various funding agencies, and various industries. The Office of the
          Dean-Research facilitates and coordinate all research and development
          activities of the Institute. The communication and coordination work,
          compilation and dissemination of scientific and technical information,
          registration for patent, etc. are attended by this Office.
        </p>

        <h3
          style={{
            marginTop: "25px",
            marginBottom: "25px",
          }}
        >
          Objectives
        </h3>

        <ul>
          <div
            id="collapseThree"
            class="collapse show"
            data-parent="#accordion"
          >
            <p
              style={{
                fontSize: "1.2em",
              }}
            >
              • To strengthen the academic research, undertake sponsored
              projects and consultancy.
              <br />
              • To establish collaborations and work
              <br />
              closely with the industries.
              <br />• To receive funds from external agencies to support
              research activities in the college and for carrying out projects.
              <br />• To initiate and coordinate the inter disciplinary research
              and consultancy activities.
              <br />• To guide and support the faculties and students to submit
              research proposals to funding agencies.
              <br />• To guide and support the faculties and students to work
              towards achieving the “Best” and encourage them to obtain
              “Patents”.
              <br />• To encourage the faculties and students to take up
              innovative projects and participate in National and Inter National
              competitions.
            </p>
          </div>
        </ul>
      </section>
    </>
  );
}
