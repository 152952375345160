import React , {useState , useEffect} from "react";

export default function Mentoring() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  return (
    <>
    <div style={{
        position: "relative",
        top: windowWidth > 768 ? "125px" :"0px",
        marginBottom: "10vh",
        marginTop: "10vh",
        padding: windowWidth > 768 ? "80px" : "20px",
        height: "fit-content",
    }}>
      <h2>Mentoring Scheme</h2>
      <p>
        In the broad sense, a mentor is someone who takes a special interest in
        helping person to motivate and de velop him/her in to a successful
        professional. In the present scenario, the concept of mentoring has
        found application in every forum of learning. In academics, mentor is
        often used synonymously with faculty adviser. A mentoring relationship
        develops over an extended period, during which a student's needs and the
        nature of the relationship tend to change. Mentor will try to be aware
        of these changes and vary the degree and type of attention, help,
        advice, information, and encouragement. In general, an effective
        mentoring relationship is characterized by mutual respect, trust,
        understanding, and empathy. Good mentors are able to share life
        experiences and wisdom, as well as technical expertise. They are good
        listeners, good observers, and good problem-solvers of mentees. They
        make an effort to know, accept, and respect the goals and interests of
        the mentees. At, JSS Noida University, a systematic mentoring system is in
        place to ensure overall development of the students, which includes
        personal, professional and career development. At present, there are
        more than 3000 students studying in different discipline and these
        students are monitored and mentored by around 150 faculty mentors.
        Mentors will be in touch with mentees even after completion of their
        degrees. Mentors collects the placement/higher studies details of the
        mentees, shares job opportunities for needy people, invites them to
        participate in college events like cultural fests, Technical talks,
        Alumni meets, etc.
      </p>
      </div>
    </>
  );
}
