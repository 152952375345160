import React from "react";
import { useState, useEffect } from "react";

export default function AdmissionCriteria() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <section
      className="section"
      style={{
        marginTop: windowWidth > 768 ? "30vh" : "100px",
        marginBottom: "100px",
        padding: "10px",
        fontSize: windowWidth > 768 ? "1.2em" : "0.75em",
      }}
      >
        <center>
          <h2>Admission Procedure and Eligibility</h2>
        </center>
          <table className="table table-bordered" style={{width:'100vw'}}>
            <caption>
              Programs offered for admission in the academic year 2024-25
            </caption>
            <thead>
              <tr>
                <th scope="col">SI. No.</th>
                <th scope="col">Course(s)</th>
                <th scope="col">Level</th>
                <th scope="col">Intake 2024-25</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th colspan="4" scope="col">
                  UNDERGRADUATE PROGRAMS
                </th>
              </tr>
              <tr>
                <th scope="row">1</th>
                <td>COMPUTER SCIENCE AND ENGINEERING</td>
                <td>UG</td>
                <td>60</td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>
                  COMPUTER SCIENCE AND ENGINEERING (ARTIFICIAL INTELLIGENCE AND
                  MACHINE LEARNING)
                </td>
                <td>UG</td>
                <td>60</td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>COMPUTER SCIENCE AND ENGINEERING (DATA SCIENCE)</td>
                <td>UG</td>
                <td>60</td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>INFORMATION TECHNOLOGY</td>
                <td>UG</td>
                <td>60</td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td>ELECTRONICS AND COMMUNICATION ENGINEERING</td>
                <td>UG</td>
                <td>60</td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td>ROBOTICS AND ARTIFICIAL INTELLIGENCE</td>
                <td>UG</td>
                <td>60</td>
              </tr>
              <tr>
                <th colspan="4" scope="col">
                  POST GRADUATE PROGRAMS
                </th>
              </tr>
              <tr>
                <th scope="row">7</th>
                <td>MBA (GENERAL)</td>
                <td>PG</td>
                <td>60</td>
              </tr>
              <tr>
                <th scope="row">8</th>
                <td>MBA (FINANCE)</td>
                <td>PG</td>
                <td>60</td>
              </tr>
              <tr>
                <th scope="row">9</th>
                <td>MCA</td>
                <td>PG</td>
                <td>120</td>
              </tr>
              <tr>
                <th scope="row">10</th>
                <td>M.Sc. Chemistry</td>
                <td>PG</td>
                <td>30</td>
              </tr>
              <tr>
                <th scope="row">11</th>
                <td>M.Sc. Physics</td>
                <td>PG</td>
                <td>30</td>
              </tr>
              <tr>
                <th scope="row">12</th>
                <td>M.Sc. Mathematics</td>
                <td>PG</td>
                <td>30</td>
              </tr>
              <tr>
                <th scope="row">13</th>
                <td>MA English</td>
                <td>PG</td>
                <td>30</td>
              </tr>
              <tr>
                <th scope="row">14</th>
                <td>MA Economics</td>
                <td>PG</td>
                <td>30</td>
              </tr>
              <tr>
                <th colspan="4" scope="col">
                  PHARMACY PROGRAMS
                </th>
              </tr>
              <tr>
                <th scope="row">15</th>
                <td>B PHARMA</td>
                <td>UG</td>
                <td>100</td>
              </tr>
              <tr>
                <th scope="row">16</th>
                <td>D PHARMA</td>
                <td>DIP</td>
                <td>60</td>
              </tr>
            </tbody>
          </table>
          <br />
          <br />

        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "10px",
            marginBottom: "120px",
          }}
        >
          <h2>Eligibility for Admission and Counselling Procedure</h2>
          <p>
            Admission to all Undergraduate and Postgraduate programs is through
            JSS University, Noida Counselling
          </p>

          <h2>Admission For B. Tech First-year</h2>
          <p>
            50% of total seats in each B.Tech Course are filled through JEE
            (Main) 2024 All India Rank (CRL).
            <br />
            Remaining seats in each B.Tech Courses will be filled through 10+2
            Examination PCM Percentage.
            <br />
            Vacant Seats are inter-transferable between the above categories.
          </p>
          <p>
            <strong>Eligibility:</strong> B. Tech program, candidates must have
            passed the 10+2 examination with Physics, Chemistry, and Mathematics
            as compulsory subjects, securing a minimum of 70% marks in these
            subjects combined.
          </p>

          <h2>Admission to MBA first-year</h2>
          <p>
            50% of total seats in the MBA Course are filled through CUET
            (PG)2024 Rank.
            <br />
            50% of total seats in the MBA Course are filled through
            undergraduate 3-year duration in any discipline from a recognized
            University with a minimum of 60% marks.
            <br />
            Vacant Seats are inter-transferable between the above categories.
          </p>
          <p>
            <strong>Eligibility:</strong> A bachelor's degree in any discipline
            or an equivalent qualification recognized by the UGC/AICTE with a
            minimum of 60% aggregate marks.
          </p>

          <h2>Admission to MCA first-year</h2>
          <p>
            50% of total seats in the MCA Course are filled through CUET
            (PG)2024 Rank.
            <br />
            Remaining seats in the MCA Course are filled through the
            undergraduate 3-year duration in any discipline (BCA/bachelor’s
            degree in computer science andengineering/B.Sc./ B. Com /B.A.) from
            a recognized University with a minimum of 60% marks.
            <br />
            Vacant Seats are inter-transferable between the above categories.
          </p>
          <p>
            <strong>Eligibility:</strong> A bachelor’s degree in any discipline
            or an equivalent qualification with a minimum of 60% marks aggregate
            with Mathematics at the 10+2 level or at the Graduate Level.
          </p>

          <h2>Admission to M. Sc first year Courses:</h2>
          <p>
            50% of total seats in the MSc course are filled through CUET
            (PG)2024 Rank
            <br />
            50% of total seats in the MSc Course are filled through
            undergraduate 3-year duration in any discipline (BCA/bachelor’s
            degree in computer science and Engineering /B.Sc.) from a recognized
            University with a minimum of 60% marks.
            <br />
            Vacant Seats are inter-transferable between the above categories.
          </p>
          <p>
            <strong>Eligibility:</strong> The candidate should have passed
            undergraduate from a recognized Institution / University with the
            related subjects. For <b>M. Sc Chemistry</b> - Chemistry is
            mandatory in the UG program, <b>M. Sc Physics </b>- Physics is
            mandatory in the UG Program and <b>M. Sc Mathematics</b> –
            Mathematics is mandatory in the UG Program
          </p>

          <h2>Admission to MA first-year Courses:</h2>
          <p>
            50% of total seats in the MA Course are filled through CUET (PG)2024
            Rank
            <br />
            50% of total seats in the MA Course are filled through undergraduate
            3-year duration in any discipline (BCA/bachelor’s degree in computer
            science and engineering /B.Sc./B. Com /B. A) from a recognized
            University with a minimum of 60% marks.
            <br />
            Vacant Seats are inter-transferable between the above categories.
          </p>
          <p>
            <strong>Eligibility:</strong> A bachelor’s degree in any discipline
            or an equivalent qualification recognized by the UGC/AICTE with a
            minimum of 60% aggregate marks. For MA English - English is
            mandatory in the UG program and For MA Economics - Economics is
            mandatory in the UG Program
          </p>

          <h2>Admission to B Pharma first year Course: </h2>
          <p>
            50% of total seats in the B. Pharma Course are filled through CUET
            (UG)2024 Rank
            <br />
            Remaining seats in the B Pharma Course are filled through 10+2
            examination PCM/PCB/PCMB with a minimum of 50% marks.
            <br />
            Vacant Seats are inter-transferable between the above categories.
          </p>
          <p>
            <strong>Eligibility:</strong>Candidate shall have passed the 10+2
            examination conducted by the respective state/central government
            with English as one of the subjects and Physics, Chemistry,
            Mathematics/Biology as optional subjects individually securing a
            minimum of 60% marks in these subjects combined.
          </p>

          <h2>Admission to D Pharma first year Course: </h2>
          <p>
          Total available seats will be filled through JSS University, Noida counseling based on 10+2 examination with P.C.M and/or P.C.B/P.C.M.B, 
            <br />
            Any other qualification approved by the Pharmacy Council of India as equivalent to any of the above examinations.

          </p>
          <p>
            <strong>Eligibility:</strong>Candidate shall have passed10+2 examination with P.C.M and/or P.C.B/P.C.M.B, Any other qualification approved by the Pharmacy Council of India as equivalent to any of the above examinations.
          </p>
        </p>
      </section>
    </>
  );
}
