import React from "react";
export default function ISTEDetail() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "20vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <center>
          <h1>ISTE</h1>
        </center>
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          The Indian Society for Technical Education (ISTE) is the leading
          National Professional non-profit making Society for the Technical
          Education System in our country with the motto of Career Development
          of Teachers and Personality Development of Students and overall
          development of our Technical Education System. Being the only national
          organisation of educators in the field of Engineering and Technology,
          ISTE effectively contributes in various missions of the Union
          Government. The strength of ISTE is the strong base it has in
          technical education institutions in the country.
          <br />
          <br />
          <h4>Objective: -</h4>
          <ul style={{ listStyle: "disc" }}>
            <li>
              Providing quality training programmes to teachers and
              administrators of technical institutions to update their knowledge
              and skills in their fields of activity.
            </li>
            <li>
              Providing quality training programmes to teachers and
              administrators of technical institutions to update their knowledge
              and skills in their fields of activity
            </li>
          </ul>
          <br />
          <br />
          <h4>Benefits: -</h4>
          <ul style={{ listStyle: "disc" }}>
            <li>
              TIt organizes an annual convention for faculty and students
              separately every year where a large number of technocrats,
              technical teachers, policy makers, experts from the industry etc.
              participate and interact.{" "}
            </li>
            <li>
              Every year a National Seminar with a specific theme with respect
              to the latest development in the field of Science and Technology
              and societal problems{" "}
            </li>
            <li>
              ISTE is actively involved in many activities conducted by All
              India Council for Technical Education New Delhi (AICTE) and
              National Board of Accreditation New Delhi (NBA).{" "}
            </li>
          </ul>
        </p>
      </section>
    </>
  );
}
