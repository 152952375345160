import React from "react";
// import RainwaterHarvestingCarousel from "./components/RainwaterHarvestingCarousel";

export default function RainwaterHarvesting() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Rainwater Harvesting</h2>
        </center>
        <img src="images/green-initiatives/rainwater_harvesting.png" alt="Banner" className="centered-image w-100"  />

        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          JSS University Noida recognizes the importance of water conservation 
          and has implemented a comprehensive rainwater harvesting system on campus. 
          This system collects rainwater from rooftops, pathways, and open areas, 
          directing it to storage tanks or recharge pits for later use.
<br/><br/>
          The rainwater harvesting system at JSS University Noida serves multiple 
          purposes. Firstly, it helps in replenishing groundwater levels by allowing 
          rainwater to percolate into the soil. This contributes to the sustainability 
          of water resources in the region, especially during periods of low rainfall 
          or drought. Secondly, the harvested rainwater is treated and used for various 
          non-potable purposes on campus, such as irrigation, flushing toilets, and 
          maintaining green spaces.
<br/><br/>
          The university's rainwater harvesting infrastructure includes filtration 
          and purification mechanisms to ensure that the stored water meets quality 
          standards for its intended uses. Regular maintenance and monitoring of the 
          system are conducted to optimize its efficiency and effectiveness.
<br/><br/>
          Beyond its practical benefits, rainwater harvesting serves as an educational 
          tool for students and staff. The university organizes workshops, seminars, 
          and practical sessions to raise awareness about water conservation practices 
          and the importance of sustainable water management. Students are actively 
          involved in monitoring water usage and conducting research on improving 
          rainwater harvesting techniques.
<br/><br/>
          By integrating rainwater harvesting into its campus infrastructure and 
          educational initiatives, JSS University Noida demonstrates its commitment 
          to environmental stewardship and sustainability, preparing future generations 
          to address global water challenges responsibly.
        </p>
      </section>
    </>
  );
}
