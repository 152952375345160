import React, { useState, useEffect } from "react";
// import "./style.css";

export default function PharmacyDetail() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div
      style={{
        padding: windowWidth > 768 ? "100px" : "10px",
        backgroundColor: "#f0f0f0",
        borderRadius: "10px",
        boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.1)",
        margin:
          windowWidth > 768 ? "200px 20px 100px 20px" : "50px 20px 50px 20px",
        textAlign: windowWidth > 768 ? "justify" : "left",
      }}
    >
      <center>
        <h1 style={{ marginTop: "20px", marginBottom: "20px" }}>
          SCHOOL OF PHARMACY
        </h1>
        <div className="position-relative">
          <img
            src="images/pharmacy/img1.jpg"
            className="img-fluid w-100"
            style={{ height: "300px", objectFit: "cover" }}
          />
        </div>
      </center>

      <p style={{ marginTop: "20px", marginBottom: "20px", fontSize: "22px" }}>
        JSSMVP has vast experience in the field of Pharmacy education. Among top
        10 Pharmacy Colleges in India. Two are run by JSSMVP viz: JSS College of
        Pharmacy, Mysore (Est.1973) and JSS College of Pharmacy, Ooty, Tamilnadu
        (Estd.1980) and are ranked as top 4th and 6th Pharmacy Colleges in India
        respectively by NIRF 2023. Both are ISO certified, NBA accredited, and
        Certified by ACPE (Accreditation Council for Pharmacy Education), USA
        for excellent Teaching and Research activities.
      </p>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
        INFRASTRUCTURE
      </h3>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
        Department of Pharmaceutics
      </h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        These labs specifically focused to provide the practical exposures to
        the budding pharmacist for the basic understanding of pre-formulation
        study, formulation and evaluation of the different dosage forms. The lab
        is equipped with all the facilities to conduct the experiments as per
        syllabus.
      </p>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img2.jpg"
                alt="Tablet Punching Machine"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Tablet Punching Machine
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img3.jpg"
                alt="Tablet Disintegration Apparatus"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Tablet Disintegration Apparatus
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img4.jpg"
                alt="Tablet Dissolution Apparatus	"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Tablet Dissolution Apparatus
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
        Department of Pharmaceutical Chemistry
      </h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        These labs designed to carry out the testing and identification of
        various inorganic and organic compounds, synthesis and purification of
        chemical compounds with proper understanding of mechanism and reaction
        aspects. The lab is equipped with apparatus like magnetic stirrer, hot
        plate, hot air oven, heating mantle and fume hood to ensure working in
        safe environment. The lab also focused for the analysis of biological
        samples like urine, blood, sugars, amino acids, cholesterol, fats. It is
        well equipped with modern equipment like centrifuge and UV
        spectrophotometer for conduct the experiments as per syllabus.
      </p>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img5.jpg"
                alt="Biochemistry Lab"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Biochemistry Lab
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img6.jpg"
                alt="Medicinal Chemistry Lab"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Medicinal Chemistry Lab
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
        Pharmaceutical Analysis Lab (Central Instrumentation Lab)
      </h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        The lab is specifically designed for the students to study various
        analytical methods like titration, conductometry, potentiometry, limit
        tests and the principles of volumetric and electro chemical analysis.
        The lab is equipped with modern equipment like conductometer,
        potentiometer, pH meter, and UV spectrophotometry to develop analytical
        skills of the students.
      </p>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img7.jpg"
                alt="Pharmaceutical Analysis"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Pharmaceutical Analysis
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img8.jpg"
                alt="Double beam UV-Vis Spectrophotometer"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Double beam UV-Vis Spectrophotometer
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img9.jpg"
                alt="Infra-Red Moisture Balance & Polarimeter"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Infra-Red Moisture Balance & Polarimeter
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
        Department of Pharmacology
      </h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        This subject is designed to impart fundamental knowledge on the
        structure and functions of the various systems of human body. During the
        course of study, the students understand the detailed anatomy and
        physiology of various organs of the human body. Experiments for blood
        count, hemoglobin measurement, blood group, blood pressure and tidal
        volume estimation are conducted. The laboratory is equipped with
        instruments, apparatus, models and charts to demonstrate the physiology
        and anatomy of various body structures of human body.
      </p>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img10.jpg"
                alt="Human Anatomy and Physiology (HAP) Lab"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Human Anatomy and Physiology (HAP) Lab
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img11.jpg"
                alt="Pharmacology Lab"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Pharmacology Lab
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
        Department of Pharmacognosy
      </h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        The main purpose of Pharmacognosy lab is to impart the students the
        knowledge of plant drugs, Mineral and Marine sources of crude drugs.
        Also, this subject involves the study of producing the plants and
        phytochemicals through plant tissue culture, drug interactions and basic
        principles of traditional system of medicine. However, in Pharmacognosy
        the students supposed to know the modern extraction techniques,
        characterization and identification of the herbal drugs and
        phytoconstituents, and to understand the preparation and development of
        herbal formulation, understand the herbal drug interactions and also to
        carryout isolation and identification of phytoconstituents.
      </p>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img12.jpg"
                alt="Projection Microscope"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Projection Microscope
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img13.jpg"
                alt="Microscopy of Crude Drugs"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Microscopy of Crude Drugs
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img14.jpg"
                alt="Pharmacognosy Lab"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Pharmacognosy Lab
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
        Microbiology Lab
      </h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        This lab with its attached aseptic room as per GLP guidelines is
        suitable for preparation and sterilization of culture media both for
        static and broth cultures. It is also used for staining and
        identification of bacteria and fungi. The lab is suitable for the
        evaluation of antiseptics and disinfectants, for the determination of
        phenol coefficient, microbial assay of vitamins, antibiotics, etc. The
        lab is also engaged with the interdisciplinary research work in the
        domain of environmental sciences, allied health sciences.
      </p>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img15.jpg"
                alt="Microbiology Lab"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Microbiology Lab
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img16.jpg"
                alt="Laminar Cabinet in Aseptic Room"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Laminar Cabinet in Aseptic Room
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img17.jpg"
                alt="BOD Incubator"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                BOD Incubator
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>Computer Lab</h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        This lab design to introduce the Database, Database Management system,
        computer application in clinical studies and use of databases for
        designing and understanding of basic computer skill in Pharmacy.
      </p>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img18.jpg"
                alt="Information Center"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Information Center
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img19.jpg"
                alt="Computer Lab	"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Computer Lab
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
        Herbal Garden (Medicinal Plant Garden)
      </h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        College is maintaining herbal garden within the campus with more than 50
        species of important medicinal and aromatic plants, to carry out
        experimental work. It serves an important tool for teaching and
        research, to preserve and promote the medicinal values of the plants.
        Herbal garden is a place where students get exposure to various
        medicinal plants and get knowledge about their useful effects including
        their source, botanical names, cultivation techniques and their
        constituents etc.
      </p>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img20.jpg"
                alt="Medicinal Plant Garden"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Medicinal Plant Garden
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img21.jpg"
                alt="Medicinal Plant Garden"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Medicinal Plant Garden
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>Museum</h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        College has a well maintained museum in the institute. The theme of the
        museum is to show some sort of historical development in various
        processes of pharmacy, Drug storage containers, Proprietary (brand name)
        medicines, Models and charts, Herberium Sheets containing medicinal &
        aromatic plant specimens.
      </p>
      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img22.jpg"
                alt="Museum"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Museum
              </div>
            </div>
          </div>
        </div>
      </div>

      <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>
        Library and information centre
      </h4>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize: "18px" }}>
        Library and Information Centre supports the Educational and Research
        objectives of the institute, it provides information in the field of
        Pharmaceutical Sciences, along with Basic Science, Humanities,
        Management and related fields. The library aims to develop a
        comprehensive collection of books, journals e-books, and e-journals
        useful for the faculty, students and research community of the academy.
        The Library and Information Centre is fully automated with RFID enabled
        circulation system with air-conditioned space. The Library has good
        collection of books, journals, reports and dissertation. Library is
        subscribing printed journals/magazines, and it has ten online full text
        databases of Science Direct, Springer, EBSCO, Emerald, Pearson,
        Cambridge Core, Wiley, Taylor & Francis, New Age International, Sage
        Publication e-books & e-journals under Dr. APJ AKTU Nalanda e-consortium
        provided for the benefit of the faculty and research community.
        E-resources can be accessed within the campus or outside campus through
        My-Loft application. Library is automated using KOHA software with
        access through web, having own library portal.
      </p>

      <div className="container my-5">
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img23.jpg"
                alt="Library"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Library
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-5">
            <div className="position-relative">
              <img
                src="images/pharmacy/img24.jpg"
                alt="Library"
                className="img-fluid w-100"
                style={{ height: "300px", objectFit: "cover" }}
              />
              <div
                className="position-absolute bottom-0 start-0 w-100 text-center py-2"
                style={{
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  color: "white",
                }}
              >
                Library
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
