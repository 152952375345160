import React from "react";
// import GardenTreeCarousel from "./components/GardenTreeCarousel";

export default function GardeningTreePlantation() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Gardening & Tree Plantation</h2>
        </center>
        <img src="images/green-initiatives/37.jpg" alt="Banner" className="centered-image w-100"  />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          JSS University Noida is dedicated to enhancing its campus environment 
          through extensive gardening and tree plantation initiatives. These efforts 
          not only beautify the campus but also contribute significantly to 
          environmental conservation and biodiversity enhancement.
<br/><br/>
          Gardening at JSS University Noida encompasses the cultivation of diverse 
          plants and flowers across campus grounds. Well-maintained gardens enhance 
          aesthetic appeal, provide green spaces for relaxation and recreation, and 
          create habitats for pollinators and wildlife. Students, faculty, and staff 
          actively participate in gardening activities, learning about plant care, 
          landscaping techniques, and sustainable gardening practices.
<br/><br/>
          Tree plantation is a core component of the university's environmental 
          sustainability strategy. JSS University Noida continuously strives to 
          increase its green cover by planting a variety of native and ornamental 
          trees throughout the campus. Trees not only improve air quality by absorbing 
          carbon dioxide and releasing oxygen but also provide shade, reduce urban 
          heat island effect, and contribute to overall ecological balance.
<br/><br/>
          The university organizes tree plantation drives and events where students 
          and staff come together to plant saplings and nurture newly planted trees. 
          These activities instill a sense of environmental responsibility and 
          community engagement among participants, fostering a culture of 
          sustainability on campus.
<br/><br/>
          Furthermore, JSS University Noida emphasizes the importance of 
          environmental education and awareness. Workshops, seminars, and 
          educational campaigns are conducted to educate the campus community about 
          the benefits of gardening, tree plantation, and ecological conservation. 
          Students are encouraged to take leadership roles in environmental 
          initiatives, promoting sustainable practices and inspiring positive 
          environmental change.
<br/><br/>
          Through its gardening and tree plantation efforts, JSS University Noida 
          aims to create a greener, more vibrant campus environment while nurturing 
          a generation of environmentally conscious leaders.
        </p>
      </section>
    </>
  );
}
