import React from "react";

export default function AdmissionHelpDesk() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Admission Help Desk & Contacts</h2>
        </center>

        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          <h4>Address</h4>
          C-20/1 Sector 62, Noida, Uttar Pradesh
          <br />
          <br />
          <h4>Contact Details</h4>
          <b>Phone</b>:+91- 9311830458
          <br />
          <b>Email</b>: admissions.jssun@jssaten.ac.in
          <br />
          <b>Landline</b>: 0120-2401484 (Direct), 0120-2400115, 2401442, 2401449
          (EPBX)
        </p>
      </section>
    </>
  );
}
