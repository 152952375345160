import React  , {useState , useEffect} from "react";

export default function Scholarships() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  return (
    <>
      <section
        className="section"
        style={{
            position: "relative",
            top: windowWidth > 768 ? "125px" :"0px",
            marginBottom: "10vh",
            marginTop: "10vh",
            padding: windowWidth > 768 ? "80px" : "20px",
            height: "fit-content",

        }}
      >
        <center>
          <h2>Scholarship Details</h2>
        </center>
        <p
          style={{
            fontSize: "1.2em",
            marginTop: "20px",
          }}
        >
          Scholarship facilities are available for students under various
          schemes depending upon merit as well as category. Students who are
          eligible to apply can contact college scholarship staff member in the
          accounts section. Also refer scholarship “Student Support portal” of
          our college website. Do not apply through private agencies. All
          scholarship information is available in the website of respective
          categories.
        </p>
        <div
          id="collapseOne"
          class="card-body collapse show"
          data-parent="#accordion"
          style={{
            marginBottom:"40px"
          }}
        >
          <h4>Please refer the website information as mentioned below:</h4>

          <ul>
            <li>
              AICTE Prime Minister Special Scholarship Scheme (For J&amp;K and
              Uttarakhand students only) -
              <a
                href="https://www.jssateb.ac.in/assets/images/general/scholar1.pdf"
                class="btn view-btn"
                target="_blank"
              >
                Click Here
              </a>
            </li>
            <li>
              National Scholarship Portal - NSP: Minority (Muslim/
              Sikh/Jain/Christian) Scholarship: (Merit Cum means) -
              <a
                href="https://www.jssateb.ac.in/assets/images/general/scholar2.pdf"
                class="btn view-btn"
                target="_blank"
              >
                Click Here
              </a>
            </li>
            <li>
              National Scholarship Portal - NSP: AICTE “Pragati” and “Saksham”
              Scholarship schemes -
              <a
                href="https://www.jssateb.ac.in/assets/images/general/scholar5.pdf"
                class="btn view-btn"
                target="_blank"
              >
                Click Here
              </a>
            </li>
            <li>
              National Scholarship Portal - NSP: Central Sector Scheme (MHRD) -
              <a
                href="https://www.jssateb.ac.in/assets/images/general/scholar3.pdf"
                class="btn view-btn"
                target="_blank"
              >
                Click Here
              </a>
            </li>
            <li>
              State Scholarship Portal (SSP) - Post Matric Scholarship for SC,
              ST, Minority, Brahmin (EWS), Backward Classes, Relatives of
              Disabled and Disabled students &amp; Defence SSP Scholarship -
              <a
                href="https://www.jssateb.ac.in/assets/images/general/scholar4.pdf"
                class="btn view-btn"
                target="_blank"
              >
                Click Here
              </a>
            </li>
            {/* <li>
              Karnataka Labour Welfare Board scholarship -
              <a
                href="https://www.jssateb.ac.in/assets/images/general/scholar7.pdf"
                class="btn view-btn"
                target="_blank"
              >
                Click Here
              </a>
            </li> */}
            <li>
              Jindal Scholarship -
              <a
                href="https://www.jssateb.ac.in/assets/images/general/scholar6.pdf"
                class="btn view-btn"
                target="_blank"
              >
                Click Here
              </a>
            </li>
            <li>
              Hostel Management Information System -
              <a
                href="https://www.jssateb.ac.in/assets/images/general/scholar8.pdf"
                class="btn view-btn"
                target="_blank"
              >
                Click Here
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
