import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const undergradCourseList = [
  "Computer Science and Engineering",
  "Computer Science and Engineering (AI/ML)",
  "Computer Science and Engineering (Data Science)",
  "Information Technology",
  "Electronics and Communication Engineering",
  "Robotics and Artificial Intelligence",
  "B.Pharm",
];

export default function ApplyForAdmission() {
  const [adm_data, setAdm_data] = useState({
    applicantName: "",
    fatherName: "",
    fatherOccupation: "",
    dob: "",
    gender: "",
    applicantAddress: "",
    city: "",
    district: "",
    state: "",
    pincode: "",
    parentsMobile: "",
    parentsEmail: "",
    applicantMobile: "",
    applicantEmail: "",
    applicantCategory: "",
    programType: "",
    jeeDetails: "",
    cuetDetails: "",
    marks10th: "",
    marks12th: "",
    cgpi: "",
    graduationMarks: "",
    btechCourse: "",
    postGraduateCourse: "",
    pcmPercentage: "",
    class10thBoard: "",
    class12thBoard: "",
    class10thPercentage: "",
    class12thPercentage: "",
  });

  const [selectedUndergradCourses, setSelectedUndergradCourses] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const handleCheckboxChange = (course) => {
    if (selectedUndergradCourses.includes(course)) {
      // Remove the course if already selected
      setSelectedUndergradCourses(
        selectedUndergradCourses.filter((c) => c !== course)
      );
      setWarningMessage(""); // Clear the warning message if any
    } else {
      if (course === "B.Pharm") {
        setSelectedUndergradCourses([course]);
        setWarningMessage(""); // Clear the warning message if any
      } else if (selectedUndergradCourses.includes("B.Pharm")) {
        // Show a warning message if trying to select another course when "Music" is selected
        setWarningMessage(
          'You cannot select another course when "B.Pharm" is selected.'
        );
      } else {
        // Add the course if less than 3 are selected
        if (selectedUndergradCourses.length < 3) {
          setSelectedUndergradCourses([...selectedUndergradCourses, course]);
          setWarningMessage(""); // Clear the warning message if any
        } else {
          // Show a warning message if trying to select more than 3 courses
          setWarningMessage("You can only select up to 3 courses.");
        }
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdm_data((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(adm_data); // Send adm_data to the backend
    setIsSubmitting(true);
    // set all empty values to NA string
    for (let key in adm_data) {
      if (adm_data[key].trim() == "") {
        adm_data[key] = "NA";
      }
    }
    // set btechCourse to selectedUndergradCourses
    if (
      selectedUndergradCourses.length !== 0 &&
      adm_data.programType === "Undergraduate"
    ) {
      adm_data.btechCourse = selectedUndergradCourses.join(", ");
    }
    const base_url =
      "https://script.google.com/macros/s/AKfycbxUgkoiQGwPyowMp15rnF0uAVuNODAAxGMkEytvMjvF-FEo5aCNt-F_Gofe6UbyTcac/exec";
    const url = `${base_url}?applicantName=${adm_data.applicantName}&fatherName=${adm_data.fatherName}&fatherOccupation=${adm_data.fatherOccupation}&dob=${adm_data.dob}&gender=${adm_data.gender}&applicantAddress=${adm_data.applicantAddress}&city=${adm_data.city}&district=${adm_data.district}&state=${adm_data.state}&pincode=${adm_data.pincode}&parentsMobile=${adm_data.parentsMobile}&parentsEmail=${adm_data.parentsEmail}&applicantMobile=${adm_data.applicantMobile}&applicantEmail=${adm_data.applicantEmail}&applicantCategory=${adm_data.applicantCategory}&programType=${adm_data.programType}&jeeDetails=${adm_data.jeeDetails}&cuetDetails=${adm_data.cuetDetails}&marks10th=${adm_data.marks10th}&marks12th=${adm_data.marks12th}&cgpi=${adm_data.cgpi}&graduationMarks=${adm_data.graduationMarks}&btechCourse=${adm_data.btechCourse}&postGraduateCourse=${adm_data.postGraduateCourse}&pcmPercentage=${adm_data.pcmPercentage}&class10thBoard=${adm_data.class10thBoard}&class12thBoard=${adm_data.class12thBoard}&class10thPercentage=${adm_data.class10thPercentage}&class12thPercentage=${adm_data.class12thPercentage}`;

    try {
      const response = await fetch(url, {
        method: "POST",
      });

      if (response.status === 200) {
        console.log("Form submitted successfully");
      } else {
        console.log("Error:", response.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    setIsSubmitting(false);
    setAdm_data({
      applicantName: "",
      fatherName: "",
      fatherOccupation: "",
      dob: "",
      gender: "",
      applicantAddress: "",
      city: "",
      district: "",
      state: "",
      pincode: "",
      parentsMobile: "",
      parentsEmail: "",
      applicantMobile: "",
      applicantEmail: "",
      applicantCategory: "",
      programType: "",
      jeeDetails: "",
      cuetDetails: "",
      marks10th: "",
      marks12th: "",
      cgpi: "",
      graduationMarks: "",
      btechCourse: "",
      postGraduateCourse: "",
      pcmPercentage: "",
      class10thBoard: "",
      class12thBoard: "",
      class10thPercentage: "",
      class12thPercentage: "",
    });

    window.scrollTo(0, 0);

    navigate("/thank-you-admission");
  };

  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "150px",
          marginBottom: "10vh",
          marginTop: "10vh",
          //   padding: "20rem 10rem",
        }}
      >
        <div className="container">
          <center>
            <h1>Admission Form 2024</h1>
          </center>
          <form onSubmit={handleSubmit} className="form">
            <div className="form-group">
              <label for="applicantName">Name of the Applicant:</label>
              <input
                type="text"
                name="applicantName"
                value={adm_data.applicantName}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Father's Name:</label>
                <input
                  type="text"
                  name="fatherName"
                  value={adm_data.fatherName}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label>Father's Occupation:</label>
                <input
                  type="text"
                  name="fatherOccupation"
                  value={adm_data.fatherOccupation}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>DOB Of Applicant:</label>
                <input
                  type="date"
                  name="dob"
                  value={adm_data.dob}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label>Gender:</label>
                <select
                  name="gender"
                  value={adm_data.gender}
                  onChange={handleChange}
                  required
                  className="form-control"
                >
                  <option value="">Select Gender</option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Transgender">Transgender</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <label>Applicant Address:</label>
              <input
                type="text"
                name="applicantAddress"
                value={adm_data.applicantAddress}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>City:</label>
                <input
                  type="text"
                  name="city"
                  value={adm_data.city}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label>District:</label>
                <input
                  type="text"
                  name="district"
                  value={adm_data.district}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>State:</label>
                <input
                  type="text"
                  name="state"
                  value={adm_data.state}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label>Pincode:</label>
                <input
                  type="text"
                  name="pincode"
                  value={adm_data.pincode}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Parents Mobile No:</label>
                <input
                  type="text"
                  name="parentsMobile"
                  value={adm_data.parentsMobile}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label>Parents Email Address (Optional):</label>
                <input
                  type="email"
                  name="parentsEmail"
                  value={adm_data.parentsEmail}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Applicant Mobile No:</label>
                <input
                  type="text"
                  name="applicantMobile"
                  value={adm_data.applicantMobile}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label>Applicant's Email:</label>
                <input
                  type="email"
                  name="applicantEmail"
                  value={adm_data.applicantEmail}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Applicant Category:</label>
                <select
                  name="applicantCategory"
                  value={adm_data.applicantCategory}
                  onChange={handleChange}
                  required
                  className="form-control"
                >
                  <option value="">Select Category</option>
                  <option value="GENERAL">GENERAL</option>
                  <option value="OBC">OBC</option>
                  <option value="SC">SC</option>
                  <option value="ST">ST</option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Type of Program:</label>
                <select
                  name="programType"
                  value={adm_data.programType}
                  onChange={handleChange}
                  required
                  className="form-control"
                >
                  <option value="">Select Program</option>
                  <option value="Undergraduate">Undergraduate</option>
                  <option value="Postgraduate">Postgraduate</option>
                  <option value="Diploma">Diploma</option>
                </select>
              </div>
            </div>
            {adm_data.programType === "Diploma" && (
              <>
                <div className="form-group">
                  <label>Diploma Course:</label>
                  <select
                    name="postGraduateCourse"
                    value={adm_data.postGraduateCourse}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="">Select Course</option>
                    <option value="MCA">Diploma in Pharmacy</option>
                  </select>
                </div>
              </>
            )}
            {adm_data.programType === "Undergraduate" && (
              <>
                <div className="form-group">
                  <label>Select upto 3 courses:</label>
                  {warningMessage && (
                    <div className="alert alert-danger" role="alert">
                      {warningMessage}
                    </div>
                  )}
                  {undergradCourseList.map((course) => (
                    <div key={course}>
                      <input
                        style={{
                          height: "20px",
                          width: "20px",
                          display: "inline",
                        }}
                        className="form-control"
                        type="checkbox"
                        id={course}
                        name={course}
                        value={course}
                        checked={selectedUndergradCourses.includes(course)}
                        onChange={() => handleCheckboxChange(course)}
                      />
                      <label htmlFor={course}> {course}</label>
                    </div>
                  ))}
                </div>
              </>
            )}
            {/* {adm_data.programType === "Undergraduate" && (
              <>
                <div className="form-group">
                  <label>Course:</label>
                  <select
                    name="btechCourse"
                    value={adm_data.btechCourse}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="">Select Course</option>
                    <option value="CSE">
                      Computer Science and Engineering
                    </option>
                    <option value="CSE with AIML">
                      Computer Science and Engineering (AI/ML)
                    </option>
                    <option value="CSE with DS">
                      Computer Science and Engineering (Data Science)
                    </option>
                    <option value="IT">Information Technology</option>
                    <option value="ECE">
                      Electronics and Communication Engineering
                    </option>
                    <option value="RAI">
                      Robotics and Artificial Intelligence
                    </option>
                    <option value="B.Pharm">B.Pharm</option>
                  </select>
                </div>
              </>
            )} */}
            {adm_data.programType == "Undergraduate" &&
              !selectedUndergradCourses.includes("B.Pharm") && (
                <div className="form-group">
                  <label>
                    Rank of JEE Main Entrance for B.Tech First Year:
                  </label>
                  <input
                    type="number"
                    name="jeeDetails"
                    value={adm_data.jeeDetails}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              )}
            {adm_data.programType === "Diploma" && (
              <div className="form-group">
                <label>Details of JEE-CUP Rank</label>
                <input
                  type="text"
                  name="cuetDetails"
                  value={adm_data.cuetDetails}
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
            )}
            {adm_data.programType === "Postgraduate" && (
              <>
                <div className="form-group">
                  <label>Post Graduate Course:</label>
                  <select
                    name="postGraduateCourse"
                    value={adm_data.postGraduateCourse}
                    onChange={handleChange}
                    className="form-control"
                  >
                    <option value="">Select Course</option>
                    <option value="MCA">MCA</option>
                    <option value="MBA-Gen">MBA-General</option>
                    <option value="MBA-Finance">MBA-Finance</option>
                    <option value="M.Sc in Physics">M.Sc in Physics</option>
                    <option value="M.Sc in Chemistry">M.Sc in Chemistry</option>
                    <option value="M.Sc in Maths">M.Sc in Maths</option>
                    <option value="MA in Economics">MA in Economics</option>
                    <option value="MA in English">MA in English</option>
                  </select>
                </div>
              </>
            )}
            {selectedUndergradCourses.includes("B.Pharm") &&
              adm_data.programType !== "Diploma" && (
                <div className="form-group">
                  <label>
                    Details of CUET-
                    {adm_data.programType === "Postgraduate" ? "PG" : "UG"}{" "}
                    entrance test
                  </label>
                  <input
                    type="text"
                    name="cuetDetails"
                    value={adm_data.cuetDetails}
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              )}
            {/* <div className="form-group">
                <label>Details of CUET-UG/PG entrance test (For Lateral Students)</label>
                <input
                type="text"
                name="cuetDetails"
                value={adm_data.cuetDetails}
                onChange={handleChange}
                className="form-control"
                />
            </div> */}
            {adm_data.programType === "Postgraduate" && (
              <>
                <div className="form-row">
                  <div className="form-group col-md-6">
                    <label>CGPI obtained in graduation:</label>
                    <input
                      type="text"
                      name="cgpi"
                      value={adm_data.cgpi}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>Total marks obtained in graduation:</label>
                    <input
                      type="text"
                      name="graduationMarks"
                      value={adm_data.graduationMarks}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </>
            )}
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Class 10th Board:</label>
                <select
                  name="class10thBoard"
                  value={adm_data.class10thBoard}
                  onChange={handleChange}
                  required
                  className="form-control"
                >
                  <option value="">Select Board</option>
                  <option value="CBSE">CBSE</option>
                  <option value="ICSE">ICSE</option>
                  <option value="UP Board">UP Board</option>
                  <option value="Others">Others</option>
                </select>
              </div>
              <div className="form-group col-md-6">
                <label>Class 12th Board:</label>
                <select
                  name="class12thBoard"
                  value={adm_data.class12thBoard}
                  onChange={handleChange}
                  required
                  className="form-control"
                >
                  <option value="">Select Board</option>
                  <option value="CBSE">CBSE</option>
                  <option value="ICSE">ICSE</option>
                  <option value="UP Board">UP Board</option>
                  <option value="Others">Others</option>
                </select>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>Total Marks in 10th class:</label>
                <input
                  type="number"
                  name="marks10th"
                  value={adm_data.marks10th}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label>Total Marks in 12th class:</label>
                <input
                  type="number"
                  name="marks12th"
                  value={adm_data.marks12th}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label>
                  Class 12th Percentage:
                  {adm_data.class12thBoard === "CBSE" && <b>(6 subjects)</b>}
                  {adm_data.class12thBoard === "ICSE" && <b>(5 subjects)</b>}
                  {adm_data.class12thBoard === "UP Board" && (
                    <b>(All subjects)</b>
                  )}
                  {adm_data.class12thBoard === "Others" && (
                    <b>(All subjects)</b>
                  )}
                </label>
                <input
                  type="number"
                  name="class12thPercentage"
                  value={adm_data.class12thPercentage}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
              <div className="form-group col-md-6">
                <label>PCM Percentage in 12th class:</label>
                <input
                  type="number"
                  name="pcmPercentage"
                  value={adm_data.pcmPercentage}
                  onChange={handleChange}
                  required
                  className="form-control"
                />
              </div>
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary"
            >
              {isSubmitting && <span className="fa fa-spinner fa-spin"></span>}{" "}
              &nbsp;&nbsp;
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </form>
        </div>
      </section>
    </>
  );
}
