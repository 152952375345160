import React from "react";
import swamiji_image from "../../assets/images/swamiji.jpg";

export default function Chancellor() {
  
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "10vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <div className="centered-image">
          <div>
            <h1 style={{ textAlign: "center" }}>Chancellor</h1>
            <h2 style={{ textAlign: "center" }}>
              Jagadguru Sri Shivarathri Deshikendra Mahaswamiji
            </h2>
          </div>
          <div>
            <img
              src={swamiji_image}
              alt="Chancellor Jagadguru Sri Shivarathri Deshikendra Mahaswamiji"
              style={{
                maxWidth: "80vh",
                height: "auto",
                display: "block",
              }}
            />
          </div>
        </div>
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          We are happy to state that all the members of the JSS University,
          Noida are committed to work to create a vibrant future for this
          institution. To achieve this end, the University has an excellent
          strategic plan, one that builds on the legacy of success provided by
          the JSS Mahavidyapeetha in providing quality education to the country
          and combining academic excellence with an unprecedented commitment to
          innovation, quality and service.
          <br />
          <br />
          JSS University, Noida is the outcome of concerted efforts to bring
          about structural re-orientation to tertiary education, particularly
          technical education under the ageis of JSS Mahavidyapeetha. The
          purpose of establishment of JSS Mahavidyapeetha, the hallowed arm of
          Sri Suttur Math, was to translate for the committed values of Srimath
          cherished by generations of Pontiffs of the Srimath into reality.
          ‘Education for all’ being the fundamental and endeared value of the
          organization for decades, the much needed push to raise the bar and
          nurture the talent to confront the global standards was availed
          through the establishment of JSS University, Noida. JSS
          Mahavidyapeetha continues to experiment with the contemporary tools of
          education to blend the cutting edge technology and skill development
          with the ethos of this great nation while aligning with the global
          benchmarks.
          <br />
          <br />
          The genesis of JSS University, Noida has come at a time when JSS
          Mahavidyapeetha is embracing a socio-economic responsibility, as its
          mission, in a large scale to mark the event of centenary celebrations
          HH Jagadguru Sri Dr. Shivarathri Rajendra Mahaswamiji, the founder of
          JSS Mahavidyapeetha. One can be confident that the university would
          redefine the purpose and objective of technical education with its
          newfound authority by making a mark in the tapestry of Indian
          technical education. This dream could be achieved with proactive
          participation of all the stake holders. The collective action injects
          sustainability and true success comes only from collective efforts.
          The desirability could be made possibilities with the consistent
          cooperation and assistance of all, as it should align with the
          nation’s vision for innovation, skill and research.
          <br />
          <br />
          JSS University, Noida welcomes all concerned to be a part of the
          transformation by ushering in a new thinking for strong and powerful
          India. The best of the times are at the door steps of India, while the
          world is agog with immensity of the country’s potential. Joint efforts
          can hasten the process of transmutation to make it worth a great deal
          to teach youngsters not only how to make a living but also how to make
          a life. Education is the soul of society and one has a moral
          obligation to use it as a tool to transmit the civilization to next
          generation. The University, hopefully, would do everything possible to
          achieve this.
          <br />
          <br />
          <h4>BIOGRAPHY</h4>
        </p>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            "margin-bottom": "100px",
          }}
        >
          His Holiness Jagadguru Sri Shivarathri Deshikendra Mahaswamiji, the
          present pontiff and the 24th Peethadhyaksha of Sri Jagadguru
          Veerasimhasana Suttur Math, assumed the Peetha in 1986 at the young
          age of 29 years. He had his spiritual training under the guidance of
          his illustrious predecessor H H Jagadguru Dr Sri Shivarathri Rajendra
          Mahaswamiji the founder president of the JSS Mahavidyapeetha, an
          educational and cultural organization dedicated to the ideal of
          providing education to the villages of Karnataka. His Holiness Sri
          Shivarathri Deshikendra Mahaswamiji, the Honourable Chancellor of JSS
          University, Noida - a visionary, endeavoured not only to cross the
          Vindhyas and establish engineering colleges, schools, etc at NOIDA
          (UP) and the JSS Spiritual and Cultural Centre at New Delhi but also
          travelled beyond the shores of the country and established the
          presence of the Math in USA, Mauritius and Dubai. His efforts to reach
          out to the core of the problems that challenge our society be it
          literacy, health care, employment or building values amongst the
          society is lauded and recognised by one and all.
        </p>
        <h4>MESSAGE FROM CHANCELLOR</h4>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          We are happy to state that all the members of the JSS University,
          Noida are committed to work to create a vibrant future for this
          institution. To achieve this end, the University has an excellent
          strategic plan, one that builds on the legacy of success provided by
          the JSS Mahavidyapeetha in providing quality education to the country
          and combining academic excellence with an unprecedented commitment to
          innovation, quality and service.
          <br />
          <br />
          JSS University, Noida is the outcome of concerted efforts to bring
          about structural re-orientation to tertiary education, particularly
          technical education under the ageis of JSS Mahavidyapeetha. The
          purpose of establishment of JSS Mahavidyapeetha, the hallowed arm of
          Sri Suttur Math, was to translate for the committed values of Srimath
          cherished by generations of Pontiffs of the Srimath into reality.
          ‘Education for all’ being the fundamental and endeared value of the
          organization for decades, the much needed push to raise the bar and
          nurture the talent to confront the global standards was availed
          through the establishment of JSS University, Noida. JSS
          Mahavidyapeetha continues to experiment with the contemporary tools of
          education to blend the cutting edge technology and skill development
          with the ethos of this great nation while aligning with the global
          benchmarks.
          <br />
          <br />
          The genesis of JSS University, Noida has come at a time when JSS
          Mahavidyapeetha is embracing a socio-economic responsibility, as its
          mission, in a large scale to mark the event of centenary celebrations
          HH Jagadguru Sri Dr. Shivarathri Rajendra Mahaswamiji, the founder of
          JSS Mahavidyapeetha. One can be confident that the university would
          redefine the purpose and objective of technical education with its
          newfound authority by making a mark in the tapestry of Indian
          technical education. This dream could be achieved with proactive
          participation of all the stake holders. The collective action injects
          sustainability and true success comes only from collective efforts.
          The desirability could be made possibilities with the consistent
          cooperation and assistance of all, as it should align with the
          nation’s vision for innovation, skill and research.
          <br />
          <br />
          <i>
            {" "}
            This is your Academy and the legacy of greatness that we must carry
            forward belongs to our future generations.
          </i>
          <br />
          <br />
          <b>
            His Holiness
            <br />
            Jagadguru Sri Shivarathri Deshikendra Mahaswamiji
            <br />
            Chancellor
            <br />
          </b>
          <br/><br/>
          <br/><br/>
        </p>
      </section>
    </>
  );
}
