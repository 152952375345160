import React from "react";

export default function AboutVisionMissionPolicy() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Vision, Mission and Quality Policy</h2>
        </center>

        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          <h4>Vision</h4>
          <ul style={{listStyleType:"disc"}}>
            <li>
              JSS University Noida aims to become an Institution of excellence
              in imparting quality Outcome Based Education that empowers the
              young generation with Knowledge, Skills, Research, Aptitude and
              Ethical values to solve Contemporary Challenging Problems.
            </li>
          </ul>

          <h4>Mission</h4>
          <ul style={{listStyleType:"disc"}}>
            <li>
            Develop a platform for achieving globally acceptable level of intellectual acumen and technological competence.
            </li>
            <li>
            Create an inspiring ambience that raises the motivation level for conducting quality research.
            </li>
            <li>
            Provide an environment for acquiring ethical values and positive attitude.
            </li>
          </ul>

          <h4>Quality Policy</h4>
          <ul style={{listStyleType:"disc"}}>
            <li>
            Achieve Excellence in Technical Education, Research and Consultancy through an Outcome Based Education.
            </li>
            <li>
            Focus on Continuous Improvement and Innovation by Benchmarking Against the Global Best Practices.
            </li>
          </ul>
        </p>
      </section>
    </>
  );
}
