import React , {useState , useEffect} from 'react';
import "./style.css";

export default function IT() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{
        padding:windowWidth > 768 ? "100px" : "10px",
        backgroundColor:"#f0f0f0",
        borderRadius:"10px",
        boxShadow:"0 0 10px 0 rgba(0, 0, 0, 0.1)",
        margin:windowWidth > 768 ? "200px 20px 100px 20px" : "50px 20px 50px 20px",
        textAlign:windowWidth > 768 ? "justify" : "left"
    }}>
      <h2 style={{ marginTop: "20px", marginBottom: "20px" }}>B. Tech - IT</h2>
      
      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>1. Comprehensive Curriculum</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        The B.Tech in Information Technology (IT) at JSS University, Noida, is designed to provide students with a strong foundation in the principles of information technology while also equipping them with the skills necessary to excel in today’s digital world. The curriculum is a blend of core IT subjects and emerging technologies, ensuring students are well-prepared for the ever-evolving tech industry.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Core IT Knowledge:</strong> The program begins with fundamental courses in computer science, including Programming, Data Structures, Algorithms, Operating Systems, and Computer Networks. These courses establish a solid understanding of the technical aspects of IT, which are essential for developing and managing information systems.
        </li>
        <li>
          <strong>Specialized IT Courses:</strong> As students advance, the curriculum covers specialized subjects such as Web Development, Database Management Systems, Cloud Computing, Cybersecurity, and Software Engineering. These courses provide students with the knowledge and skills required to design, implement, and manage IT solutions in various domains.
        </li>
        <li>
          <strong>Practical Learning:</strong> The program emphasizes hands-on learning through labs, projects, and assignments. Students gain practical experience by working on real-world IT problems, developing software applications, managing databases, and ensuring the security of information systems.
        </li>
        <li>
          <strong>Capstone Projects:</strong> In the final year, students undertake capstone projects where they apply their learning to solve complex IT challenges. These projects often involve collaboration with industry partners, allowing students to work on cutting-edge technologies and gain valuable industry experience.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>2. Future Scope and Job Opportunities</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        The IT industry is one of the most dynamic and rapidly growing sectors, offering a wide range of career opportunities for graduates of the B.Tech in IT program.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Diverse Career Opportunities:</strong> Graduates can pursue careers as Software Developers, IT Consultants, Network Administrators, Database Administrators, System Analysts, Web Developers, Cybersecurity Analysts, and Cloud Architects. These roles are in high demand across various industries, including technology, finance, healthcare, education, and government.
        </li>
        <li>
          <strong>Growing Demand for IT Professionals:</strong> The demand for IT professionals is on the rise as organizations across the globe continue to digitize their operations and adopt new technologies. This demand ensures strong job prospects and competitive salaries for IT graduates.
        </li>
        <li>
          <strong>Emerging Technologies:</strong> The IT field is constantly evolving, with new technologies such as Artificial Intelligence, Internet of Things (IoT), Blockchain, and Edge Computing becoming increasingly important. The B.Tech in IT program at JSS University prepares students to stay ahead of these trends and work in innovative fields that are shaping the future.
        </li>
        <li>
          <strong>Global Career Opportunities:</strong> The skills and knowledge gained from the B.Tech in IT program are highly transferable, allowing graduates to pursue careers in international markets. With a degree from JSS University, students are well-equipped to work in global IT hubs such as Silicon Valley, London, Singapore, and Bengaluru.
        </li>
        <li>
          <strong>Entrepreneurship:</strong> The IT industry offers numerous opportunities for entrepreneurship. Graduates can start their own tech ventures, developing innovative software solutions, launching web-based services, or providing IT consulting services to businesses.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>3. Higher Studies and Research Opportunities</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        The B.Tech in IT provides a strong foundation for students interested in pursuing higher studies and research in Information Technology and related fields.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Advanced Degrees in IT:</strong> Graduates can pursue advanced degrees such as M.Tech, MS, or Ph.D. in Information Technology, Computer Science, Cybersecurity, or Software Engineering. The rigorous curriculum and research opportunities provided by the B.Tech in IT program ensure that students are well-prepared for these advanced studies.
        </li>
        <li>
          <strong>Research-Oriented Learning:</strong> JSS University encourages a research-driven approach, offering students opportunities to engage in cutting-edge research projects. These projects often explore advanced topics such as network security, cloud computing, and human-computer interaction, contributing to the advancement of knowledge in the IT field.
        </li>
        <li>
          <strong>Collaborative Research:</strong> The university collaborates with industry and academic institutions on various research initiatives, providing students with the chance to work on interdisciplinary research projects. These collaborations often lead to publications in reputed journals and presentations at international conferences.
        </li>
        <li>
          <strong>Innovation in IT:</strong> The focus on innovation at JSS University encourages students to think creatively and develop new IT solutions to address complex challenges. This innovative mindset is essential for those pursuing research careers or advanced studies, where they can contribute to the development of new technologies and methodologies in the IT field.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>4. State-of-the-Art Infrastructure</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        JSS University, Noida, offers top-notch infrastructure that supports the specialized needs of the B.Tech in IT program, ensuring that students have access to the latest tools and technologies.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Modern IT Labs:</strong> The university is equipped with state-of-the-art IT laboratories, featuring high-performance computing resources and advanced networking equipment. These labs provide an environment where students can gain hands-on experience with the latest IT tools and technologies.
        </li>
        <li>
          <strong>Industry-Standard Software and Tools:</strong> Students have access to industry-standard software tools such as Java, Python, SQL, Oracle, VMware, and AWS. These tools are essential for developing software applications, managing databases, and implementing IT solutions. The university ensures that students are proficient in these tools, making them industry-ready upon graduation.
        </li>
        <li>
          <strong>Extensive Network Resources:</strong> Networking is a critical aspect of IT, and JSS University provides students with access to extensive networking resources. These resources are crucial for conducting experiments, setting up virtual networks, and developing secure information systems.
        </li>
        <li>
          <strong>Smart Classrooms:</strong> The university’s smart classrooms are equipped with modern teaching aids that enhance interactive and immersive learning experiences. These classrooms support hybrid learning models, where students can participate in virtual labs, attend guest lectures from industry experts, and collaborate on projects remotely.
        </li>
        <li>
          <strong>Innovation and Incubation Centers:</strong> JSS University’s innovation and incubation centers are designed to foster creativity and entrepreneurship. These centers provide students with the resources and mentorship needed to develop IT solutions, from initial concept to prototype development and commercialization.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>5. Industry Connections and Placement Support</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        The B.Tech in IT program at JSS University benefits from the university’s strong industry connections and comprehensive placement support, which are crucial for students transitioning into the workforce.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Industry-Academia Collaboration:</strong> The IT program is developed in collaboration with industry experts, ensuring that the curriculum is aligned with the latest industry requirements. Regular workshops, seminars, and guest lectures by professionals from leading IT companies provide students with insights into industry trends and expectations.
        </li>
        <li>
          <strong>Internships and Live Projects:</strong> JSS University’s strong ties with the tech industry facilitate internships and live projects, giving students practical experience in real-world IT applications. These internships often lead to job offers, as companies prefer to hire candidates who have demonstrated their skills in a professional setting.
        </li>
        <li>
          <strong>Dedicated Placement Cell:</strong> The university’s placement cell is proactive in preparing students for the job market. It offers training in resume building, interview techniques, and soft skills, ensuring that students are well-prepared for recruitment processes. The placement cell also organizes campus recruitment drives, attracting top IT companies to hire from the university.
        </li>
        <li>
          <strong>Global Career Opportunities:</strong> The placement cell’s strong connections with multinational companies provide students with global career opportunities. Graduates have the chance to work with leading organizations in IT, both in India and internationally, in roles that offer challenging and rewarding career paths.
        </li>
        <li>
          <strong>Strong Alumni Network:</strong> The university’s alumni network is a valuable resource for current students. Alumni working in prominent positions in IT companies often return to mentor students, offer internships, and share their experiences. This network provides students with guidance and networking opportunities that are critical for career success.
        </li>
      </ul>

      <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>6. Holistic Development</h3>
      <p style={{ marginTop: "10px", marginBottom: "10px", fontSize:"22px" }}>
        JSS University is committed to the holistic development of its students, ensuring they graduate as well-rounded individuals ready to excel in the rapidly evolving field of Information Technology.
      </p>
      <ul className='course-section'>
        <li>
          <strong>Technical and Non-Technical Clubs:</strong> The university hosts a variety of clubs and societies that cater to both technical and non-technical interests. IT enthusiasts can join coding clubs, participate in hackathons, and engage in collaborative projects. Meanwhile, cultural, sports, and arts clubs offer avenues for students to explore their passions beyond academics.
        </li>
        <li>
          <strong>Soft Skills Enhancement:</strong> The program emphasizes the development of soft skills, such as communication, leadership, teamwork, and critical thinking. These skills are integral to the success of IT professionals, who often work in interdisciplinary teams and need to articulate complex ideas to diverse audiences.
        </li>
        <li>
          <strong>Social Initiatives and Community Service:</strong> JSS University encourages students to participate in social initiatives, where they can apply their IT skills to solve real-world problems. Projects like IT for social good, digital literacy initiatives, and e-governance solutions not only provide practical experience but also instill a sense of social responsibility.
        </li>
        <li>
          <strong>Global Exposure:</strong> Through exchange programs, international internships, and collaborations with global research institutions, students gain exposure to the international IT community. This global perspective is invaluable in a field as dynamic and widespread as IT, preparing students to work in diverse environments and contribute to global innovations.
        </li>
        <li>
          <strong>Mental and Physical Well-being:</strong> The university offers resources to support the mental and physical well-being of its students. Counseling services, fitness centers, and wellness programs are available to help students manage stress and maintain a healthy lifestyle, ensuring they can perform at their best academically and personally.
        </li>
      </ul>

      <p style={{ marginTop: "20px", marginBottom: "20px", fontSize:"22px" }}>
        The B.Tech in Information Technology (IT) at JSS University, Noida, is a comprehensive program that equips students with the skills and knowledge required to excel in the fast-growing field of Information Technology. With a curriculum that combines strong foundational knowledge with specialized IT techniques, state-of-the-art infrastructure, and a focus on practical experience, students are well-prepared to tackle the challenges of the digital world. Whether your goal is to work in top IT companies, pursue advanced studies, or launch your own tech startup, the B.Tech in IT from JSS University provides the platform you need to succeed.
      </p>
    </div>
  );
}