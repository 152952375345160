import React from "react";
import pro_chancellor_img from "../../assets/images/pro_chancellor.png";

export default function AboutProChancellor() {
  return (
    <>
      <section className="section"
        style={{
            position: "relative",
            top: "185px",
            marginBottom: "20vh",
            marginTop: "10vh",
            padding: "2rem",
        }}
      >
        <div className="centered-image">
        <div>
        <h2>Pro Chancellor Dr. B. Suresh</h2>
        </div>
        <div>
        <img src={pro_chancellor_img} alt="Pro Chancellor Dr. B. Suresh"
          style={{
            maxWidth: "90%",
            height: "auto",
            display: "block",
          }}
        />
        </div>
        </div>
        <br/><br/>
        <h4>In pursuit of excellence</h4>
        <p className="" style={{
            "textAlign": "justify",
            "fontSize": "1.2em",
        }}>
        A university needs to be a place where knowledge is created, where meeting of minds takes place, where innovation gives birth to the future needs, and the student comes out as a responsible and enlightened citizen. The relevance of the university and the impact that it is making on the society are the heart and soul of any university to be universally recognized and understood. Built on the strong foundation and legacy of JSS institutions. ‘Quality’ and ‘Excellence’ have been the key drivers of the JSS University, Noida which is evident from the various strides it has made in the benchmarking initiatives nationally and internationally in a short span of ten years.
        <br/><br/>
        The developmental initiatives of JSS Academy of Higher Education and Research, Mysuru was not limited by the idea of a physical environment but traversed the whole realm of education. The good governance structure and extraordinary leadership of the institution played a catalyst’s role for it to progress quickly and continue its journey to the next level of advancement. The committed faculty, the diverse student community, the strategic national and international co-operations and infusion of right amount of resources on right projects have added value towards its success journey. The coming together of the Health Sciences focused Institution was the beginning, keeping together and expanding responsibly showed the progress and working together gave several successes to the institution. JSSAHER’s ongoing planning process will continue to provide the framework for its growth and development into the future, shaped by multiple societal and economic trends.
        <br/><br/>
        <b>Dr. B. Suresh, M.Pharm., Ph.D., D.Sc.,</b>
        <br/>
        <b>Pro Chancellor</b>
        </p>

        </section>
    </>
  );
}
