import React from "react";

export default function STEPInfo() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          <div class="col-md-9 col-lg-9 col-sm-12 col-xs-12 ftco-animate fadeInUp ftco-animated">

                <h2 class="mb-3 csheader-tag">SCIENCE &amp; TECHNOLOGY ENTREPRENEURS PARK (STEP)</h2>
                <div>
                    <p>Nourishing entrepreneurial spirit in technocrats for achieving global excellence</p>
                    <p>Innovation| technology | Business</p>
                </div>
                <h4 class="library-title ">ABOUT STEP</h4>
                <p>JSS Business Incubator is engaged in the promotion of entrepreneurship development of knowledge-based and innovation-driven technology ventures that promote employment opportunities.</p>
                <p>JSS Business Incubator, starting in 2004, is India’s first Technology Business Incubator (TBI), supported by DST under the TBI scheme. It has supported over 300 start-ups, with 11 successful exits on investment.</p>
                <div class="d-lg-flex online-payment">
                    <p><b>For More Details</b></p>
                    <a href="https://www.jssstepnoida.org/" target="_blank" class="btn view-btn online-btn">Click here</a>
                </div>
                <h4 class="library-title ">Key vision includes</h4>
                <ul style={{listStyle:'disc'}}>
                    <li>Identify, engage and hand-hold potential ideas to convert into a formal business opportunity</li>
					<li>Support founders in capacity and capability building</li>
					<li>Build knowledge pool of mentors, suitable for new ventures and provide them on demand</li>
					<li>Engage with govt. departments and private investors to create a channel of their funds to reach deserving technology ventures</li>
					<li>Understand challenges from founders and if a pattern emerges, take them to the Govt, for policy intervention</li>
					<li>Enable local, national, and/or global market access through connections and partnerships and facilitate it to its incubated ventures</li>
                </ul>
				<h4 class="library-title ">Thrust Area of the Incubator</h4>
				<p>JSS ATE STEP nurtures post ideation stage ventures, in TRL 6 and above. Our USP is go-to-market for the businesses to take to market, usually to Corporate, Govt and NGO in India. 
98% of the startups that the Incubator supported have used Information Technology as its core. Our focus on IT are technologies such as IOT, AI, ML, Computer Vision, Robotics, Drones, Blockchain, AR/VR, Quantum computing. Having such a focus has helped the incubator to have mentors and corporate who has such a focus too. 
</p>
                <p>From Sep 2020, post pandemic, Incubator also supported a cohort of 10 startups in healthcare (under our Electifi-Healthcare accelerator program). Out of these, four are not using IT as part of their product development, however, they have added value with their product for the beneficiaries, as was the need of the market. 
</p>
                <div class="d-lg-flex online-payment">
                    <p><b>For More Details</b></p>
                    <a href="https://www.jssstepnoida.org/" target="_blank" class="btn view-btn online-btn">Click here</a>
                </div>
                <h4 class="library-title ">Why us?</h4>
                <p>“Your idea, execution ability and our mentoring, investment avenues make a great team”
It is proven fact that incubation of a venture helps increase its success. We at incubator know investors, have mentors, know corporate interested in working with startups, have experience and knowledge of guiding founders of how to navigate the entrepreneurial journey inexpensively and by avoiding costly mistakes.
This way we are in a position to fast track your venture's go-to-market, and thus increase its chances of success.
</p>
                <h4 class="library-title ">Facilities /Amenities</h4>
				<ul style={{listStyle:'disc'}}>
				  <li>Area of approx. 18,000 sq ft.</li>
				  <li>35 Furnished work cabins (6 to18 seater)</li>
				  <li>Plug and Play facility to start your work in No time.</li>
				  <li>Conference, Discussion, and Meeting rooms</li>
				  <li>Common Reception Lounge</li>
				  <li>Seminar Hall</li>
				  <li>Cafeteria and Pantry</li>
				  <li>Access to labs: Computer, IT, Electronics, Mechanics, etc.</li>
                  <li>High bandwidth Internet connectivity (1:1 lease line)</li>
				  <li>Business facilitation (Xerox, Telephone, etc.)</li>
				  <li>100% power backup (DG Set, UPS)</li>
				  <li>Ample free parking spaces for 2 and 4 wheelers</li>
				  <li>Technical Support through Faculty &amp; Existing Incubatee companies</li>
				  <li><b>Other Services Included:</b>
				     <ul>
					   <li>Reception Services</li>
				       <li>24x7 Security Services</li>
				       <li>Housekeeping</li>
				       <li>Documentation support &amp; facilitation (Accounts/legal)</li>
				        <li>HR assistance and access to a pool of Interns</li>
					 </ul>
				  </li>
				</ul>
				
                <div class="d-lg-flex online-payment">
                    <p><b>For More Details</b></p>
                    <a href="https://www.jssstepnoida.org/" target="_blank" class="btn view-btn online-btn">Click here</a>
                </div>
				
				
				<h2 class="mb-3 csheader-tag">Support &amp; Benefits</h2>
                <h4 class="library-title ">Mentors</h4>
                <p>To refine Idea, vet the Business plan and help to create a strategy</p>
                <h4 class="library-title ">Investment Support</h4>
                <p>Seed fund support provided of upto 20-25 Lakh on a case merit basis</p>
                <h4 class="library-title ">Investor Match-making</h4>
                <p>Enabling meetups with Angel, Venture capitalist, HNI, corporate investors, and Partners</p>
                <h4 class="library-title ">Corporate R&amp;D handshaking</h4>
                <p>Provide projects to technology start-up to co-create pilot and products.</p>
				 <h4 class="library-title ">GST Exemption</h4>
                <p>For 3 years from the date of incubation (50L turnover) and no GST on Incubation charges.</p>
				 <h4 class="library-title ">Accelerator Program</h4>
                <p> Enhance your chances of success by removing the costly strategic mistakes. Investors knowing the start-ups over a longer period, de-risk their investment</p>
				<h4 class="library-title ">Value Partnerships</h4>
                <p> Over 30 partners providing value services to our incubatee start-ups on-demand at a discounted cost.</p>
				<h4 class="library-title ">Learning and Networking Events</h4>
                <p>Learn from industry professionals, other startup experiences, and other ecosystem partners in the events and meeting we organize.</p>
				
                <h2 class="mb-3 csheader-tag">Services</h2>
                <h4 class="library-title ">Plug-n-play office space</h4>
                <p>
                  With 100 Mbps high speed Internet, 24 x 7 Open, Centrally air-conditioned, 100% back-up power, access control, ample parking, CCTV, 24 x 7 security, shared meeting rooms, conference halls, 2000 capacity auditorium, cafeteria. Flexible space or dedicated cabins. Part of 28 Acre college Green Campus, backed by JSS Mahavidhyapeetha, Mysore - 370+ institutions in India, and Abroad
                </p>
                <h4 class="library-title ">Incubation</h4>
                <p>
                    Physical and virtual over max 3 years, gradual progress over stages A, B, &amp; C

                </p>
                <h4 class="library-title ">Mentors</h4>
                <p>
                    In-house mentors, three level of mentoring - leadership development, technology and business model, domain / industry level 
                </p>
                <h4 class="library-title ">Tech Due-Diligence</h4>
                <p>
                    Validate Architecture, code review, performance &amp; Scalability

                </p>
				<h4 class="library-title ">Value Partnerships</h4>
                <p>
                     We want you to focus on your core business, that's why we have over 30 partners providing services to our incubatee start-ups on demand. Vendor deals worth over $15,000

                </p>
				<h4 class="library-title ">Events</h4>
                <p>Learn from industry professionals, other startup experiences and other eco-system partners in the events and meeting we organize
                </p>
				<h4 class="library-title ">Investment Support</h4>
                <p>
                    Seed fund support provided on case merit basis.

                </p>
				<h4 class="library-title ">Investor Connect</h4>
                <p>
                   Facilitate Angel, Venture capitalist, HNI and corporate investors to fund in our start-ups. Institutional investment, for growth

                </p>
				<h4 class="library-title ">Corporate R&amp;D handshaking</h4>
                <p>
                   Project to provide technology start-up to co-create pilot and products.That corporate may wish to take to their customers in India and abroad. We seed such partnerships
                </p>
				<h4 class="library-title ">Value-Add Programs</h4>
                <p>
                    Accelerator, Startupreneur, WEDP, TEDP, etc., to inculcate the spirit of entrepreneurship and to boost the performance of our incubatees
                </p>
				<h4 class="library-title ">Competency Center</h4>
                <p>
                   Technology and domain skill enhancement training arranged by bringing industry experts
                </p>
               
                <div class="d-lg-flex online-payment">
                    <p><b>For More Details</b></p>
                    <a href="https://www.jssstepnoida.org/" target="_blank" class="btn view-btn online-btn">Click here</a>
                </div>
                <h4 class="library-title ">Programmes Conducted by STEP:</h4>
                <ul  style={{listStyle:'disc'}}>
                    <li>Electrifi 1.0: Business Accelerator Program</li>
                    <li>Electrifi 3T: Travel, Tourism, &amp; Transportation – Sector Specific Business Accelerator Program</li>
                    <li>Electrifi 3.0: Mobility &amp; Logistics – Sector Specific Business Accelerator Program</li>
                    <li>Women Entrepreneurship Development Program</li>
                    <li>Technology-Based Entrepreneurship Development Program</li>
					<li>Global Startupreneur Program</li>
					<li>NIDHI Entrepreneur-In-Residence Program</li>
					<li>Incubation Program</li>
					<li>Virtual Incubation Program</li>
					<li>Startup-Investor meetup program</li>
					<li>Choose-Your-Mentor Program</li>
					<li>Design Sprints and Design Thinking program</li>
					<li>JSS-GIZ German Cooperation Program</li>
					<li>BIZZNESS - Student Entrepreneur Development Program</li>
					<li>Student-Startup Hiring Program</li>
					<li>Product Life-Cycle Management Competency Centre</li>
					<li>Centre for Advanced Manufacturing Technology</li>
					<li>Entrepreneurship Development &amp; Training</li>
                </ul>
                <h4 class="library-title ">For More details Contact : </h4>
                <p><b>Website Details: </b> https://www.jssstepnoida.org/ </p>
                <p>Dr. Divya Rajput</p>
                <p>Chief Executive</p>
                <p>ceo@jssstepnoida.org</p>
            </div>
          <br />
          <br />
        </p>
      </section>
    </>
  );
}
