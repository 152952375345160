import React from "react";

export default function StatutoryBodies() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "10vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <center>
          <h2>Statutory Bodies</h2>
        </center>
        <br />
        <br />
        <div width="100%" style={{ marginBottom: "100px" }}>
          <div
            className="row"
            style={{ justifyContent: "center", alignItems: "center" }}
          >
            {/* <center> */}
            <a href="https://jssuninoida.edu.in/assets/docs/AICTE_Approval_for_JSS_University_Noida.pdf" target="_blank">
              <div className="card hover-shadow" style={{ margin: "30px" }}>
                <div className="card-body">
                  <h5 className="card-title"><u>AICTE Approval</u></h5>
                  <img
                    src="images/aicte-logo.png"
                    width="250px"
                    height="250px"
                  />
                </div>
              </div>
            </a>
            <a href="https://jssuninoida.edu.in/assets/docs/UP_Govt_Gazzete_Notification-Approval_for_JSS_University_Noida.pdf" target="_blank">
              <div className="card hover-shadow" style={{ margin: "30px" }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <u>UP Government Gazzete Notifications</u>
                  </h5>
                  <img
                    src="images/up_gov_logo.png"
                    width="250px"
                    height="250px"
                  />
                </div>
              </div>
            </a>
            <a href="https://www.ugc.gov.in/universitydetails/Private_university_other?id=1313" target="_blank">
              <div className="card hover-shadow" style={{ margin: "30px" }}>
                <div className="card-body">
                  <h5 className="card-title">
                    <u>University Grant Commission</u>
                  </h5>
                  <img
                    src="images/ugc_logo.png"
                    width="250px"
                    // height="300px"
                  />
                </div>
              </div>
            </a>
            {/* </center> */}
          </div>
        </div>
      </section>
    </>
  );
}
