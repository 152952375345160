import React from "react";

export default function CustomUnderConstruction() {
    return (
        <section className="container"
        style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}
        >
            <div className="row">
                <div className="col-md-12">
                    <h1>Under Construction</h1>
                    <p style={{fontSize:"1.5em"}}>
                        The page you are looking is under construction. Please visit later.
                    </p>
                    <a href="/" className="btn btn-primary">
                        Go to Homepage
                    </a>
                </div>
            </div>
        </section>
    );
}
