import React from "react";
export default function IEEEDetail() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "20vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <center>
          <h1>IEEE</h1>
        </center>
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          IEEE is the world's largest technical professional organisation which
          is dedicated to advancing technology for the benefit of humanity. IEEE
          and its members inspire a global community through its highly cited
          publications, conferences, technology standards, and professional and
          educational activities. IEEE student branch provides opportunities to
          meet and learn from fellow IEEE Student and Graduate Student Members
          and engage with professional IEEE members locally. An active IEEE
          Student Branch can be one of the most positive elements of the
          academic career, offering programs, activities, and professional
          networking opportunities that build critical skills outside of the
          classroom. IEEE currently has Student Branches at thousands of
          universities and colleges in hundreds of countries throughout the
          world. pursuits.
          <br />
          <br />
          <h4>Objective: -</h4>
          <ul style={{ listStyle: "disc" }}>
            <li>Evolve through continuing education</li>
            <li>CTo access the most breakthrough research </li>
            <li>
              Develop student activities (including conferences, contests, guest
              lectures etc.)
            </li>
            <li>
              Opens the door to opportunities that'll help students develop
              their professional identities in IEEE designated fields of
              interest STEM (Science, Technology, Engineering and Mathematics).
            </li>
            <li>Network globally with professionals</li>
          </ul>
          <br />
          <br />
          <h4>Benefits: -</h4>
          <ul style={{listStyle:'disc'}}>
            <li>Upgrade cutting edge latest technical knowledge</li>
            <li>Obtain funding for events, projects and activities</li>
          </ul>
        </p>
      </section>
    </>
  );
}
