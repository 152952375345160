import React from "react";
import GreenPolicyCarousel from "./components/GreenPolicyCarousel";

export default function GreenPolicy() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>University Green Policy</h2>
        </center>
        <img src="images/green-initiatives/20.jpg" alt="Banner" className="centered-image w-100"  />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
JSS University Noida, is deeply committed
          to environmental sustainability. The university has implemented a
          comprehensive Green Policy to promote eco-friendly practices across
          the campus. This policy encompasses a wide range of initiatives aimed
          at reducing the university's carbon footprint and fostering a culture
          of environmental responsibility.
<br/><br/>
          Key components of the Green Policy include waste reduction programs,
          such as extensive recycling efforts and the minimization of single-use
          plastics. Energy conservation measures have been implemented
          campus-wide, including the installation of energy-efficient lighting,
          the promotion of energy-saving practices, and the use of renewable
          energy sources like solar power. The university has also established
          green building standards for new constructions and renovations,
          ensuring that all infrastructure projects adhere to sustainable
          design principles.
<br/><br/>
          In addition to infrastructural changes, the university actively
          engages students, faculty, and staff in its green initiatives. Various
          programs and events are organized to raise awareness about
          environmental issues and encourage the campus community to adopt
          sustainable practices in their daily lives. These efforts include
          workshops, seminars, and volunteer opportunities related to
          environmental conservation and sustainability.
<br/><br/>
JSS University Noida, continuously seeks to
          innovate and improve its Green Policy, exploring new ways to enhance
          sustainability on campus. Through these ongoing efforts, the
          university aims to set a benchmark for environmental stewardship in
          higher education and inspire other institutions to follow suit.
        </p>
      </section>
    </>
  );
}
