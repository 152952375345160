import React from "react";
// import WasteManagementCarousel from "./components/WasteManagementCarousel";

export default function SustainableWasteManagement() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Sustainable Solid Waste Management</h2>
        </center>
        <img src="images/green-initiatives/31.jpg" alt="Banner" className="centered-image w-100"  />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          JSS University Noida is committed to sustainable solid waste management 
          practices that prioritize environmental responsibility and resource 
          efficiency. The university has implemented a comprehensive waste management 
          system designed to minimize waste generation, promote recycling, and 
          responsibly dispose of residual waste.
<br/><br/>
          The sustainable waste management approach at JSS University Noida begins 
          with waste reduction strategies across campus. Initiatives such as 
          promoting the use of reusable materials, minimizing packaging waste, and 
          encouraging composting of organic waste help reduce the overall volume 
          of waste generated.
<br/><br/>
          Recycling plays a crucial role in the university's waste management strategy. 
          Dedicated recycling bins are placed strategically across campus to collect 
          paper, plastics, glass, and metals. These materials are sorted, processed, 
          and sent to recycling facilities, where they are transformed into new 
          products, thereby conserving natural resources and reducing landfill waste.
<br/><br/>
          For non-recyclable waste, JSS University Noida emphasizes responsible disposal 
          practices. The university collaborates with certified waste management 
          companies to ensure that residual waste is handled in compliance with 
          environmental regulations. Efforts are made to minimize the environmental 
          impact of waste disposal through proper segregation, treatment, and disposal 
          methods.
<br/><br/>
          Additionally, the university promotes awareness and education about sustainable 
          waste management among students, faculty, and staff. Educational campaigns, 
          workshops, and events are organized to foster a culture of waste reduction 
          and recycling on campus. Students are encouraged to participate in waste 
          audit programs and research projects focused on improving waste management 
          practices.
<br/><br/>
          Through its sustainable solid waste management initiatives, JSS University 
          Noida aims to lead by example in environmental stewardship, contributing 
          to a cleaner, healthier campus environment and preparing future leaders 
          committed to sustainable development.
        </p>
      </section>
    </>
  );
}
