import React, { useState, useEffect } from "react";

export default function CanteenCarousel() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth > 768 ? (
        <div
          style={{
            minHeight: "80vh",
            position: "relative",
            top: "10px",
            paddingBottom:"50px",
            zIndex: "-1",
          }}
        >
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <ol class="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="images/facilities/cafe1.jpg"
                  class="d-block w-100"
                  style={{ height: "70vh" }}
                  alt="Image 1"
                />
              </div>
              <div className="carousel-item">
                <img
                  src="images/facilities/cafe2.jpg"
                  class="d-block w-100"
                  alt="Image 1"
                  style={{ height: "70vh" }}
                />
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Previous</span>
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      ) : (
        <div
          style={{
            maxHeight: "50vh",
            position: "relative",
            top: "0px",
            marginBottom: "0px",
            zIndex: "-1",
          }}
        >
          <div
            id="carouselExampleIndicators"
            class="carousel slide"
            data-ride="carousel"
          >
            <ol class="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                class="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <img
                  src="images/facilities/cafe1.jpg"
                  class="d-block w-100"
                  style={{ height: "40vh" }}
                  alt="Image 1"
                />
              </div>
              <div className="carousel-item">
                <img
                  src="images/facilities/cafe2.jpg"
                  class="d-block w-100"
                  alt="Image 1"
                  style={{ height: "40vh" }}
                />
              </div>
            </div>
            <a
              class="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span
                class="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              {/* <span class="sr-only">Previous</span> */}
            </a>
            <a
              class="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span
                class="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              {/* <span class="sr-only">Next</span> */}
            </a>
          </div>
        </div>
      )}
    </>
  );
}
