import React, { useRef, useState, useEffect } from "react";
import swamiji_image from "../../assets/images/swamiji.jpg";

export default function SchoolOfManagement() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        style={{
          height: "fit-content",
          flexDirection: windowWidth > 768 ? "row" : "column",
          display: "flex",
          justifyContent: "center",
          alignItems: windowWidth > 768 ? "" : "center",
          paddingTop: windowWidth > 768 ? "20vh" : "10vh",
          gap: "40px",
        }}
      >
        <nav
          class="d-flex flex-column shadow-sm py-3 navbar"
          style={{
            width: "250px",
            marginTop: "45px",
            backgroundColor: "#0054A4",
            padding: "10px",
            borderRadius: "10px",
            color: "white",
            height: "fit-content",
          }}
        >
          <p
            style={{
              backgroundColor: "rgb(230, 120, 23)",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "20px",
              width: "100%",
              textAlign: "center",
              color: "white",
              fontWeight: "bold",
            }}
          >
            PG Schools
          </p>
          <a class="nav-link text-white side-menu-link active"
            href="/school-of-management"
            >
            &gt; School Of Management
          </a>
          <a
            class="nav-link text-white side-menu-link"
            href="/school-of-life-sciences"
          >
            &gt; School Of Computer Applications
          </a>
          <a
            class="nav-link text-white side-menu-link"
            href="/school-of-humanities"
          >
            &gt; School Of Humanities
          </a>
        </nav>

        <section class="section-sm" style={{
          position:"relative",
          top:"30px"
        }}>
          <div class="container">
            <div class="row">
              <div class="col-12 mb-4">
                <a href="/admission-apply">
                  <img
                    src="images/admission_apply_now.png"
                    class="img-fluid w-100"
                    style={{ height: "auto" }}
                    alt="Admission Apply Now"
                  />
                </a>
              </div>
            </div>
            <div class="row align-items-center mb-5">
              <div class="col-xl-6 order-1 col-sm-6 mb-4 mb-xl-0">
                <h2>School Of Management</h2>
              </div>

              <div class="col-xl-6 text-sm-right text-left order-sm-2 order-3 order-xl-3 col-sm-6 mb-4 mb-xl-0">
                <a href="/admission-apply" class="btn btn-primary">
                  Apply now
                </a>
              </div>
              <div class="col-12 mt-4 order-4">
                <div class="border-bottom border-primary"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6 mb-4">
                <div class="card shadow">
                  <div class="card-body">
                    <h5 class="card-title">MBA (General)</h5>
                    <p class="card-text">
                      The MBA (General) program provides a comprehensive
                      foundation in business fundamentals, preparing students
                      for a variety of management roles. The curriculum covers
                      areas like marketing, finance, accounting, human
                      resources, operations management, and business strategy.
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-6 mb-4">
                <div class="card shadow">
                  <div class="card-body">
                    <h5 class="card-title">MBA (Finance)</h5>
                    <p class="card-text">
                      The MBA (Finance) program delves deeper into financial
                      theory and analysis, equipping students with the skills to
                      excel in the financial services industry. The curriculum
                      covers areas like corporate finance, investment
                      management, financial markets, risk management, and
                      financial modeling.
                    </p>
                  </div>
                </div>
              </div>

              {/* Add more programme information boxes here */}

              <div class="col-12 mb-5">
                <h3>Words From HOD's Desk</h3>
                <p>
                  {/* Add content about the HOD message here */}
                  "Welcome to JSS University School of Management! We are
                  committed to shaping future business leaders by providing a
                  rigorous academic program with a focus on practical
                  application. Our esteemed faculty and industry collaborations
                  ensure that students gain the knowledge and skills to excel in
                  a globalized marketplace..."
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
