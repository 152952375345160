import React from "react";


export default function Hostel() {
    return (
      <>
        <section className="section"
          style={{
              position: "relative",
              top: "185px",
              marginBottom: "100px",
              padding: "100px",
  
          }}
        >
              <center>
              <h2>Hostel Facilities</h2>
              </center>
              <img src="./images/4.png" alt="Banner" className="centered-image w-100"  />
              <br/><br/>
              <p className="" style={{
                  "textAlign": "justify",
                  "fontSize": "1.2em",
              }}>
                The College has separate hostel facilities for both boys & girls. Rooms are spacious, clean, airy and well maintained with common bathrooms and are available on multi-sharing basis.
Each room is provided with beds, wardrobes, study tables and chairs. Round-the-clock power back-up facility as well as security is available.
Water purifiers and water coolers are installed to provide potable water. Provision has been made for AC equipped rooms to meet the specific requirement of the students.
Both the hostels have limited capacity and will be available for UG students only. The allotment of hostel will be done on FIRST COME FIRST SERVE basis.
Hostel has a provision for a visitor(s) room, a spacious well-lit common room with a large television and comfortable seating arrangement, recreational facilities, facilities for indoor games, newspapers & magazines, wi-fi connectivity.
Spacious dining rooms and modern kitchens ensure that students are served with nutritious food in a good ambiance. There is a dedicated team consisting of wardens and caretakers to help, guide and solve problems of the boarders. Students are advised to read the rules and regulations of the college related to hostel.
              </p>
  
          </section>
      </>
    );
  }