import React, { useRef, useState, useEffect } from "react";

export default function SchoolOfAppliedSciences() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
        <div
          style={{
            height: "fit-content",
            flexDirection: ((windowWidth > 768) ? "row" : "column"),
            display: "flex",
            justifyContent: "center",
            alignItems: ((windowWidth > 768) ? "" : "center"),
            paddingTop: ((windowWidth > 768) ? "20vh" : "10vh"),
            gap: "40px",
          }}
        >
      <nav
        class="d-flex flex-column shadow-sm py-3 navbar"
        style={{
          width: "250px",
          marginTop: "45px",
          backgroundColor: "#0054A4",
          padding: "10px",
          borderRadius: "10px",
          color: "white",
          height: "fit-content",
        }}
      >
        <p
          style={{
            backgroundColor: "rgb(230, 120, 23)",
            padding: "10px",
            borderRadius: "5px",
            marginBottom: "20px",
            width: "100%",
            textAlign: "center",
            color: "white",
            fontWeight: "bold",
          }}
        >
          Navigation Bar
        </p>
        <a
          class="nav-link text-white side-menu-link"
          href="/school-of-engineering"
        >
          &gt; School Of Engineering
        </a>
        <a
          class="nav-link text-white side-menu-link"
          href="/school-of-pharmacy"
        >
          &gt; School Of Pharmacy
        </a>
        <a
          class="nav-link text-white side-menu-link"
          href="/school-of-management"
        >
          &gt; School Of Management
        </a>
        {/* <a
          class="nav-link text-white side-menu-link"
          href="/school-of-life-sciences"
        >
          &gt; School Of Life Sciences
        </a> */}
        <a
          class="nav-link text-white side-menu-link"
          href="/page-under-development"
        >
          &gt; School Of Applied Sciences
        </a>
      </nav>

      <section class="section-sm" style={{
          position:"relative",
          top:"30px"
        }}>
        <div class="container">
          <div class="row">
            <div class="col-12 mb-4">
              <a href="#">
              <img
                src="images/admission_apply_now.png"
                class="img-fluid w-100"
                style={{ height: "auto" }}
              />
              </a>
            </div>
          </div>
          <div class="row align-items-center mb-5">
            <div class="col-xl-6 order-1 col-sm-6 mb-4 mb-xl-0">
              <h2>School Of Applied Sciences</h2>
            </div>

            <div class="col-xl-6 text-sm-right text-left order-sm-2 order-3 order-xl-3 col-sm-6 mb-4 mb-xl-0">
              <a href="#" class="btn btn-primary">
                Apply now
              </a>
            </div>
            <div class="col-12 mt-4 order-4">
              <div class="border-bottom border-primary"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-4">
              <h3>About Course</h3>
              <p>
                Embark on a journey of innovation and discovery at the new JSS
                University School of Engineering. Nestled within a dynamic
                academic environment, our institution is committed to fostering
                the next generation of engineering leaders. Through rigorous
                curriculum and hands-on experience, students engage in
                cutting-edge research and practical application, preparing them
                for the challenges of tomorrow's technological landscape. With
                state-of-the-art facilities and a renowned faculty, we empower
                students to push boundaries, solve complex problems, and shape
                the future of engineering with creativity and ingenuity.
              </p>
            </div>

            <div class="col-12 mb-4">
              <h3 class="mb-3">Programs Offered</h3>
              <ul class="list-styled">
                <li>Computer Science and Engineering (CSE)</li>
                <li>
                  Computer Science and Engineering (Artificial Intelligence and
                  Machine Learning)
                </li>
                <li>
                  Computer Science and Engineering (Data Science) Information
                  Technology
                </li>
                <li>Information Technology</li>
                <li>Electronics and Communication Engineering</li>
                <li>Robotics and Artificial Intelligence</li>
              </ul>
            </div>
            {/* <div class="col-12 mb-5">
              <h3>Words From HOD's Desk</h3>
              <p>
                "Welcome to JSS University School of Engineering! As the Head of
                Department, I am thrilled to invite you to embark on a
                transformative journey of learning and innovation. Our esteemed
                faculty and state-of-the-art facilities await to nurture your
                talents and cultivate your potential. Together, let us explore
                the frontiers of engineering, pushing boundaries and creating
                solutions that impact the world. Join us in shaping the future
                of technology, where curiosity thrives and excellence reigns.
                Your adventure starts here, at the heart of innovation. Welcome
                to a community dedicated to engineering excellence." - Dr.
                Kakoli Banarjee, Head of Department
              </p>
            </div> */}
            {/* <div class="col-12">
              <h5 class="mb-3">Managed Under</h5>
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="media mb-2 mb-sm-0">
                  <img
                    class="mr-4 img-fluid"
                    src="images/teacher.jpg"
                    alt="Teacher"
                  />
                  <div class="media-body">
                    <h4 class="mt-0"> Dr. Kakoli Banarjee</h4>
                    HOD
                  </div>
                </div>
                <div class="social-link">
                  <h6 class="d-none d-sm-block">Social Link</h6>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a class="d-inline-block text-light p-1" href="#">
                        <i class="ti-facebook"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="d-inline-block text-light p-1" href="#">
                        <i class="ti-twitter-alt"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="d-inline-block text-light p-1" href="#">
                        <i class="ti-linkedin"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="d-inline-block text-light p-1" href="#">
                        <i class="ti-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="border-bottom border-primary mt-4"></div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
    </>
  );
}
