import React from "react";
import ab1_image from "../../assets/images/AB1.jpg";

export default function AboutUniversity() {
  return (
    <>
      <section className="section"
        style={{
            position: "relative",
            top: "185px",
            marginBottom: "200px",
            marginTop: "10vh",
            padding: "2rem",

        }}
      >
            <center>
            <h2>About University</h2>
            </center>
            <img src={ab1_image} alt="Banner" className="centered-image w-100"  />
            <br/><br/>
            <h4>In pursuit of excellence</h4>
            <p className="" style={{
                "textAlign": "justify",
                "fontSize": "1.2em",
            }}>
            JSS University, Noida is built on a strong reputation of JSS Academy of Technical Education, Noida, and passionately committed for providing education in Science, Technology, Management and Life Sciences & Pharmacy. It is Steadfast to find solutions to some of the great challenges of our time through scientific research and technological innovations.
            <br/><br/>
            JSS University, Noida is the fourth University established by JSS Mahavidyapeetha, besides the JSS Academy of Higher Education and Research which is health sciences focused deemed university at Mysuru, JSS Science and Technology University a state private University at Mysuru and JSS Academy of Higher Education and Research at Mauritius.
            <br/><br/>
            JSS University, Noida plays a key role in providing high quality skilled manpower in the fields of Science, Engineering & Technology and Management, Pharmacy field to transform the society through research and innovation.
            <br/><br/>
            JSS University, Noida is committed to deliver quality education at all levels and provide opportunities for aspiring youth to meet the global needs of society in every sector.
            </p>

        </section>
    </>
  );
}
