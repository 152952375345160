import React, { useEffect, useState } from "react";

export default function Iqac() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        style={{
          position: "relative",
          top: windowWidth > 768 ? "125px" :"0px",
          marginBottom: "10vh",
          marginTop: "10vh",
          padding: windowWidth > 768 ? "80px" : "20px",
          height: "fit-content",
        }}
      >
        <h2>About IQAC</h2>
        <p>
          The primary responsibility of the Internal Quality Assurance Cell
          (IQAC) within the college is to ensure, uphold, and elevate quality
          across all domains. Serving as the central body for quality oversight,
          the IQAC operates under the leadership of the principal and includes
          senior faculty members, representatives from the local community, and
          a student representative. Its overarching goal is to cultivate and
          sustain a framework that fosters both academic and administrative
          excellence. Here's how the IQAC fulfils its mandate: Setting
          Objectives: The IQAC delineates both short-term and long-term
          objectives for the institution, providing a clear direction for
          quality enhancement efforts. Establishing Standards: It establishes
          benchmarks for quality improvement initiatives, serving as a yardstick
          against which progress can be measured. Developing Action Plans: The
          IQAC devises comprehensive action plans to accomplish its objectives,
          outlining the steps required to enhance quality across various aspects
          of the institution. Monitoring and Coordination: It actively monitors
          the implementation of these action plans and coordinates efforts
          across different departments to ensure alignment with overarching
          quality enhancement goals. The IQAC operates on the principle that
          excellence and quality are not static endpoints but ongoing processes.
          As such, it convenes regularly to review progress and identify areas
          for improvement. Strategic Plan 2021-2026 – The PDF is Attached for
          this. This can be added here as pdf College Best Practices – PDF is
          attached for the same which can be added to this page. The pdf is
          existing in the NAAC page, which can be removed. Institutional
          Distinctiveness - PDF is attached for the same which can be added to
          this page. The pdf is existing in the NAAC page, which can be removed.
        </p>
      </div>
    </>
  );
}
