import React from "react";

export default function RooftopSolarPowerPlant() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Rooftop Solar Power Plant</h2>
        </center>
        <img src="images/green-initiatives/rooftop_solar.png" alt="Banner" className="centered-image w-100"  />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          JSS University Noida has taken a significant step towards sustainability 
          by installing a state-of-the-art rooftop solar power plant. This initiative 
          reflects the university's commitment to reducing its carbon footprint and 
          promoting the use of renewable energy sources.
<br/><br/>
          The rooftop solar power plant harnesses the abundant solar energy available 
          in the region, converting it into electricity that powers various facilities 
          on campus. The system comprises numerous solar panels installed on the roofs 
          of campus buildings, ensuring optimal exposure to sunlight throughout the day. 
          This setup not only helps in generating clean energy but also significantly 
          reduces the university's reliance on non-renewable energy sources.
<br/><br/>
          The solar power plant is equipped with advanced technology to monitor and 
          optimize energy production. Real-time data on energy generation and consumption 
          is tracked, ensuring maximum efficiency and performance of the system. The 
          electricity generated is used to power classrooms, administrative offices, 
          laboratories, and other essential services, thereby reducing the overall 
          energy costs for the university.
<br/><br/>
          Beyond the environmental and economic benefits, the rooftop solar power plant 
          also serves as a valuable educational resource. Students and faculty members 
          have the opportunity to study the solar power generation process, analyze data, 
          and conduct research on renewable energy technologies. This hands-on experience 
          fosters a deeper understanding of sustainable practices and inspires future 
          innovations in the field of renewable energy.

          JSS University Noida's rooftop solar power plant is a testament to its 
          dedication to environmental sustainability and its role as a leader in 
          promoting green initiatives in higher education.
        </p>
      </section>
    </>
  );
}
