import React from "react";
// import STPCarousel from "./components/STPCarousel";

export default function SewageTreatmentPlant() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Sewage Treatment Plant (STP)</h2>
        </center>
        <img src="images/green-initiatives/STP_Plant.jpg" alt="Banner" className="centered-image w-100"  />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
  JSS University Noida, is equipped with a
          state-of-the-art Sewage Treatment Plant (STP) to manage and treat
          wastewater efficiently. The STP ensures that wastewater is processed
          and recycled, reducing the environmental impact and promoting
          sustainability on campus. Treated water from the STP is used for
          landscaping, irrigation, and other non-potable purposes, contributing
          to the conservation of fresh water. 
<br/><br/>
          The STP employs advanced treatment technologies, including primary, 
          secondary, and tertiary treatment processes, to ensure that the 
          effluent water meets stringent quality standards. This system not only 
          helps in reducing the campus's overall water consumption but also 
          minimizes the discharge of pollutants into the environment, thus protecting 
          the local ecosystem.
<br/><br/>
          Furthermore, the university actively involves students and staff in the 
          management and monitoring of the STP, providing educational opportunities 
          and raising awareness about sustainable water management practices. 
          Regular workshops and training sessions are conducted to educate the 
          university community about the importance of wastewater treatment and 
          the benefits of recycling water. By maintaining such an efficient 
          sewage treatment infrastructure, JSS Academy of Higher Education & Research 
          underscores its commitment to environmental stewardship and sustainable 
          development.
        </p>
      </section>
    </>
  );
}
