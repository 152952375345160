import React, { useState, useEffect } from "react";
import { redirect } from "react-router-dom";

export default function Courssection() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {windowWidth > 768 ? (
        <div
          style={{
            position: "relative",
            top: "50px",
            margin: "50px",
          }}
        >
          <section class="section-sm">
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="d-flex align-items-center section-title justify-content-between">
                    <h2 class="mb-0 mr-3">Courses Offered By JSS University</h2>
                    <div class="border-top w-100 border-primary d-none d-sm-block"></div>
                    <div>
                      <a
                        href="/school-of-engineering"
                        class="btn btn-sm btn-primary"
                      >
                        see all
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center"
                style={{
                  // display: "flex",
                  // justifyContent: "center",
                  // alignItems: "center",
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                  gap: "20px",
                }}
              >
                {/* <div class="col-lg-4 col-sm-6 mb-5"> */}
                <div class="card p-0 border-primary rounded-0 hover-shadow">
                  <img
                    class="card-img-top rounded-0"
                    src="images/jssCampus.jpg"
                    alt="course thumb"
                    style={{ aspectRatio: "4/3" }}
                  />
                  <div class="card-body">
                    <a href="/school-of-engineering">
                      <h4 class="card-title">School Of Engineering</h4>
                    </a>
                    <p class="card-text mb-4">
                      {" "}
                      Discover the innovative hub of engineering excellence at
                      JSS University School of Engineering, where cutting-edge
                      research meets hands-on learning for tomorrow's
                      technological pioneers.
                    </p>
                  </div>
                  <div className="card-footer">
                    <a
                      href="/school-of-engineering"
                      class="btn btn-primary btn-sm"
                    >
                      Read More
                    </a>
                  </div>
                </div>
                {/* </div> */}
                {/* <div class="col-lg-4 col-sm-6 mb-5"> */}
                <div class="card p-0 border-primary rounded-0 hover-shadow">
                  <img
                    class="card-img-top rounded-0"
                    src="images/jssCampus1.jpg"
                    alt="School of Pharmacy"
                    style={{ aspectRatio: "4/3" }}
                  />
                  <div class="card-body">
                    <a href="/school-of-pharmacy">
                      <h4 class="card-title">School Of Pharmacy</h4>
                    </a>
                    <p class="card-text mb-4">
                      Empowering future pharmacists with a blend of theoretical
                      knowledge and practical skills. The School of Pharmacy at
                      JSS University equips students to become leaders in the
                      healthcare industry.
                    </p>
                  </div>
                  <div className="card-footer">
                    <a
                      href="/school-of-pharmacy"
                      class="btn btn-primary btn-sm"
                    >
                      Read More
                    </a>
                  </div>
                </div>
                {/* </div> */}
                {/* <div class="col-lg-4 col-sm-6 mb-5"> */}
                <div class="card p-0 border-primary rounded-0 hover-shadow">
                  <img
                    class="card-img-top rounded-0"
                    src="images/jssCampus4.jpg"
                    alt="School of Management"
                    style={{ aspectRatio: "4/3" }}
                  />
                  <div class="card-body">
                    <a href="/school-of-management">
                      <h4 class="card-title">School Of Management</h4>
                    </a>
                    <p class="card-text mb-4">
                      The School of Management at JSS University fosters future
                      business leaders through a dynamic curriculum, industry
                      collaborations, and real-world simulations.
                    </p>
                  </div>
                  <div className="card-footer">
                    <a
                      href="/page-under-development"
                      class="btn btn-primary btn-sm"
                    >
                      Read More
                    </a>
                  </div>
                </div>
                {/* </div> */}
                <div class="card p-0 border-primary rounded-0 hover-shadow">
                  <img
                    class="card-img-top rounded-0"
                    src="images/jssCampus5.jpg"
                    alt="School of Computer Applications"
                    style={{ aspectRatio: "4/3" }}
                  />
                  <div class="card-body">
                    <a href="/page-under-development">
                      <h4 class="card-title">
                        School Of Computer Applications
                      </h4>
                    </a>
                    <p class="card-text mb-4">
                      We equip students with the technical expertise and
                      problem-solving skills to thrive in the digital age. Our
                      comprehensive curriculum covers a wide range of topics,
                      from programming languages and software development to
                      web.
                    </p>
                  </div>
                  <div className="card-footer">
                    <a
                      href="/page-under-development"
                      class="btn btn-primary btn-sm"
                    >
                      Read More
                    </a>
                  </div>
                </div>
                {/* <div class="col-lg-4 col-sm-6 mb-5"> */}
                {/* <div class="card p-0 border-primary rounded-0 hover-shadow">
                    <img
                      class="card-img-top rounded-0"
                      src="images/jssCampus5.jpg"
                      alt="School of Life Sciences"
                      height="100%"
                    />
                    <div class="card-body">
                      <a href="/page-under-development">
                        <h4 class="card-title">School Of Humanities</h4>
                      </a>
                      <p class="card-text mb-4">
                        Unravel the mysteries of life at JSS University's School
                        of Life Sciences. Our programs equip students for
                        careers in research, medicine, and environmental
                        sciences.
                      </p>
                      </div>
                      <div className="card-footer">
                      <a
                        href="/page-under-development"
                        class="btn btn-primary btn-sm"
                      >
                        Read More
                      </a>
                      </div>
                  </div> */}
                {/* </div> */}
                {/* <div class="col-lg-4 col-sm-6 mb-5">
                  <div class="card p-0 border-primary rounded-0 hover-shadow">
                    <img
                      class="card-img-top rounded-0"
                      src="images/jssCampus2.jpg"
                      alt="School of Life Sciences"
                    />
                    <div class="card-body">
                      <a href="/school-of-life-sciences">
                        <h4 class="card-title">School Of Life Sciences</h4>
                      </a>
                      <p class="card-text mb-4">
                        Unravel the mysteries of life at JSS University's School
                        of Life Sciences. Our programs equip students for
                        careers in research, medicine, and environmental
                        sciences.
                      </p>
                      <a
                        href="/school-of-life-sciences"
                        class="btn btn-primary btn-sm"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div> */}
                {/* <div class="col-lg-4 col-sm-6 mb-5"> */}
                <div class="card p-0 border-primary rounded-0 hover-shadow">
                  <img
                    class="card-img-top rounded-0"
                    src="images/jssCampus1.jpg"
                    alt="School of Basic Sciences"
                    style={{ aspectRatio: "4/3" }}
                  />
                  <div class="card-body">
                    <a href="/page-under-development">
                      <h4 class="card-title">School Of Applied Sciences</h4>
                    </a>
                    <p class="card-text mb-4">
                      Bridge the gap between theory and practice at JSS
                      University's School of Applied Sciences. Our programs focus
                      on practical applications of science in various fields
                      like agriculture, environment, and technology.
                    </p>
                  </div>
                  <div className="card-footer">
                    <a
                      href="/page-under-development"
                      class="btn btn-primary btn-sm"
                    >
                      Read More
                    </a>
                  </div>
                </div>
                <div class="card p-0 border-primary rounded-0 hover-shadow">
                  <img
                    class="card-img-top rounded-0"
                    src="images/jssCampus3.jpg"
                    alt="School of Basic Sciences"
                    style={{ aspectRatio: "4/3" }}
                  />
                  <div class="card-body">
                    <a href="/school-of-humanities">
                      <h4 class="card-title">School Of Humanities</h4>
                    </a>
                    <p class="card-text mb-4">
                      Bridge the gap between theory and practice at JSS
                      University's School of Humanities. Our programs focus
                      on practical applications of English and Economics.
                    </p>
                  </div>
                  <div className="card-footer">
                    <a
                      href="/school-of-humanities"
                      class="btn btn-primary btn-sm"
                    >
                      Read More
                    </a>
                  </div>
                </div>
                {/* </div>   */}
              </div>

              <div class="row">
                <div class="col-12 text-center">
                  <a
                    href="/school-of-engineering"
                    class="btn btn-sm btn-primary-outline d-sm-none d-inline-block"
                  >
                    sell all
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : (
        <div
          style={{
            position: "relative",
            top: "10px",
            margin: "10px",
          }}
        >
          <section class="section-sm">
            <div class="container">
              <div class="row">
                <div class="col">
                  <div class="d-flex align-items-center section-title justify-content-between">
                    <h2 class="mb-0 mr-3">Courses Offered By JSS University</h2>
                    <div class="border-top w-100 border-primary d-none d-sm-block"></div>
                    <div>
                      <a
                        href="/school-of-engineering"
                        class="btn btn-sm btn-primary-outline ml-sm-3 d-none d-sm-block"
                      >
                        see all
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div class="col-lg-4 col-sm-6 mb-5">
                  <div class="card p-0 border-primary rounded-0 hover-shadow">
                    <img
                      class="card-img-top rounded-0"
                      src="images/jssCampus.jpg"
                      alt="course thumb"
                      style={{ aspectRatio: "4/3" }}
                    />
                    <div class="card-body">
                      <a href="/school-of-engineering">
                        <h4 class="card-title">School Of Engineering</h4>
                      </a>
                      <p class="card-text mb-4">
                        {" "}
                        Discover the innovative hub of engineering excellence at
                        JSS University School of Engineering, where cutting-edge
                        research meets hands-on learning for tomorrow's
                        technological pioneers.
                      </p>
                      <a
                        href="/school-of-engineering"
                        class="btn btn-primary btn-sm"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mb-5">
                  <div class="card p-0 border-primary rounded-0 hover-shadow">
                    <img
                      class="card-img-top rounded-0"
                      src="images/jssCampus1.jpg"
                      alt="School of Pharmacy"
                      style={{ aspectRatio: "4/3" }}
                    />
                    <div class="card-body">
                      <a href="/school-of-pharmacy">
                        <h4 class="card-title">School Of Pharmacy</h4>
                      </a>
                      <p class="card-text mb-4">
                        Empowering future pharmacists with a blend of
                        theoretical knowledge and practical skills. The School
                        of Pharmacy at JSS University equips students to become
                        leaders in the healthcare industry.
                      </p>
                      <a
                        href="/school-of-pharmacy"
                        class="btn btn-primary btn-sm"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mb-5">
                  <div class="card p-0 border-primary rounded-0 hover-shadow">
                    <img
                      class="card-img-top rounded-0"
                      src="images/jssCampus4.jpg"
                      alt="School of Management"
                      style={{ aspectRatio: "4/3" }}
                    />
                    <div class="card-body">
                      <a href="/school-of-management">
                        <h4 class="card-title">School Of Management</h4>
                      </a>
                      <p class="card-text mb-4">
                        The School of Management at JSS University fosters
                        future business leaders through a dynamic curriculum,
                        industry collaborations, and real-world simulations.
                      </p>
                      <a
                        href="/school-of-management"
                        class="btn btn-primary btn-sm"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mb-5">
                  <div class="card p-0 border-primary rounded-0 hover-shadow">
                    <img
                      class="card-img-top rounded-0"
                      src="images/jssCampus5.jpg"
                      alt="School of Life Sciences"
                      style={{ aspectRatio: "4/3" }}
                    />
                    <div class="card-body">
                      <a href="/page-under-development">
                        <h4 class="card-title">
                          School Of Computer Applicatons
                        </h4>
                      </a>
                      <p class="card-text mb-4">
                        We equip students with the technical expertise and
                        problem-solving skills to thrive in the digital age. Our
                        comprehensive curriculum covers a wide range of topics,
                        from programming languages and software development to
                        web.
                      </p>
                      <a
                        href="/page-under-development"
                        class="btn btn-primary btn-sm"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-sm-6 mb-5">
                  <div class="card p-0 border-primary rounded-0 hover-shadow">
                    <img
                      class="card-img-top rounded-0"
                      src="images/jssCampus2.jpg"
                      alt="School of Life Sciences"
                      style={{ aspectRatio: "4/3" }}
                    />
                    <div class="card-body">
                      <a href="/page-under-development">
                        <h4 class="card-title">School Of Applied Sciences</h4>
                      </a>
                      <p class="card-text mb-4">
                        Bridge the gap between theory and practice at JSS
                        University's School of Applied Sciences. Our programs
                        focus on practical applications of science in various
                        fields like agriculture, environment, and technology.
                      </p>
                      <a
                        href="/page-under-development"
                        class="btn btn-primary btn-sm"
                      >
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 text-center">
                  <a
                    href="/school-of-engineering"
                    class="btn btn-sm btn-primary-outline d-sm-none d-inline-block"
                  >
                    see all
                  </a>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </>
  );
}
