import React from "react";
import SportsCarousel from "./components/MedicalCarousel";

export default function Medical() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Medical Facilities</h2>
        </center>
        <SportsCarousel></SportsCarousel>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding:"20px",
            marginBottom:"120px"
          }}
        >
          While JSS Academy of Higher Education & Research, Noida, prioritizes
          academic excellence across various disciplines, it currently does not
          have on-site medical facilities. However, the university is situated
          in a well-connected area with access to reputable hospitals and
          clinics nearby. In case of medical emergencies, students and faculty
          can seek immediate care at these external facilities. The university
          is likely exploring future options to enhance healthcare access for
          its community.
        </p>
      </section>
    </>
  );
}
