import React from "react";
import banner3 from "../../assets/images/banner3.jpg";

export default function AboutJSSMVP() {
  return (
    <>
      <section className="section"
        style={{
            position: "relative",
            top: "185px",
            marginBottom: "10vh",
            marginTop: "10vh",
            padding: "2rem",

        }}
      >
            <center>
            <h2>About JSSMVP</h2>
            </center>
            <img src={banner3} alt="Banner" className="centered-image w-100"  />
            <br/><br/>
            <h4>In pursuit of excellence</h4>
            <p className="" style={{
                "textAlign": "justify",
                "fontSize": "1.2em",

            }}>
                The origin of JSS Mahavidyapeetha (JSSMVP), a formidable educational movement, can be traced to the establishment of a small hostel in 1928 to cater to the accommodation needs of students pursuing higher studies in Mysore. His Holiness Jagadguru Dr. Sri Shivarathri Rajendra Mahaswamiji established the Jagadguru Sri Shivarathreeshwara Mahavidyapeetha (JSSMVP) in 1954 which was registered under the Societies Registration Act 1960.
                <br/> 
Focusing on a purpose as expansive yet specific as improving quality of life through Human Development, the JSS Mahavidyapeetha has grown from strength to strength. A long and healthy life, Education for all and a decent standard of living, the indicators of Human development, have been the underlying philosophy of Jagadguru Sri Veerasimhasana Mahasamsthana Math, Suttur Srikshethra for centuries. This is also the philosophy for which the Mahaidyapeetha today stands for.
            </p>
            <br/>
            <h4>Empowering People through Education</h4>
            <p style={{
                "textAlign": "justify",
                "fontSize": "1.2em",

            }}>
            Education empowers people with the knowledge, skills and values they need to build a better world. Right from Sri Suttur Math’s very first initiative of starting a free hostel in 1928 through the visionary leadership of its spiritual heads, JSS Mahavidyapeetha has a trailblazing legacy of establishing several educational institutions that have led to transformative change.
            <br/>
            Under the untiring efforts of Jagadguru Dr. Sri Shivarathri Rajendra Mahaswamiji, the Mahavidyapeetha has witnessed enormous growth in the field of Education and today has over 300 institutions under its fold, from kindergartens to postgraduate centres and postdoctoral research catering to the educational needs of more than 1,00,000 students.
            <br/>
            The Mahavidyapeetha continues to play a vital role in expanding the scope of its activities to several branches of knowledge, welfare and culture. Its educational efforts span crèches for toddlers of working rural women, schools to impart primary and secondary Education in both Kannada and English medium, Colleges, Polytechnics, Technical, Medicine, etc. It has equipped itself with extensive infrastructure and an army of dedicated and highly qualified human resources to realize its mission. These institutions, located in strategic areas, serve a broad spectrum of society, from virtually remote tribal villages to metropolitan cities such as Bengaluru, Noida, New Delhi, Ooty, and Coimbatore, besides their presence in the United States, Mauritius and Dubai.

            </p>
            <p style={{
                "textALign": "justify",
                "fontSize": "1.2em",

            }}>
            <h4>Fostering Creativity & Celebrating Culture</h4>
            The endeavours of JSSMVP encompass a spectrum of activities beyond formal education. It aims to advance rural development by providing training and empowerment to rural communities and facilitating access to contemporary and traditional Indian healthcare systems. Efforts are also made to support artistic and literary pursuits and restore historical temples and monuments.
            <br/><br/><br/>
            For more info abput JSSMVP, visit <a href="https://jssonline.org/">https://jssonline.org/</a>
            </p>

        </section>
    </>
  );
}
