import React, { useRef, useState, useEffect } from "react";
import swamiji_image from "../../assets/images/swamiji.jpg";

export default function SchoolOfEngineering() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div
        style={{
          height: "fit-content",
          flexDirection: windowWidth > 768 ? "row" : "column",
          display: "flex",
          justifyContent: "center",
          alignItems: windowWidth > 768 ? "" : "center",
          paddingTop: windowWidth > 768 ? "20vh" : "10vh",
          gap: "40px",
        }}
      >
        <nav
          class="d-flex flex-column shadow-sm py-3 navbar"
          style={{
            width: "250px",
            marginTop: "65px",
            backgroundColor: "#0054A4",
            padding: "10px",
            borderRadius: "10px",
            color: "white",
            height: "fit-content",
          }}
        >
          <p
            style={{
              backgroundColor: "rgb(230, 120, 23)",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "20px",
              width: "100%",
              textAlign: "center",
              color: "white",
              fontWeight: "bold",
            }}
          >
            UG Schools
          </p>
          <a
            class="nav-link text-white side-menu-link"
            href="/school-of-engineering"
          >
            &gt; School Of Engineering
          </a>
          <a
            class="nav-link text-white side-menu-link"
            href="/school-of-pharmacy"
          >
            &gt; School Of Pharmacy
          </a>
         
        </nav>

        <section class="section-sm" style={{
          position:"relative",
          top:"30px"
        }}>
          <div class="container">
            <div class="row">
              <div class="col-12 mb-4">
                <a href="/admission-apply">
                  <img
                    src="images/admission_apply_now.png"
                    class="img-fluid w-100"
                    style={{ height: "auto" }}
                    alt="Admission Apply Now"
                  />
                </a>
              </div>
            </div>
            <div class="row align-items-center mb-5">
              <div class="col-xl-6 order-1 col-sm-6 mb-4 mb-xl-0">
                <h2>School Of Engineering</h2>
              </div>

              <div class="col-xl-6 text-sm-right text-left order-sm-2 order-3 order-xl-3 col-sm-6 mb-4 mb-xl-0">
                <a href="/admission-apply" class="btn btn-primary">
                  Apply now
                </a>
              </div>
              <div class="col-12 mt-4 order-4">
                <div class="border-bottom border-primary"></div>
              </div>
            </div>

            <div class="row">
              {/* Programme Information Boxes */}
              <div class="col-md-12 mb-4">
                <div class="col-md-12 mb-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5 class="card-title">CSE</h5>
                      <p class="card-text">
                        The CSE program equips students with a strong foundation
                        in The CSE program equips students with a strong
                        foundation in computer science principles, programming
                        languages, algorithms, and data structures. Graduates
                        are prepared for careers in software development,
                        systems engineering, and various other IT fields.
                      </p>
                      <a href="/cse" class="btn btn-primary btn-sm">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-12 mb-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5 class="card-title">
                        CSE (Artificial Intelligence & Machine Learning)
                      </h5>
                      <p class="card-text">
                        This specialized program delves deeper into AI and ML,
                        equipping students with the skills to develop
                        intelligent systems, analyze complex data, and solve
                        real-world problems. It prepares graduates for careers
                        in areas like robotics, natural language processing, and
                        computer vision.
                      </p>
                      <a href="/cseaiml" class="btn btn-primary btn-sm">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5 class="card-title">CSE (Data Science)</h5>
                      <p class="card-text">
                        The CSE (Data Science) program focuses on the extraction
                        and analysis of large datasets. Students learn
                        techniques for data mining, machine learning, and data
                        visualization to solve complex problems in various
                        fields like healthcare, finance, and marketing.
                        Graduates are prepared for careers as data scientists,
                        data analysts, and business intelligence specialists.
                      </p>
                      <a href="/cse-dst" class="btn btn-primary btn-sm">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5 class="card-title">Information Technology (IT)</h5>
                      <p class="card-text">
                        The IT program equips students with the skills to
                        design, develop, implement, and manage information
                        systems. They learn about networking, cybersecurity,
                        cloud computing, and various software applications.
                        Graduates are prepared for careers as IT specialists,
                        network administrators, and system analysts.
                      </p>
                      <a href="/it" class="btn btn-primary btn-sm">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5 class="card-title">
                        Electronics and Communication Engineering (ECE)
                      </h5>
                      <p class="card-text">
                        The ECE program focuses on the design and development of
                        electronic circuits, communication systems, and embedded
                        systems. Students learn about analog electronics,
                        digital electronics, signal processing, and control
                        systems. Graduates are prepared for careers in areas
                        like telecommunications, VLSI design, and robotics.
                      </p>
                      <a href="#" class="btn btn-primary btn-sm">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mb-4">
                  <div class="card shadow">
                    <div class="card-body">
                      <h5 class="card-title">
                        Robotics and Artificial Intelligence (RAI)
                      </h5>
                      <p class="card-text">
                        The RAI program combines robotics with artificial
                        intelligence, equipping students with the skills to
                        design, develop, and program intelligent robots. They
                        learn about robot mechanics, sensors, actuators, control
                        systems, and machine learning algorithms. Graduates are
                        prepared for careers in areas like automation,
                        industrial robotics, and autonomous systems.
                      </p>
                      <a href="#" class="btn btn-primary btn-sm">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>

                {/* Add more programme information boxes here */}

                {/* <div class="col-12 mb-5">
                  <h3>Words From HOD's Desk</h3>
                  <p>
                    "Welcome to JSS University School of Engineering! As the
                    Head of Department, I am thrilled to invite you to embark on
                    a transformative journey of learning and innovation. Our
                    esteemed
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
