import React from "react";
import { useState, useEffect } from "react";

const governingBodiesData = [
  {
    slNo: 1,
    constitution: "The Chancellor",
    name: "His Holiness Jagadguru Sri Shivarathri Deshikendra MahaSwamiji",
    designation: "",
    status: "Chairman",
  },
  {
    slNo: 2,
    constitution: "Pro Chancellor",
    name: "Dr. B Suresh",
    designation:
      "Director, Technical education Division  JSS Mahavidhyapeetha, Mysuru",
    status: "Member",
  },
  {
    slNo: 3,
    constitution: "Vice Chancellor",
    name: "Dr. H.R. Mahadevaswamy",
    designation: "Vice Chancellor ( Officiating) JSS University, Noida",
    status: "Member",
  },
  {
    slNo: 4,
    constitution: "Nominee of the Sponsoring Body",
    name: "Dr. C. G. Betsurmath",
    designation: "Member Secretary JSS Mahavidhyapeetha, Ghaziabad",
    status: "Member",
  },
  {
    slNo: 5,
    constitution: "Nominee of the Sponsoring Body",
    name: "Sri S P Manjunath",
    designation: "Secretary- 1 JSS Mahavidhyapeetha, Mysuru",
    status: "Member",
  },
  {
    slNo: 6,
    constitution: "Eminent Educationist Nominated by Sponsoring body",
    name: "Dr. A.N. Santhosh Kumar",
    designation:
      "  Vice Chancellor JSS Science and Technology University Mysuru",
    status: "Member",
  },
  {
    slNo: 7,
    constitution: "Industry Expert Nominated by Sponsoring body",
    name: "Sri AS Kiran Kumar",
    designation: "Former Chairman, ISRO Bengaluru",
    status: "Member",
  },
  {
    slNo: 8,
    name: "Sri Baba N Kalyani ",
    designation:
      "Chairman and MD Bharat Forge Limited, Pune Cantonment, Mundhwa Pune- 411036",
    constitution: "Industry Expert Nominated by Sponsoring body",
    status: "Member",
  },
  {
    slNo: 9,
    constitution: "Industry Expert Nominated by Sponsoring body",
    name: "Mrs. Sudha Murthy",
    designation:
      "Chairman, Infosys Foundation and Member of Parliament (Rajya Shabha) Bengaluru",
    status: "Member",
  },
  {
    slNo: 10,
    constitution: "Industry Expert Nominated by Sponsoring body",
    name: "Dr. Lingaraj S Patil",
    designation:
      "Shanthosaraj Nilay, Plot Mo 83 & 84 HUDA Heights, MLA Colony Road No 12, Banjara Hills Hyderabad- 500034 Telangana",
    status: "Member",
  },
  {
    slNo: 11,
    constitution: "Legal Expert Nominated by Sponsoring body",
    name: "Justice Sri S.R.Bannurmath ",
    designation:
      "Chairman Law Commission of Karnataka, Former Chief Justice of Kerala and (Former Chairperson of Maharastra State Human Rights Commission)",
    status: "Member",
  },
  {
    slNo: 12,
    constitution: "Finance Expert Nominated by Sponsoring body",
    name: "Sri S Puttasubbappa",
    designation: " Director- Finance JSS Mahavidhyapeetha, Mysuru",
    status: "Member",
  },
  {
    slNo: 13,
    constitution: "Registrar- Ex- Officio Secretary",
    name: "",
    designation: "By Designation",
    status: "Member",
  },
];

const GoverningBodies = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <section
      className="section"
      style={{
        marginTop: windowWidth > 768 ? "30vh" : "100px",
        marginBottom: "100px",
        padding: "10px",
        fontSize: windowWidth > 768 ? "1.2em" : "0.75em",
      }}
    >
      <div className="container mt-5">
        <h1 className="mb-4">Governing Body</h1>
        <table className="table table-striped">
          <thead style={{ background: "#0054a4", color:"#fff" }}>
            <tr>
              <th scope="col">SL NO</th>
              <th scope="col">CONSTITUTION</th>
              <th scope="col">NAME AND DESIGNATION</th>
              <th scope="col">STATUS</th>
            </tr>
          </thead>
          <tbody>
            {governingBodiesData.map((body, index) => (
              <tr key={index}>
                <th scope="row">{body.slNo}</th>
                <td>{body.constitution}</td>
                <td>
                  {body.name}
                  <br />
                  {body.designation}
                </td>
                <td>{body.status}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default GoverningBodies;
