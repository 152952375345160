import React from "react";
import { useState, useEffect } from "react";
import admission_pdf from "../../assets/admission_procedure_eligibility.pdf"


export default function AdmissionMain() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
    {windowWidth > 768 ? (
      <div
        style={{
          padding: "50px",
          marginTop: "200px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "50vw",
          }}
        >
          <img
            style={{
              width: "100%",
            }}
            src="images/mainProspectus.jpg"
            alt=""
          />
        </div>
        <div
          style={{
            height: "fit-content",
            padding: "25px",
            width: "50vw",
          }}
        >
          <div
            class="card p-0 border-primary rounded-0 hover-shadow"
            style={{
              height: "40%",
              width: "100%",
              marginTop:"0px"
            }}
          >
            <a
              href="/admission-criteria"
              class="btn btn-primary btn-sm"
              style={{
                height: "100%",
              }}
            >
              Admission Procedure and Eligibility
            </a>
          </div>
          <div
            class="card p-0 border-primary rounded-0 hover-shadow"
            style={{
              height: "40%",
              width: "100%",
              marginTop:"50px"

            }}
          >
            <a
              href="/admission-apply"
              class="btn btn-primary btn-sm"
              style={{
                height: "100%",
              }}
            >
              Admission Apply Form
            </a>
          </div>
          <div
            class="card p-0 border-primary rounded-0 hover-shadow"
            style={{
              height: "40%",
              width: "100%",
              marginTop:"50px"

            }}
          >
            <a
              href="/fee-structure"
              class="btn btn-primary btn-sm"
              style={{
                height: "100%",
              }}
            >
              Fee Structure
            </a>
          </div>
          
        </div>
      </div> ) : (<>
        <div
        style={{
          marginTop: "20px",
          display: "coloumn",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "100vw",
          }}
        >
          <img
            style={{
              width: "100%",
            }}
            src="images/mainProspectus.jpg"
            alt=""
          />
        </div>
        <div
          style={{
            height: "fit-content",
            width: "100vw",
            marginBottom: "20px"    ,
            marginTop:"20px"

          }}
        >
          <div
            class="card p-0 border-primary rounded-0 hover-shadow"
            style={{
              height: "40%",
              width: "100%",
              marginTop:"0px"
            }}
          >
            <a
              href="/admission-criteria"
              class="btn btn-primary btn-sm"
              style={{
                height: "100%",
              }}
            >
              Admission Procedure and Eligibility
            </a>
          </div>
          <div
            class="card p-0 border-primary rounded-0 hover-shadow"
            style={{
              height: "40%",
              width: "100%",
              marginTop:"50px"

            }}
          >
            <a
              href="/admission-apply"
              class="btn btn-primary btn-sm"
              style={{
                height: "100%",
              }}
            >
              Admission Apply Form
            </a>
          </div>
          <div
            class="card p-0 border-primary rounded-0 hover-shadow"
            style={{
              height: "40%",
              width: "100%",
              marginTop:"50px"

            }}
          >
            <a
              href="/fee-structure"
              class="btn btn-primary btn-sm"
              style={{
                height: "100%",
              }}
            >
              Fee Structure
            </a>
          </div>
          
        </div>
      </div>
      
      </>)}
    </>
  );
}
