import React, { useRef, useState, useEffect } from "react";
import swamiji_image from "../../assets/images/swamiji.jpg";

export default function SchoolOfPharmacy() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <div
        style={{
          height: "fit-content",
          flexDirection: windowWidth > 768 ? "row" : "column",
          display: "flex",
          justifyContent: "center",
          alignItems: windowWidth > 768 ? "" : "center",
          paddingTop: windowWidth > 768 ? "20vh" : "10vh",
          gap: "40px",
        }}
      >
        <nav
          class="d-flex flex-column shadow-sm py-3 navbar"
          style={{
            width: "250px",
            marginTop: "45px",
            backgroundColor: "#0054A4",
            padding: "10px",
            borderRadius: "10px",
            color: "white",
            height: "fit-content",
          }}
        >
          <p
            style={{
              backgroundColor: "rgb(230, 120, 23)",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "20px",
              width: "100%",
              textAlign: "center",
              color: "white",
              fontWeight: "bold",
            }}
          >
            UG Schools
          </p>
          <a
            class="nav-link text-white side-menu-link"
            href="/school-of-engineering"
          >
            &gt; School Of Engineering
          </a>
          <a
            class="nav-link text-white side-menu-link"
            href="/school-of-pharmacy"
          >
            &gt; School Of Pharmacy
          </a>
          
          
        </nav>

        <section class="section-sm" style={{
          position:"relative",
          top:"30px"
        }}>
          <div class="container">
            <div class="row">
              <div class="col-12 mb-4">
                <a href="/admission-apply">
                  <img
                    src="images/admission_apply_now.png"
                    class="img-fluid w-100"
                    style={{ height: "auto" }}
                  />
                </a>
              </div>
            </div>
            <div class="row align-items-center mb-5">
              <div class="col-xl-6 order-1 col-sm-6 mb-4 mb-xl-0">
                <h2>School Of Pharmacy</h2>
              </div>

              <div class="col-xl-6 text-sm-right text-left order-sm-2 order-3 order-xl-3 col-sm-6 mb-4 mb-xl-0">
                <a href="/admission-apply" class="btn btn-primary">
                  Apply now
                </a>
              </div>
              <div class="col-12 mt-4 order-4">
                <div class="border-bottom border-primary"></div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 mb-4">
                <h3>About Course</h3>
                <p>
                  Ignite your passion for science and healthcare at JSS Noida
                  University:School of Pharmacy. Our Pharmacy programs fosters
                  future leaders by delving deep into the science of medication
                  - from drug design to patient interaction. Through rigorous
                  coursework and hands-on experiences in simulated labs and
                  diverse practice settings, you'll gain the knowledge and
                  skills to provide safe, effective patient care. Our esteemed
                  faculty, known for their clinical expertise, will guide you in
                  a supportive learning environment. Join a community of
                  passionate peers and embark on your journey to become a
                  catalyst for positive change in the evolving world of pharmacy
                </p>
              </div>

              <div class="col-12 mb-4">
                <h3 class="mb-5">Programs Offered</h3>
                
                  <div class="col-md-12 mb-4">
                    <div class="card shadow">
                      <div class="card-body">
                        <h5 class="card-title">
                          Diploma in Pharmacy (D. Pharm)
                        </h5>
                        <p class="card-text">
                          (Replace with description of the Diploma in Pharmacy
                          program)
                        </p>
                        <a href="/pharmacy-detail" class="btn btn-primary btn-sm">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-4">
                    <div class="card shadow">
                      <div class="card-body">
                        <h5 class="card-title">
                          Bachelor of Pharmacy (B. Pharm)
                        </h5>
                        <p class="card-text">
                          (Replace with description of the Bachelor of Pharmacy
                          program)
                        </p>
                        <a href="/pharmacy-detail" class="btn btn-primary btn-sm">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>
                  {/* <div class="col-md-12 mb-4">
                    <div class="card shadow">
                      <div class="card-body">
                        <h5 class="card-title">
                          Master of Pharmacy (M. Pharm)
                        </h5>
                        <p class="card-text">
                          (Replace with description of the Master of Pharmacy
                          program)
                        </p>
                        <a href="#" class="btn btn-primary btn-sm">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-12 mb-4">
                    <div class="card shadow">
                      <div class="card-body">
                        <h5 class="card-title">Doctor of Pharmacy (PharmD)</h5>
                        <p class="card-text">
                          (Replace with description of the Doctor of Pharmacy
                          program)
                        </p>
                        <a href="#" class="btn btn-primary btn-sm">
                          Learn More
                        </a>
                      </div>
                    </div>
                  </div> */}
                
              </div>
              {/* <div class="col-12 mb-5">
              <h3>Words From HOD's Desk</h3>
              <p>
              "Welcome to JSS University School of Engineering! As the Head of Department, I am thrilled to invite you to embark on a transformative journey of learning and innovation. Our esteemed faculty and state-of-the-art facilities await to nurture your talents and cultivate your potential. Together, let us explore the frontiers of engineering, pushing boundaries and creating solutions that impact the world. Join us in shaping the future of technology, where curiosity thrives and excellence reigns. Your adventure starts here, at the heart of innovation. Welcome to a community dedicated to engineering excellence." - Dr. Kakoli Banarjee, Head of Department
              </p>
            </div>
            <div class="col-12">
              <h5 class="mb-3">Managed Under</h5>
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="media mb-2 mb-sm-0">
                  <img
                    class="mr-4 img-fluid"
                    src="images/teacher.jpg"
                    alt="Teacher"
                  />
                  <div class="media-body">
                    <h4 class="mt-0"> Dr. Kakoli Banarjee</h4>
                    HOD 
                  </div>
                </div>
                <div class="social-link">
                  <h6 class="d-none d-sm-block">Social Link</h6>
                  <ul class="list-inline">
                    <li class="list-inline-item">
                      <a class="d-inline-block text-light p-1" href="#">
                        <i class="ti-facebook"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="d-inline-block text-light p-1" href="#">
                        <i class="ti-twitter-alt"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="d-inline-block text-light p-1" href="#">
                        <i class="ti-linkedin"></i>
                      </a>
                    </li>
                    <li class="list-inline-item">
                      <a class="d-inline-block text-light p-1" href="#">
                        <i class="ti-instagram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="border-bottom border-primary mt-4"></div>
            </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
