import React from "react";
import coeImg from "../../assets/images/coe.png";
export default function ControllerOfExamination() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "20vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <div className="centered-image">
          <div>
            <img
              src={coeImg}
              alt="Controller of Examinations Dr. Shiddayya S Shirur"
              style={{
                width: "100%",
              }}
            />
          </div>
          <div style={{ textAlign: "center", marginTop: "30px" }}>
            <h2>Dr. Shiddayya S Shirur</h2>
            <p>coe@jssuninoida.edu.in</p>
          </div>
        </div>
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          As the Controller of Examinations, I am committed to ensuring the
          integrity, transparency, and smooth functioning of the examination
          process at JSS University, Noida. Our team is dedicated to providing a
          fair and supportive environment for all students, faculty, and staff
          involved in the examination process. We strive to maintain the highest
          standards of academic excellence and integrity, while also innovating
          and improving our processes to meet the evolving needs of our
          University. I wish all students the best of luck in their academic
          pursuits.
          <br />
          <br />
          Dr. Shiddayya S Shirur
          <br />
          Controller of Examination
          <br />
          JSS University, Noida
        </p>
      </section>
    </>
  );
}
