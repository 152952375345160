import React from "react";
import CanteenCarousel from "./components/CanteenCarousel";

export default function Canteen() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "100px",
          padding: "50px",
        }}
      >
        <center>
          <h2>Canteen</h2>
        </center>
        <CanteenCarousel></CanteenCarousel>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
            padding: "20px",
            marginBottom: "120px",
          }}
        >
          JSS University Noida boasts a vibrant and well-equipped canteen that serves as 
          a popular gathering spot for students, faculty, and staff. The canteen offers 
          a wide variety of food options, catering to diverse tastes and dietary 
          preferences, ensuring that everyone can find something to enjoy.
<br/><br/>
          The canteen's menu includes a range of delicious and nutritious meals, 
          from traditional Indian dishes to international cuisines. Whether you're 
          looking for a hearty breakfast, a satisfying lunch, or a quick snack between 
          classes, the canteen has something to offer. Freshly prepared meals, 
          snacks, and beverages are available throughout the day, providing 
          convenient dining options on campus.
<br/><br/>
          Hygiene and quality are top priorities at the JSS University Noida canteen. 
          The food is prepared in a clean and sanitary environment, and the staff 
          ensures that high standards of food safety are maintained at all times. 
          The canteen also offers healthy eating options, including salads, fruits, 
          and low-calorie meals, promoting the well-being of the university community.
<br/><br/>
          The canteen provides a comfortable and welcoming atmosphere for students 
          and staff to relax and socialize. With ample seating and a pleasant ambiance, 
          it serves as an ideal place for informal meetings, group studies, or simply 
          unwinding after a busy day of classes. The friendly staff and prompt service 
          enhance the overall dining experience, making the canteen a favorite spot on campus.
<br/><br/>
          In addition to providing delicious food, the canteen also plays a role in 
          fostering a sense of community within the university. It is a place where 
          students from different departments can interact, share ideas, and build 
          lasting friendships. Regular food festivals and special events organized 
          by the canteen further enrich the campus life, offering opportunities for 
          cultural exchange and celebration.
<br/><br/>
          JSS University Noida's canteen is more than just a place to eat; it is an 
          integral part of the campus experience, contributing to the vibrant and 
          dynamic life at the university. By offering quality food and a welcoming 
          environment, the canteen supports the academic and personal well-being of 
          the university community.
        </p>
      </section>
    </>
  );
}
