import React from "react";

export default function ViceChancellor() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "20vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <div className="centered-image">
          <div>
            <h2>Dr. H.R. Mahadevaswamy, Officiating Vice Chancellor</h2>
          </div>
          <div>
            <img
              src="images/vice_chancellor.jpeg"
              alt="Vice Chancellor Dr.H.R.Mahadevaswamy"
              style={{
                maxWidth: "90%",
                height: "auto",
                display: "block",
              }}
            />
          </div>
        </div>
        <br />
        <br />
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          Dr.H.R.Mahadevaswamy is a result oriented techno manager turned to
          Entrepreneur with 31 Years of proven experience and is having blend of
          experience with Academic, Industry, Entrepreneurship, Administration.
          His assignments are like : Joint Director, Principal of Engineering
          College, Group Manager at MNC and CEO of own firm. He is well
          connected to agencies Accreditation agencies, Industries & corporate
          people across India and outside India, experience in writing proposal
          for funding from various agencies like DST-Govt of India, KCTU-Govt of
          Karnataka and dealt with GITA, Horizon-2020, and few other agencies.
          Also, well connected professional bodies like KASSIA, CII, IAN, IIF,
          CSI, FKKCI, ISTE, IE(I), IEEE and few others.
          <br />
          <br />
          He studied B.E. in ECE from SJCE, Mysuru during 1987, M.Tech. in
          Industrial Electronics from National Institute of Technology(NIT),
          Suratkal during 1992, PhD in Image Processing from National Institute
          of Technology(NIT), Calicut during 2000.
          <br />
          <br />
          He has authored 15 publications in conferences and journals. He had
          visited <b>Countries</b> :Germany, Portugal, Malaysia, France for
          technology business discussions. His research areas of interest are :
          Virtual Reality (VR), 3D Point Cloud, Imaging technology, Multimedia,
          and Cyber Physical System (CPS).
          <br />
          <br />
          <a
            className="btn btn-primary"
            href="https://jssuninoida.edu.in/assets/docs/Dr.HRM-Profile.pdf"
            target="_blank"
          >
            View Profile
          </a>
        </p>
        <br /><br />
        <h4>MESSAGE FROM VICE CHANCELLOR</h4>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          <b>Dear Students, Faculty, Staff, and Visitors,</b><br/>
          It is with immense pride and excitement that I welcome you to JSS
          University, Noida, a new institution dedicated to shaping the future
          of education, research, and innovation under the umbrella of JSS
          Mahavidyapeetha. JSS is well known organization having four reputed
          Universities with high ranking in NIRF and globally. As the founding &
          Officiating Vice Chancellor, it is both an honour and a responsibility
          to lead this university at such a pivotal moment in its inception.
          <br />
          <br />
          At JSS University, Noida, we are driven by a vision to create a
          dynamic, inclusive, and forward-thinking academic environment where
          curiosity thrives and innovation is nurtured. Our mission is to
          provide a platform for students to explore new ideas, challenge
          conventional thinking, and develop the skills necessary to make
          meaningful contributions to society.As a new university, we have the
          unique opportunity to build something truly extraordinary from the
          ground up. We are committed to assembling a diverse and talented
          community of scholars, educators, and learners who share our passion
          for excellence and our dedication to addressing the challenges of the
          21st century.
          <br />
          <br />
          We aim to foster a culture of collaboration, creativity, and critical
          inquiry, where every student is encouraged to reach their full
          potential. As we embark on this exciting journey together, I invite
          you to join us in building a vibrant and inclusive community at JSS
          University, Noida. Whether you are a prospective student, a faculty
          member, or a visitor, I encourage you to explore our website and
          discover the many opportunities that await you at our university.
          <br />
          <br />
          Together, let us lay the foundation for a legacy of excellence that
          will inspire future generations.
          <br />
          <br />
          Thank you for being part of this transformative journey.
          <br />
          <br />
          <b>
            Warm regards,<br />
            Dr.H.R.Mahadevaswamy<br />
            Vice Chancellor ( Officiating)<br />
            JSS University, Noida<br />
          </b>
        </p>
      </section>
    </>
  );
}
