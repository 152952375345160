import React from "react";

export default function RegistrarMessage() {
  return (
    <>
      <section
        className="section"
        style={{
          position: "relative",
          top: "185px",
          marginBottom: "20vh",
          marginTop: "10vh",
          padding: "2rem",
        }}
      >
        <div className="centered-image">
          <div>
            <h2>Message from Registrar</h2>
          </div>
          <div>
            <img
              src="images/registrar.jpeg"
              alt="Vice Chancellor Dr.H.R.Mahadevaswamy"
              style={{
                maxWidth: "90%",
                height: "auto",
                display: "block",
              }}
            />
          </div>
        </div>
        <br />
        <br />
        <h4>As the Registrar of JSS University, Noida,</h4>
        <p
          className=""
          style={{
            textAlign: "justify",
            fontSize: "1.2em",
          }}
        >
          I am dedicated to advancing the institution's mission of academic
          excellence, equity, and innovation in higher education. With a deep
          sense of commitment to fostering a supportive and inclusive
          environment for our diverse student body and supporting our faculty
          and staff, my intent is to foster an environment where every student
          can thrive academically and personally based on the strong foundation
          and legacy of JSS Institutions.
          <br />
          <br />
          Having over 25 years of experience in academia & administration, I
          have a deep commitment to student success; my vision is to enhance the
          operational efficiency, integrity, and inclusivity of our academic
          programs and services. Through proactive engagement and collaboration
          with stakeholders across the university community, I seek to promote
          equity and access in higher education. This involves implementing
          policies that promote diversity, equity, and inclusion, as well as
          ensuring compliance with regulatory requirements.
          <br />
          <br />
          In addition to my administrative responsibilities, I am committed to
          promoting a culture of continuous improvement and innovation. By
          staying abreast of emerging trends in education and implementing best
          practices, I strive to position JSS University, Noida as a leader in
          academic excellence and student-cantered learning. I view
          collaboration with faculty, staff, and students as essential to
          achieving our shared goals of academic excellence and student success
          so I look forward to working together with all stakeholders to
          continuously improve our academic services and support systems,
          ensuring that JSS University, Noida remains a leader in education.
          <br />
          <br />
          <b>
            Warm regards,
            <br />
            Dr.T.G.Mamatha 
            <br />
            Registrar
            <br />
            JSS University, Noida
            <br />
          </b>
        </p>
      </section>
    </>
  );
}
